export const FACE_PROBLEM_CLASS_MAPPING: { [key: string]: Array<string> } = {
  ACNE_OR_PIMPLES: ['pimple-region', 'come-region'],
  COMEDONES: ['pimple-region', 'come-region'],
  DARK_SPOTS_OR_MARK: ['darkspot-region'],
  UNDER_EYE_DARK_CIRCLES: ['DarkCircle'],
  PUFFY_EYES: ['DarkCircle'],
  ACNE_SCARS: ['ascar-region'],
  PIGMENTATION: ['Melasma'],
  MELASMA: ['Melasma'],
  OPEN_PORES: ['OpenPores'],
  FRECKLES: ['Freckles'],
};
