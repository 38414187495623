<div class="tw-flex tw-flex-row tw-items-start tw-gap-6 tw-pt-14 tw-px-6 tw-pb-6 tw-min-h-[400px]">
  <div id="face-section" class="tw-relative tw-flex-1 tw-bg-gray-80 tw-pb-8 tw-px-4 tw-pt-20 tw-rounded-2.5 tw-shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]" [ngClass]='{ "tw-order-1": selectedConcernClass === appConfig.Shared.Regimen.Class.HAIR }' (click)="onSelectConcern(appConfig.Shared.Regimen.Class.FACE)">
    <img class="tw-absolute tw-block tw-h-[90px]" style="top: -28px;" [src]="faceIconUrl" />
    <div class="tw-font-body tw-font-bold tw-text-500 tw-text-[20px] tw-text-gray-800 tw-mb-6" i18n="@@forSkin"> For skin problems </div>
    <cs-button [class]="'tw-w-full primary btn-xxxs'">
      <cs-button-text i18n="@@clickHere">Click Here</cs-button-text>
      <cs-icon class="tw-h-4 tw-w-4" [iconName]='"chevron_right"'></cs-icon>
    </cs-button>
  </div>
  <div id="hair-section" class="tw-relative tw-flex-1 tw-bg-gray-80 tw-pb-8 tw-px-4 tw-pt-20 tw-rounded-2.5 tw-shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]" (click)="onSelectConcern(appConfig.Shared.Regimen.Class.HAIR)">
    <img class="tw-absolute tw-block tw-h-[90px]" style="top: -28px;" [src]="hairIconUrl" />
    <div class="tw-font-body tw-font-bold tw-text-500 tw-text-[20px] tw-text-gray-800 tw-mb-6" i18n="@@forHair"> For hair problems </div>
    <cs-button [class]="'tw-w-full primary btn-xxxs'">
      <cs-button-text i18n="@@clickHere">Click Here</cs-button-text>
      <cs-icon class="tw-h-4 tw-w-4" [iconName]='"chevron_right"'></cs-icon>
    </cs-button>
  </div>
</div>
