<div class="tw-flex tw-justify-between tw-items-center tw-font-body tw-mt-3 tw-mb-1 tw-px-4">
  <h3 class="tw-text-gray-900 tw-font-bold" i18n="@@chooseRightPlan"> Choose your right plan </h3>
  <h3 id="read-more" class="tw-flex tw-items-center tw-text-green-500 tw-text-100 tw-mr-2 tw-lowercase tw-font-bold" *ngIf="!regimen?.orderPlaced" (click)="readMore()">
    <span i18n="@@readMore">Read More</span>
    <cs-icon class="tw-h-4 tw-w-4 tw-ml-1 tw-animate-bounce_x tw-text-green-400" *ngIf="!regimen?.orderPlaced" (click)="readMore()" [iconName]='"chevron_right"'></cs-icon>
  </h3>
</div>
<section class="tw-font-body tw-overflow-scroll tw-pb-3" #scroll>
  <div class="tw-flex tw-text-center tw-pl-2" [ngClass]="{'tw-mt-2': showIntroductoryKit || showAdvanceTag, 'tw-text-100': user?.get('languagePreference') === 'ta', 'tw-text-300': user?.get('languagePreference') !== 'ta'}">
    <div class="tw-mx-1 tw-overflow-visible tw-flex-1 tw-relative tw-rounded-xl tw-my-2 tw-shadow-z1" *ngIf="showIntroductoryKit" [ngStyle]="{ 'background-image': selectedPlan === 'introductory' ? 'url(/assets/images/gold-shine.gif)' : 'none' }">
      <div id="introductory" class="tw-overflow-visible tw-rounded-xl" [ngStyle]="{'margin': selectedPlan === 'introductory' ? '3px' : '1px'}" style="min-width: 178px; max-height: 90px" [ngClass]="{'tw-bg-teal-100': selectedPlan === 'introductory', 'tw-bg-gray-100': selectedPlan !== 'introductory', 'shine': selectedPlan === 'introductory'}" (click)="selectPlan('introductory')">
        <div class="tw-rounded-xl tw-rounded-b-none tw-flex tw-items-center tw-justify-center tw-h-[30px]" [ngClass]="{'tw-bg-teal-200': selectedPlan === 'introductory', 'tw-bg-gray-200': selectedPlan !== 'introductory'}">
          <img class="tw-mx-1 tw-w-[15px] tw-h-[15px]" *ngIf="selectedPlan === 'introductory'" src="/assets/images/correct.png" />
          <span class="tw-font-bold">
            <span>3&nbsp;</span>
            <span i18n="@@products">Products</span>
            <span>&nbsp;</span>
            <span>&#64; ₹{{variantCosts.introductory}}</span>
          </span>
        </div>
        <div class="tw-px-1 tw-p-2" style="color: #2f2f2f" [ngClass]="{'tw-text-100': user?.get('languagePreference') === 'ta', 'tw-text-200': user?.get('languagePreference') !== 'ta'}">
          <p i18n="@@seeImprov89">Improvement starts in <br /><b> 8-9 weeks</b></p>
        </div>
      </div>
      <div class="tw-absolute gold-button shine loop tw-font-bold tw-text-200 tw-z-100 tw-px-2 tw-top-[-12px] tw-left-[15px]" i18n="@@newPlan"> NEW </div>
    </div>
    <div id="advance" class="tw-rounded-xl tw-mx-1 tw-my-2 tw-border tw-border-gray-200 tw-overflow-hidden tw-flex-1 tw-shadow-z1" style="min-width: 178px; max-height: 90px" [ngClass]="{'tw-bg-teal-100': selectedPlan === 'advance', 'tw-bg-gray-100': selectedPlan !== 'advance', 'tw-mt-3': selectedPlan === 'introductory'}" (click)="selectPlan('advance')">
      <div class="tw-flex tw-items-center tw-justify-center tw-h-[30px]" [ngClass]="{'tw-bg-teal-200': selectedPlan === 'advance', 'tw-bg-gray-200': selectedPlan !== 'advance'}">
        <img class="tw-mx-1 tw-w-[15px] tw-h-[15px]" *ngIf="selectedPlan === 'advance'" src="/assets/images/correct.png" />
        <span class="tw-font-bold">
          <span>4&nbsp;</span>
          <span i18n="@@products">Products</span>
          <span>&nbsp;</span>
          <span>&#64; ₹{{variantCosts.advance}}</span>
        </span>
      </div>
      <div class="tw-px-1 tw-p-2" style="color: #2f2f2f" [ngClass]="{'tw-text-100': user?.get('languagePreference') === 'ta', 'tw-text-200': user?.get('languagePreference') !== 'ta'}">
        <p i18n="@@seeImprov46">Improvement starts in <br /><b>4-6 weeks</b></p>
      </div>
    </div>
    <ng-container *ngIf="showAdvanceTag && !showIntroductoryKit ">
      <div class="tw-mx-1 tw-overflow-visible tw-flex-1 tw-relative tw-rounded-xl tw-my-2 tw-shadow-z1" *ngIf="!isStandAloneVariant" [ngStyle]="{ 'background-image': selectedPlan === 'pro' ? 'url(/assets/images/gold-shine.gif)' : 'none' }">
        <div id="pro" class="tw-overflow-visible tw-rounded-xl" style="min-width: 178px; max-height: 90px" [ngStyle]="{'margin': selectedPlan === 'pro'? '3px': '1px'}" [ngClass]="{'tw-bg-orange-100': selectedPlan === 'pro', 'tw-bg-gray-100': selectedPlan !== 'pro'}" (click)="selectPlan('pro')">
          <div class="tw-rounded-xl tw-rounded-b-none tw-flex tw-items-center tw-justify-center tw-h-[30px]" [ngClass]="{'tw-bg-orange-200': selectedPlan === 'pro', 'tw-bg-gray-200': selectedPlan !== 'pro'}">
            <img class="tw-mx-1 tw-w-[15px] tw-h-[15px]" *ngIf="selectedPlan === 'pro'" src="/assets/images/correct.png" />
            <span class="tw-font-bold">
              <span>5&nbsp;</span>
              <span i18n="@@products">Products</span>
              <span>&nbsp;</span>
              <span>&#64; ₹{{variantCosts.pro}}</span>
            </span>
          </div>
          <div class="tw-px-1 tw-p-2" style="color: #2f2f2f" [ngClass]="{'tw-text-100': user?.get('languagePreference') === 'ta', 'tw-text-200': user?.get('languagePreference') !== 'ta'}">
            <p class="tw-mb-1" i18n="@@seeImprov34">Improvement starts in <br /><b>3-4 weeks</b></p>
          </div>
        </div>
        <div class="tw-absolute gold-button tw-font-bold tw-text-200 tw-z-100 tw-px-2 tw-top-[-12px] tw-left-[15px]" i18n="@@advanceTxt"> ADVANCE </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!showAdvanceTag || showIntroductoryKit">
      <div id="pro" class="tw-rounded-xl tw-mx-1 tw-my-2 tw-border tw-border-gray-200 tw-overflow-hidden tw-flex-1 tw-shadow-z1" *ngIf="!isStandAloneVariant" style="min-width: 178px; max-height: 90px" [ngClass]="{'tw-bg-orange-100': selectedPlan === 'pro', 'tw-bg-gray-100': selectedPlan !== 'pro', 'tw-mt-3': selectedPlan === 'introductory'}" (click)="selectPlan('pro')">
        <div class="tw-flex tw-items-center tw-justify-center tw-h-[30px]" [ngClass]="{'tw-bg-orange-200': selectedPlan === 'pro', 'tw-bg-gray-200': selectedPlan !== 'pro'}">
          <img class="tw-mx-1 tw-w-[15px] tw-h-[15px]" *ngIf="selectedPlan === 'pro'" src="/assets/images/correct.png" />
          <span class="tw-font-bold">
            <span>5&nbsp;</span>
            <span i18n="@@products">Products</span>
            <span>&nbsp;</span>
            <span>&#64; ₹{{variantCosts.pro}}</span>
          </span>
        </div>
        <div class="tw-px-1 tw-p-2" style="color: #2f2f2f" [ngClass]="{'tw-text-100': user?.get('languagePreference') === 'ta', 'tw-text-200': user?.get('languagePreference') !== 'ta'}">
          <p class="tw-mb-1" i18n="@@seeImprov34">Improvement starts in <br /><b>3-4 weeks</b></p>
        </div>
      </div>
    </ng-container>
    <div id="assist" class="tw-rounded-xl tw-mx-1 tw-my-2 tw-border tw-border-gray-200 tw-overflow-hidden tw-flex-1 tw-shadow-z1" *ngIf="!isStandAloneVariant" style="min-width: 240px; max-height: 90px" [ngClass]="{'tw-bg-blue-100': selectedPlan === 'assist', 'tw-bg-gray-100': selectedPlan !== 'assist', 'tw-mt-3': selectedPlan === 'introductory'}" (click)="selectPlan('assist')">
      <div class="tw-flex tw-items-center tw-justify-center tw-h-[30px]" [ngClass]="{'tw-bg-blue-200': selectedPlan === 'assist', 'tw-bg-gray-200': selectedPlan !== 'assist'}">
        <img class="tw-mx-1 tw-w-[15px] tw-h-[15px]" *ngIf="selectedPlan === 'assist'" src="/assets/images/correct.png" />
        <span class="tw-font-bold" i18n="@@5Prod1to1Assist">5 Products With 1:1 &#64; ₹{{variantCosts.assist}}</span>
      </div>
      <div class="tw-px-1 tw-p-2" [ngClass]="{'tw-text-100': user?.get('languagePreference') === 'ta', 'tw-text-200': user?.get('languagePreference') !== 'ta'}">
        <p i18n="@@seeImprov1to1">See improvement with <br /><b>1:1 Doctor Consultation</b></p>
      </div>
    </div>
  </div>
</section>
