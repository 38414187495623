<!-- New UI-->
<div class="tw-flex tw-flex-col tw-h-full tw-bg-gray-100">
  <!-- back button-->
  <div class="tw-fixed tw-top-3 tw-left-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
    <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
  </div>
  <!-- service list-->
  <loading class="tw-flex-1 tw-self-center tw-justify-self-center" *ngIf="loading"></loading>
  <div class="tw-flex-1 tw-flex tw-flex-col tw-overflow-y-auto tw-pt-16" *ngIf="!loading">
    <header class="tw-px-6 tw-pt-2 tw-pb-5">
      <h1 class="tw-font-body tw-font-bold tw-text-600 tw-text-green-400 tw-mb-1.5" i18n="@@whatWillYouGet">
        What will you get in ₹{{regimen.totalSP}}
      </h1>
    </header>
    <section class="tw-p-6 tw-pb-4 tw-bg-purple-100 tw-flex tw-flex-row tw-items-center tw-gap-6">
      <div class="tw-flex tw-items-start tw-justify-center tw-h-28 tw-w-28 tw-rounded-full tw-bg-purple-200 tw-overflow-hidden">
        <img class="tw-flex-none tw-block tw-h-24 tw-w-24" [src]="regimen?.regimenCoverImage" />
      </div>
      <div class="tw-flex-1">
        <h2 class="tw-font-body tw-font-bold tw-text-400 tw-text-gray-900 tw-mb-1" i18n="@@cureskinGuaranteeNew">
          100% Effectiveness
        </h2>
        <p class="tw-font-body tw-text-200 tw-text-gray-900" i18n="@@cureskinGuaranteeDescNew">
          Clinically-tested ingredients and customised products to ensure you
          get visible results.
        </p>
      </div>
    </section>
    <section class="tw-p-6 tw-pb-4 tw-bg-teal-100 tw-flex tw-flex-row tw-items-center tw-gap-6">
      <div class="tw-flex tw-items-start tw-justify-center tw-h-28 tw-w-28 tw-rounded-full tw-bg-green-100 tw-overflow-hidden tw-relative">
        <img class="tw-flex-none tw-block tw-h-24 tw-w-24 tw-rounded-full tw-absolute tw-bottom-0" src="https://cdn.cureskin.com/dr-checkup.png" />
      </div>
      <div class="tw-flex-1">
        <h2 class="tw-font-body tw-font-bold tw-text-400 tw-text-gray-900 tw-mb-1" i18n="@@monthlyDoctorCheckup">
          Monthly Doctor Checkup
        </h2>
        <p class="tw-font-body tw-text-200 tw-text-gray-900" *ngIf="regimen.class === appConfig.Shared.Regimen.Class.HAIR" i18n="@@monthlyDoctorDescNewHair">Free routine checkups with a Cureskin doctor to measure your
          hair progress in detail.</p>
        <p class="tw-font-body tw-text-200 tw-text-gray-900" *ngIf="regimen.class === appConfig.Shared.Regimen.Class.FACE" i18n="@@monthlyDoctorDescNewSkin">Free routine checkups with a Cureskin doctor to measure your
          skin progress in detail.</p>
      </div>
    </section>
    <section class="tw-p-6 tw-pb-4 tw-bg-orange-100 tw-flex tw-flex-row tw-items-center tw-gap-6">
      <img class="tw-flex-none tw-block tw-h-24 tw-w-24 tw-rounded-full" src="https://cdn.cureskin.com/diet.png" />
      <div class="tw-flex-1">
        <h2 class="tw-font-body tw-font-bold tw-text-400 tw-text-gray-900 tw-mb-1" i18n="@@lifeStyleGuidance">
          Lifestyle Guidance
        </h2>
        <p class="tw-font-body tw-text-200 tw-text-gray-900" i18n="@@lifeStyleGuidanceDescNew">
          Tips to lead a healthy, hygienic, and stress-free life so that you
          glow from within.
        </p>
      </div>
    </section>
    <section class="tw-p-6 tw-pb-4 tw-bg-blue-100 tw-flex tw-flex-row tw-items-center tw-gap-6">
      <img class="tw-flex-none tw-block tw-h-24 tw-w-24 tw-rounded-full" src="https://cdn.cureskin.com/app/img/regimen-service-4.png" />
      <div class="tw-flex-1">
        <h2 class="tw-font-body tw-font-bold tw-text-400 tw-text-gray-900 tw-mb-1" i18n="@@freeHomeDelivery">
          Free Home Delivery On Online Payment
        </h2>
        <p class="tw-font-body tw-text-200 tw-text-gray-900" i18n="@@freeHomeDeliveryDescNew">
          No shipping charges or hidden costs. Only an effective treatment kit
          delivered right to your doorstep.
        </p>
      </div>
    </section>
    <section class="tw-p-6 tw-pb-4 tw-bg-yellow-100 tw-flex tw-flex-row tw-items-center tw-gap-6">
      <img class="tw-flex-none tw-block tw-h-24 tw-w-24 tw-rounded-full" src="https://cdn.cureskin.com/support.png" />
      <div class="tw-flex-1">
        <h2 class="tw-font-body tw-font-bold tw-text-400 tw-text-gray-900 tw-mb-1" *ngIf="regimen.class === appConfig.Shared.Regimen.Class.FACE" i18n="@@personalSkinAdvisor">
          Personal Skin Advisor
        </h2>
        <p class="tw-font-body tw-text-200 tw-text-gray-900" *ngIf="regimen.class === appConfig.Shared.Regimen.Class.FACE" i18n="@@personalSkinAdvisorDescNew">
          A team of skin advisors made available 24X7 on chat, to solve your
          doubts and support your healthy skin journey.
        </p>
        <h2 class="tw-font-body tw-font-bold tw-text-400 tw-text-gray-900 tw-mb-1" *ngIf="regimen.class === appConfig.Shared.Regimen.Class.HAIR" i18n="@@personalHairAdvisor">
          Personal Hair Advisor
        </h2>
        <p class="tw-font-body tw-text-200 tw-text-gray-900" *ngIf="regimen.class === appConfig.Shared.Regimen.Class.HAIR" i18n="@@personalHairAdvisorDesc">
          A team of hair advisors made available 24X7 on chat, to solve your
          doubts and support your healthy hair journey.
        </p>
      </div>
    </section>
  </div>
  <!-- footer-->
  <footer class="tw-flex-none" *ngIf="!loading && !regimen.active && !regimen.orderPlaced">
    <buy-now-footer [regimen]="regimen" (buyNow)="buyRegimen()"></buy-now-footer>
  </footer>
</div>
