<section class="tw-flex tw-flex-col tw-bg-white tw-px-4 tw-py-6 tw-my-6">
  <div class="tw-font-body tw-font-bold tw-text-gray-800 tw-text-600 tw-mb-4" i18n="@@clearYourDoubts">
    Clear your doubts
  </div>
  <div class="tw-w-full tw-flex tw-items-stretch tw-gap-2">
    <div *ngFor="let data of doubtsData; let i = index" id="doubts"
         class="tw-flex tw-flex-col tw-w-full tw-bg-teal-100 tw-rounded-[15px] tw-min-h-50" (click)="playVideo(i)">
      <div class="tw-px-4 tw-py-4 tw-flex tw-gap-2 tw-justify-between tw-items-start">
        <h1 class="tw-flex-1 tw-font-body tw-text-400 tw-text-gray-800">{{ data.title }}</h1>
        <cs-icon *ngIf="!isPlaying" id="play-button" class="tw-h-8 tw-w-8"
                 [iconName]="'/rebranding/v1/play_icon'"></cs-icon>
      </div>
      <img src={{data.image}} class="tw-w-full tw-object-cover tw-rounded-b-[15px] tw-mt-auto" alt="">
    </div>
  </div>
  <!-- Video container -->
  <div id="video-container" [ngClass]="{'tw-hidden' : !isPlaying}"
       class="video-container tw-flex tw-flex-col tw-justify-center tw-items-center tw-fixed tw-h-full tw-w-full tw-inset-0 bottom-0 tw-bg-black tw-z-50 tw-min-h-screen">
    <video-player id="videoPlayer" [src]="activeVideo" [autoplay]="true" [poster]="activeThumbnail" [loop]="false" (onVideoEnd)="onVideoEnd()"
                  class="video-container tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full tw-inset-0 bottom-0 tw-z-10 tw-min-h-screen"></video-player>
    <span id="close-video"
          class="tw-absolute tw-top-4 tw-right-3 tw-rounded-full tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-center tw-text-white tw-font-bold tw-text-xl tw-z-10 close-video-button"
          (click)="closeVideo()">
        <svg class="tw-h-6 tw-w-6" fill="currentColor" viewBox="0 0 24 24">
          <path
            d="M18.3 5.71a.996.996 0 00-1.41 0L12 10.59 7.11 5.7A.996.996 0 105.7 7.11L10.59 12 5.7 16.89a.996.996 0 101.41 1.41L12 13.41l4.89 4.89a.996.996 0 101.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z">
          </path>
        </svg>
      </span>
  </div>
</section>
<section class="banners" *ngFor="let banner of bannersTop" (click)="handleBanner(banner)">
  <img [src]="imageService.getURL(banner.image)" />
</section>
