import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { TestimonialCaseStudiesInfo, TestimonialCaseStudy } from 'e2e/src/shared/constants';
import { EventLoggerService } from '@services/event-logger-service';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'testimonial-case-studies-info',
  templateUrl: './testimonial-case-studies-info.html',
  styleUrls: [],
})

export class TestimonialCaseStudiesInfoComponent {
  testimonialCaseStudy: TestimonialCaseStudy;
  caseStudyId: number = 0;
  user: any;
  hideRegimenCTA: boolean;

  constructor(private broadcast: BroadcastService,
    private route: ActivatedRoute,
    private conn: ConnectionService,
    public appConfig: AppConfig,
    private router: Router,
    private eventLogger: EventLoggerService) { }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe((params: ParamMap): void => {
      this.caseStudyId = Number(params.get('caseStudyId'));
    });
    this.user = this.conn.getActingUser();
    await this.setHideRegimenCTAFlag();
    const language = this.user.get('languagePreference') || this.appConfig.Shared.Languages.EN;
    this.testimonialCaseStudy = TestimonialCaseStudiesInfo[language][this.caseStudyId];
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'testimonials-case-studies' }));
  }

  /*
  1. If only one regimen is allocated, just check for orderPlaced Status.
  2. If two regimens are allocated, faceRegimen && orderPlaced status should be checked.
  */
  async setHideRegimenCTAFlag(): Promise<void> {
    const regimens = await this.conn.fetchRegimens(null, true);
    if (regimens.length > 1) {
      this.hideRegimenCTA = regimens.some((regimen: any): boolean => regimen.class
      === this.appConfig.Shared.Regimen.Class.FACE && regimen.orderPlaced);
    } else {
      this.hideRegimenCTA = regimens.some((regimen: any): boolean => regimen.orderPlaced);
    }
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  get testimonialImageUrl(): string {
    const name = this.testimonialCaseStudy.imageName;
    return `https://cdn.cureskin.com/app/img/testimonials/case-studies/${name}.png`;
  }

  redirectToRegimenPage(): void {
    this.router.navigate(['/user'], { queryParams: { tab: 'regimen' } });
  }
}
