<div class="tw-flex tw-flex-col tw-h-full tw-bg-gray-80 tw-pb-40 tw-overflow-scroll">
  <div id="backButton" *ngIf="!showInfo" class="tw-fixed tw-left-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
    <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
  </div>
  <ng-container *ngIf="showInfo; then howMyCityInfoView else defaultView"></ng-container>
  <ng-template #defaultView>
    <!-- Default view -->
     <div class="tw-flex-1 tw-p-6 tw-flex tw-flex-col">
      <div class="tw-font-body tw-font-bold tw-text-800 tw-text-gray-800 tw-mt-14 tw-w-4/5" i18n="@@yourCityAffectsYourSkinAndHair1"> Your city affects your Skin & Hair </div>
      <img class="tw-block tw-w-[75px] tw-h-[25px] tw-mt-2" src="https://cdn.cureskin.com/app/img/cs-icon.svg" />
      <p class="tw-font-body tw-text-300 tw-text-gray-800 tw-mt-6 tw-w-[70%]" i18n="@@tellYourDoctorWhichCityYouLive"> Tell your doctor which city you live in, so that they can give you the best treatment </p>
      <p id="how-my-city-affects-skin-and-hair-text" class="tw-font-body tw-font-bold tw-text-300 tw-text-teal-600 tw-mt-5 tw-underline" (click)="showInfo = !showInfo" i18n="@@howMyCityAffectsMySkinAndHair"> But how my city affects my skin & hair? </p>
    </div>
  </ng-template>
  <ng-template #howMyCityInfoView>
    <!-- How my city affects my skin and hair view -->
     <div class="tw-flex-1 tw-p-6 tw-flex tw-flex-col">
      <div class="tw-flex tw-gap-[2px] tw-items-center tw-mb-5 tw-pt-[46px]">
        <cs-icon id="hide-detailed-view-btn" class="tw-h-2 tw-w-5 tw-h-5" [iconName]="'/rebranding/v1/chevron_left'" (click)="showInfo = !showInfo"></cs-icon>
        <p class="tw-font-body tw-font-bold tw-text-300 tw-text-teal-600 tw-underline" i18n="@@howMyCityAffectsMySkinAndHair"> But how my city affects my skin & hair? </p>
      </div>
      <p class="tw-font-body tw-text-500 tw-text-gray-800 tw-mb-9 tw-w-[85%] tw-font-normal" i18n="@@climateAffects"> Your area's climate can be a major factor behind your skin or hair concerns. </p>
      <p class="tw-font-body tw-text-100 tw-text-gray-800 tw-uppercase tw-mb-3" i18n="@@forExample"> For eg: </p>
      <div class="tw-flex tw-flex-col tw-gap-2">
        <div class="tw-flex tw-items-center tw-gap-4 tw-p-2 tw-bg-gray-50 tw-rounded-[10px]">
          <img src="https://cdn.cureskin.com/app/img/bangalore_v1.svg" />
          <p class="tw-font-body tw-text-300 tw-text-gray-800 tw-w-3/5" i18n="@@bangloreHardWater"> Bangalore's hard water can cause heavy hair fall </p>
        </div>
        <div class="tw-flex tw-items-center tw-gap-4 tw-p-2 tw-bg-gray-50 tw-rounded-[10px]">
          <img src="https://cdn.cureskin.com/app/img/delhi_v1.svg" />
          <p class="tw-font-body tw-text-300 tw-text-gray-800 tw-w-[70%]" i18n="@@delhiPollution"> Delhi's pollution is a major factor behind pigmentation </p>
        </div>
        <div class="tw-flex tw-items-center tw-gap-4 tw-p-2 tw-bg-gray-50 tw-rounded-[10px]">
          <img src="https://cdn.cureskin.com/app/img/mumbai_v1.svg" />
          <p class="tw-font-body tw-text-300 tw-text-gray-800 tw-w-[70%]" i18n="@@mumbaisHeavy"> Mumbai's heavy monsoons can trigger acne problems </p>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="tw-fixed tw-bottom-0 tw-right-0 tw-left-0 tw-p-6 tw-pt-8 tw-flex tw-flex-col tw-gap-2 tw-items-stretch tw-bg-white tw-rounded-tr-5 tw-rounded-tl-5">
    <cs-button id="location-btn" [class]="'tw-w-full primary tw-font-bold'" [loading]="loading" (callback)="getLocation()">
      <cs-button-text>
        <span i18n="@@userYourLocation">Use Your Location</span>
      </cs-button-text>
    </cs-button>
    <cs-button id="continue-btn" class="tw-mt-[5px]" [class]="'tw-w-full secondary tw-font-bold'" [disabled]="loading" style="background: unset" (callback)="continue()">
      <cs-button-text>
        <span i18n="@@noThanksNew">No, Thanks</span>
      </cs-button-text>
    </cs-button>
  </div>
</div>
