import { Component, EventEmitter, Output } from '@angular/core';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';

@Component({
  selector: 'checkup-due-banner',
  standalone: true,
  imports: [],
  templateUrl: './checkup-due-banner.html',
  styles: '',
})
export class CheckupDueBannerComponent {
  @Output('startFollowUp') startFollowUp: EventEmitter<any> = new EventEmitter();
  user: Table.User;
  userPatientName: string;
  doctor: { firstName: string; image: string } = { firstName: '', image: '' };

  constructor(
    private readonly conn: ConnectionService,
    private readonly eventLogger: EventLoggerService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.user = this.conn.getActingUser();
    this.userPatientName = this.user?.get('PatientName');
    await this.getDoctorDetails();
  }

  async getDoctorDetails(): Promise<void> {
    const allocatedDoctor = this.user?.get('allocatedDoctor');
    if (allocatedDoctor) {
      const doctorData = await this.conn.findUserByObjectId(allocatedDoctor.id);
      const doctorName = doctorData.get('DoctorDisplayName') || doctorData.get('username');
      this.doctor.firstName = doctorName.split(' ').slice(0, 2).join(' ') || '';
      this.doctor.image = doctorData.get('doctorDisplayImage');
    }
  }

  handleClick(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'level-two-checkup-due-banner' }));
    this.startFollowUp.emit('ANYTIME_FOLLOWUP_BANNER');
  }
}
