<div class="tw-fixed tw-left-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back.emit(true)" *ngIf="user">
  <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
</div>
<div class="tw-fixed tw-right-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" *ngIf="isPaidUser" routerLink="/cart">
  <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'shopping_cart'"></cs-icon><span class="tw-absolute tw-top-[2px] tw-right-[18%] tw-bg-black tw-font-body tw-font-bold tw-h-[14px] tw-text-200 tw-text-red-400">{{ numberOfProductsInCart }}</span>
</div>
<div class="tw-flex tw-flex-col tw-h-full">
  <div class="tw-flex-1 tw-overflow-y-auto">
  <swiper #swiperWrapperOne="" [config]="carouselConfig"><ng-container *ngFor="let image of images">
    <section class="tw-flex tw-items-center tw-justify-center tw-bg-purple-200 tw-mb-4" *swiperSlide>
      <img class="tw-block" *ngIf="image.type === 'image'" [src]="image?.url" [ngClass]="isOldProductImage ? 'tw-h-[430px]' : 'tw-w-full'" /><video class="tw-block tw-w-full" *ngIf="image.type === 'video'" controls="controls">
      <source type="video/mp4" [src]="image?.url" />
      </video>
    </section>
    </ng-container></swiper>
  <div class="tw-flex tw-px-4 tw-mb-4 tw-overflow-y-auto" *ngIf="images?.length > 1">
    <div class="tw-flex tw-items-center tw-pr-2" (click)="slidePrev()">
    <cs-icon class="tw-h-8 tw-w-8 tw-text-gray-500 tw-rotate-180" [iconName]="'chevron_right'"></cs-icon>
    </div>
    <div class="tw-w-auto tw-flex tw-items-center tw-gap-2" id="swiper-pagination"></div>
    <div class="tw-flex tw-items-center tw-pl-2" (click)="slideNext()">
    <cs-icon class="tw-h-8 tw-w-8 tw-text-gray-500" [iconName]="'chevron_right'"></cs-icon>
    </div>
  </div>
  <section class="tw-px-6 tw-mb-4 tw-relative">
    <div class="tw-absolute tw-right-6 tw-float-right tw-font-body tw-text-yellow-500 tw-text-100">
    {{ product?.quantity }}{{ product?.quantityUnit }}
    </div>
    <div class="tw-font-body tw-text-200 tw-mr-16 tw-tracking-[1px]">
    {{ product?.hiddenTitle }}
    </div>
    <div class="tw-font-body tw-font-bold tw-text-700">
    {{ product?.title }}
    </div>
  </section>
  <div class="tw-border-b tw-border-gray-200 tw-mx-6 tw-mb-4"></div>
  <section class="tw-px-6 tw-mb-8">
    <div class="tw-font-body tw-text-300 tw-text-gray-700">
    {{ product?.description }}
    </div>
  </section>
  <section class="tw-px-6 tw-mb-10" *ngIf="product?.featureList">
    <h2 class="tw-font-body tw-text-200 tw-px-3 tw-py-1 tw-inline-block tw-rounded-full tw-bg-blue-100 tw-uppercase tw-tracking-[1px]" i18n="@@features">
    Features
    </h2>
    <div class="tw-border-b tw-border-gray-200 tw-mb-6 tw-mt-[-13px]"></div>
    <div class="[&amp;_ul]:tw-list-disc [&amp;_ul]:tw-font-body [&amp;_ul]:tw-font-bold [&amp;_ul]:tw-text-300 [&amp;_ul]:tw-pl-6 [&amp;_ul]:tw-text-gray-700 [&amp;_ol]:tw-list-disc [&amp;_ol]:tw-font-body [&amp;_ol]:tw-font-bold [&amp;_ol]:tw-text-300 [&amp;_ol]:tw-pl-6 [&amp;_ol]:tw-text-gray-700" [innerHtml]="product?.featureList"></div>
  </section>
  <section class="tw-px-6 tw-mb-10" *ngIf="product?.ingredientList">
    <h2 class="tw-font-body tw-text-200 tw-px-3 tw-py-1 tw-inline-block tw-rounded-full tw-bg-blue-100 tw-uppercase tw-tracking-[1px]" i18n="@@ingredients">
    Ingredients
    </h2>
    <div class="tw-border-b tw-border-gray-200 tw-mb-6 tw-mt-[-13px]"></div>
    <div class="[&amp;_h2]:tw-font-body [&amp;_h2]:tw-font-bold [&amp;_h2]:tw-text-300 [&amp;_h2]:tw-text-gray-800 [&amp;_ul]:tw-list-disc [&amp;_ul]:tw-font-body [&amp;_ul]:tw-text-300 [&amp;_ul]:tw-text-gray-700 [&amp;_ul]:tw-pl-8 [&amp;_ul]:tw-mb-5 [&amp;_ol]:tw-list-disc [&amp;_ol]:tw-font-body [&amp;_ol]:tw-text-300 [&amp;_ol]:tw-text-gray-700 [&amp;_ol]:tw-pl-8 [&amp;_ol]:tw-mb-5" [innerHtml]="product?.ingredientList"></div>
  </section>
  <section class="tw-px-6 tw-mb-10" *ngIf="product?.instructionList">
    <h2 class="tw-font-body tw-text-200 tw-px-3 tw-py-1 tw-inline-block tw-rounded-full tw-bg-blue-100 tw-uppercase tw-tracking-[1px]" i18n="@@howToUse">
    How to use
    </h2>
    <div class="tw-border-b tw-border-gray-200 tw-mb-6 tw-mt-[-13px]"></div>
    <div class="[&amp;_h2]:tw-font-body [&amp;_h2]:tw-font-bold [&amp;_h2]:tw-text-300 [&amp;_h2]:tw-text-gray-800 [&amp;_ul]:tw-list-disc [&amp;_ul]:tw-font-body [&amp;_ul]:tw-text-300 [&amp;_ul]:tw-text-gray-700 [&amp;_ul]:tw-pl-8 [&amp;_ul]:tw-mb-5 [&amp;_ol]:tw-list-disc [&amp;_ol]:tw-font-body [&amp;_ol]:tw-text-300 [&amp;_ol]:tw-text-gray-700 [&amp;_ol]:tw-pl-8 [&amp;_ol]:tw-mb-5" [innerHtml]="product?.instructionList"></div>
  </section>
  </div>
<!--  old buy now CTA-->
  <div class="tw-flex-none tw-shadow-z1 tw-animate-slide_in_bottom" *ngIf="isPaidUser && product.price !== 0 && !buyNowExperiment">
    <div class="tw-flex tw-item-center tw-justify-center tw-py-2 tw-bg-yellow-100" id="firstDiv" *ngIf="product.mrp !== product.price && +discount >= 5">
      <p class="tw-font-body tw-text-200 tw-text-black tw-flex tw-items-center tw-gap-1">
      <cs-icon class="tw-h-5 tw-w-5 tw-text-green-400" [iconName]="'sale'"></cs-icon>
      <span i18n="@@discountAppliedForProducts">
        {{ discount }}% discount applied</span>
      <span i18n="@@amountSaved">(₹{{ product.mrp - product.price }} saved)</span>
      </p>
    </div>
    <div class="tw-flex tw-gap-4 tw-items-center tw-p-5">
      <div class="tw-flex-none" *ngIf="product.mrp !== product.price">
        <del class="tw-font-body tw-font-bold tw-text-600 tw-text-gray-900">₹{{ product.mrp }}</del>
        <span class="tw-font-body tw-font-bold tw-text-600 tw-text-orange-500">&nbsp;₹{{ product.price }}</span>
      </div>
      <div class="tw-flex-none" *ngIf="product.mrp === product.price">
        <span class="tw-font-body tw-font-bold tw-text-600 tw-text-green-400">₹{{ product.price }}</span>
      </div>
      <div class="tw-flex-auto tw-flex tw-items-center tw-justify-center tw-h-12" *ngIf="isAddRemovePending">
        <loading></loading>
      </div>
      <div class="button tw-flex-auto" *ngIf="!productInCart && !isAddRemovePending">
      <cs-button [class]="'primary w100 shine tw-gap-2 tw-items-center tw-leading-none'" (callback)="addProductToCart()">
        <cs-button-text class="tw-font-body tw-font-bold tw-text-300 tw-tracking-normal" i18n="@@addToCart">Add to Cart</cs-button-text>
        <cs-icon class="tw-h-7 tw-w-7 tw-text-white" [iconName]="'chevron_right'"></cs-icon>
      </cs-button>
      </div>
      <div class="button tw-flex-auto" *ngIf="productInCart?.quantity && !isAddRemovePending">
        <div class="tw-border tw-rounded-full tw-border-green-400 tw-flex tw-items-center tw-justify-around tw-h-12">
          <div class="tw-flex-1 tw-flex tw-justify-center" (click)="removeProductFromCart()">
            <cs-icon class="tw-h-7 tw-w-7" [iconName]="'remove'"></cs-icon>
          </div>
          <p class="tw-flex-none tw-m-0 tw-font-body tw-font-300">{{ productInCart?.quantity }}</p>
          <div class="tw-flex-1 tw-flex tw-justify-center" (click)="addProductToCart()">
            <cs-icon class="tw-h-7 tw-w-7" [iconName]="'add'"></cs-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
<!--new buy now CTA-->
  <product-cta-container
    [isPaidUser]="isPaidUser"
    [discount]="discount"
    [buyNowExperiment]="buyNowExperiment"
    [appConfig]="appConfig"
    (addToCart)="addProductToCart()"
    [isAddRemovePending]="isAddRemovePending"
    (removeFromCart)="removeProductFromCart()"
    [product]="product"
    [productInCart]="productInCart"
    (handleBuyNow)="handleBuyNow()">
  </product-cta-container>
</div>
