<ng-container *ngIf="isRebranding; then rebrandedTemplate else originalTemplate"></ng-container>

<ng-template #originalTemplate>
     <div id="original" class="tw-block tw-w-full tw-h-full tw-border-spacing-0 tw-overflow-hidden tw-font-body tw-font-semibold">
          <div class="tw-flex tw-flex-col tw-flex-nowrap tw-h-full">
            <div class="tw-w-full tw-flex-initial">
              <div class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-[100]">
                <div class="tw-absolute tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10"
                     (click)="back()">
                  <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50"
                           [iconName]="'arrow_back'"></cs-icon>
                </div>
                <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white"
                      i18n="@@Select Photo Type">Select photo type</span>
              </div>
            </div>
            <div
                 class="tw-w-full tw-flex tw-flex-col tw-flex-nowrap tw-items-center tw-justify-evenly tw-p-[10px] tw-flex-auto tw-overflow-x-hidden tw-overflow-y-auto">
              <div class="tw-w-full tw-max-w-[400px] tw-flex tw-flex-wrap tw-items-center tw-justify-evenly">
                <div class="tw-w-[145px] tw-h-[150px] tw-overflow-hidden tw-border tw-border-solid tw-border-gray-200 tw-text-center tw-rounded-1 tw-flex tw-flex-col tw-flex-nowrap tw-justify-between "
                     rippleEffect="rippleEffect"
                     rippleClass="ripple-black"
                     (callback)="showPreview(appConfig.Shared.InstantCheckup.Type.FRONT_FACE)">
                  <div class="tw-py-3 tw-px-[7px] tw-text-[15px]">
                    <translate-string [key]="appConfig.Shared.InstantCheckup.Type.FRONT_FACE"></translate-string>
                  </div>
                  <img class="tw-w-[92px] tw-inline-block tw-my-0 tw-mx-auto"
                       *ngIf="user?.get('Gender') !== appConfig.Shared.Gender.MALE"
                       src="https://cdn.cureskin.com/app/img/take-photo-front-face.jpg" />
                  <img class="tw-w-[92px] tw-inline-block tw-my-0 tw-mx-auto"
                       *ngIf="user?.get('Gender') === appConfig.Shared.Gender.MALE"
                       src="https://cdn.cureskin.com/app/img/take-photo-front-face_male.jpg" />
                </div>
                <div class="imgHolder tw-w-[145px] tw-h-[150px] tw-overflow-hidden tw-border tw-border-solid tw-border-gray-200 tw-text-center tw-rounded-1 tw-flex tw-flex-col tw-flex-nowrap tw-justify-between "
                     rippleEffect="rippleEffect"
                     rippleClass="ripple-black"
                     (callback)="showPreview(appConfig.Shared.InstantCheckup.Type.SIDE_FACE)">
                  <div class="tw-py-3 tw-px-[7px] tw-text-[15px]">
                    <translate-string [key]="appConfig.Shared.InstantCheckup.Type.SIDE_FACE"></translate-string>
                  </div>
                  <img class="tw-w-[92px] tw-inline-block tw-my-0 tw-mx-auto"
                       *ngIf="user?.get('Gender') !== appConfig.Shared.Gender.MALE"
                       src="https://cdn.cureskin.com/app/img/take-photo-side-face.jpg" />
                  <img class="tw-w-[92px] tw-inline-block tw-my-0 tw-mx-auto"
                       *ngIf="user?.get('Gender') === appConfig.Shared.Gender.MALE"
                       src="https://cdn.cureskin.com/app/img/take-photo-side-face_male.jpg" />
                </div>
              </div>
              <div class="tw-w-full tw-max-w-[400px] tw-flex tw-flex-wrap tw-items-center tw-justify-evenly">
                <div class="tw-w-[145px] tw-h-[150px] tw-overflow-hidden tw-border tw-border-solid tw-border-gray-200 tw-text-center tw-rounded-1 tw-flex tw-flex-col tw-flex-nowrap tw-justify-between "
                     rippleEffect="rippleEffect"
                     rippleClass="ripple-black"
                     (callback)="showPreview(appConfig.Shared.InstantCheckup.Type.HAIR_FRONT)">
                  <div class="tw-py-3 tw-px-[7px] tw-text-[15px]">
                    <translate-string [key]="appConfig.Shared.InstantCheckup.Type.HAIR_FRONT"></translate-string>
                  </div>
                  <img class="tw-w-[92px] tw-inline-block tw-my-0 tw-mx-auto"
                       *ngIf="user?.get('Gender') !== appConfig.Shared.Gender.MALE"
                       src="https://cdn.cureskin.com/app/img/take-photo-front-face.jpg" />
                  <img class="tw-w-[92px] tw-inline-block tw-my-0 tw-mx-auto"
                       *ngIf="user?.get('Gender') === appConfig.Shared.Gender.MALE"
                       src="https://cdn.cureskin.com/app/img/take-photo-front-face_male.jpg" />
                </div>
                <div class="tw-w-[145px] tw-h-[150px] tw-overflow-hidden tw-border tw-border-solid tw-border-gray-200 tw-text-center tw-rounded-1 tw-flex tw-flex-col tw-flex-nowrap tw-justify-between "
                     rippleEffect="rippleEffect"
                     rippleClass="ripple-black"
                     (callback)="showPreview(appConfig.Shared.InstantCheckup.Type.HAIR_TOP)">
                  <div class="tw-py-3 tw-px-[7px] tw-text-[15px]">
                    <translate-string [key]="appConfig.Shared.InstantCheckup.Type.HAIR_TOP"></translate-string>
                  </div>
                  <img class="tw-w-[92px] tw-inline-block tw-my-0 tw-mx-auto"
                       *ngIf="user?.get('Gender') !== appConfig.Shared.Gender.MALE"
                       src="https://cdn.cureskin.com/app/img/take-photo-hair-top.jpg" />
                  <img class="tw-w-[92px] tw-inline-block tw-my-0 tw-mx-auto"
                       *ngIf="user?.get('Gender') === appConfig.Shared.Gender.MALE"
                       src="https://cdn.cureskin.com/app/img/take-photo-hair-top_male.jpg" />
                </div>
              </div>
              <div class="tw-w-full tw-max-w-[400px] tw-flex tw-flex-wrap tw-items-center tw-justify-evenly">
                <div class="tw-w-[145px] tw-h-[150px] tw-overflow-hidden tw-border tw-border-solid tw-border-gray-200 tw-text-center tw-rounded-1 tw-flex tw-flex-col tw-flex-nowrap tw-justify-between "
                     *ngIf="hasBodyRegimen"
                     rippleEffect="rippleEffect"
                     rippleClass="ripple-black"
                     (callback)="showPreview(appConfig.Shared.InstantCheckup.Type.BODY)">
                  <div class="tw-py-3 tw-px-[7px] tw-text-[15px]">
                    <translate-string [key]="appConfig.Shared.InstantCheckup.Type.BODY"></translate-string>
                    <span class="pL5"
                          i18n="@@photo">Photo</span>
                  </div>
                  <img class="tw-w-[92px] tw-inline-block tw-my-0 tw-mx-auto"
                       src="https://cdn.cureskin.com/app/img/take-photo-body.jpg" />
                </div>
                <div class="tw-w-[145px] tw-h-[150px] tw-overflow-hidden tw-border tw-border-solid tw-border-gray-200 tw-text-center tw-rounded-1 tw-flex tw-flex-col tw-flex-nowrap tw-justify-between "
                     rippleEffect="rippleEffect"
                     rippleClass="ripple-black"
                     (callback)="showPreview(appConfig.Shared.InstantCheckup.Type.REPORT)">
                  <div class="tw-py-3 tw-px-[7px] tw-text-[15px]"
                       i18n="@@uploadReports">Other Photos or Reports</div>
                  <img class="tw-w-[55px] tw-inline-block tw-my-0 tw-mx-auto"
                       src="https://cdn.cureskin.com/app/img/upload-report.svg" />
                </div>
              </div>
            </div>
            <div class="tw-w-full tw-flex-[0_1_0px] ">
              <div class="tw-text-center tw-uppercase tw-text-green-400 tw-text-200 tw-pt-1 tw-px-0 tw-pb-6 tw-tracking-[1px] tw-font-semibold"
                   (click)="viewUploadedPhotos()"
                   routerLink="/user/instantCheckup/list"
                   i18n="@@viewUploadedPhotos"> View Uploaded Photos </div>
            </div>
          </div>
        </div>
        <file-picker (onFileChange)="onFileChange($event)"
                     accept="image/jpeg"></file-picker>
        <file-picker (onFileChange)="uploadImageFromNativeCamera($event)"
                     accept="image/jpeg"></file-picker>
</ng-template>

<ng-template #rebrandedTemplate>
  <div id="rebranded" class="tw-w-full tw-h-[8.4%] tw-bg-gray-800">
    <div class="tw-h-full tw-flex tw-items-center tw-pl-4 tw-pr-8" (click)="back()">
       <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
       <span class="tw-text-300 tw-font-normal tw-font-display tw-text-gray-75 tw-mx-auto" i18n="@@Select Photo Type">Select photo type</span>
    </div>
  </div>
  <div class="tw-w-full tw-m-auto tw-px-7 tw-pb-6 tw-bg-gray-80 tw-overflow-y-scroll xxs:tw-h-[91.6%]">
    <div class="tw-h-full tw-flex tw-flex-col tw-justify-center">
      <div class="tw-w-full tw-grid tw-grid-cols-1 tw-max-w-[150px] tw-my-6 tw-gap-4 tw-mx-auto xxs:tw-grid xxs:tw-grid-cols-2 xxs:tw-grid-rows-3 xxs:tw-max-w-[400px]">
        <div *ngFor="let card of displayCards">
          <div class="tw-pt-6 tw-font-body tw-text-400 tw-text-gray-800 tw-rounded-3 tw-bg-white tw-shadow-z4" (click)="showPreview(card.param)">
            <div class="tw-w-max tw-mx-auto">
              <translate-string [key]="card.type"></translate-string>
            </div>
            <div class="tw-flex tw-items-center tw-justify-center">
              <div class="tw-mt-7 tw-inline-block tw-relative">
                <img class="tw-w-[68px] tw-relative tw-z-10 tw-m-auto tw-mb-[10px]" src="https://cdn.cureskin.com/app/img/Blue-scanner.png" alt="qr_rebranded"/>
                <img class="tw-w-[80px] tw-absolute tw-left-1/2 tw-transform -tw-translate-x-1/2 -tw-translate-y-[100%] tw-max-w-none" [src]="card.url" alt="user">
              </div>
            </div>
          </div>
        </div>
        <div class="tw-min-h-[150px] tw-pt-6 tw-font-body tw-text-400 tw-text-gray-800 tw-rounded-3 tw-bg-white tw-shadow-z4 xxs:tw-min-h-full" (click)="showPreview(appConfig.Shared.InstantCheckup.Type.REPORT)">
          <p class="tw-m-auto tw-text-center tw-lowercase first-letter:tw-capitalize" [ngClass]="{'tw-w-full': userLanguage === 'kn', 'tw-w-full tw-text-300': userLanguage === 'ta', 'tw-max-w-[12ch] tw-w-max': userLanguage !== 'ta' && userLanguage !== 'kn'}" i18n="@@uploadReports">Other Photos or Reports</p>
          <div class="tw-relative">
            <div class="tw-absolute tw-left-1/2 tw-transform -tw-translate-x-1/2 tw-translate-y-1/4 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-w-[50px] tw-h-[50px] tw-m-auto tw-bg-gray-100">
              <img class="tw-w-[24px] tw-m-auto" src="https://cdn.cureskin.com/app/img/upload.png" alt="upload_rebranded"/>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-w-full tw-max-w-[400px] tw-sticky tw-z-10 tw-mx-auto">
        <div class="tw-text-center tw-px-6 tw-py-3 tw-m-auto tw-border tw-border-teal-600 tw-border-solid tw-rounded-3">
          <div class="tw-text-300 tw-font-bold tw-font-body tw-text-teal-600" (click)="viewUploadedPhotos()" routerLink="/user/instantCheckup/list" i18n="@@viewUploadedPhotos">
            View Uploaded Photos
          </div>
        </div>
      </div>
    </div>
  </div>
  <file-picker (onFileChange)="onFileChange($event)" accept="image/jpeg"></file-picker>
  <file-picker (onFileChange)="uploadImageFromNativeCamera($event)" accept="image/jpeg"></file-picker>
</ng-template>