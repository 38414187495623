import { Component, OnInit, Input } from '@angular/core';
import { CsIconModule } from '@components/icon';
import { ConnectionService } from '@services/connection-service';
import { AppConfig } from 'client/app/app.config';
import { concernsTranslation } from '../../../../static-translations/concern-translations';

const REGIMEN_IMG_BACKGROUND_CLASS = {
  variant_999: 'tw-bg-teal-100',
  variant_1499: 'tw-bg-teal-100',
  variant_1999: 'tw-bg-orange-100',
  variant_4999: 'tw-bg-blue-100',
};

@Component({
  selector: 'regimen-kit-ready',
  templateUrl: './regimen-kit-ready.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
})
export class RegimenKitReadyComponent implements OnInit {
  @Input('user') user: any;
  @Input('userName') userName: string;
  @Input('class') class: string;
  @Input('regimen') regimen: any;
  @Input('coverImage') coverImage: string;
  @Input('doctorImage') doctorImage: string;
  concernDisplayName: string;
  imgSrcstr: string = '';
  isNewRegimenBanner: boolean = false;
  regimenImageBackgroundColor: string = '';

  constructor(
    public appConfig: AppConfig,
    public conn: ConnectionService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.userName = this.findUserDisplayName(this.userName);
    const experiments = await this.conn.findUserActiveExperiments();
    experiments.forEach((exp: any): void => {
      if (exp.key === 'new_regimen_banner') {
        this.isNewRegimenBanner = true;
      }
    });

    this.findConcernDisplayName();
    this.setRegimenImageBackgroundColor();
  }

  findConcernDisplayName(): void {
    const language = this.user?.get('languagePreference');
    const concernKey = this.regimen?.displayConcern?.name;
    const concernObject = concernsTranslation.find((item: { [key: string]: string }): boolean => item.en === concernKey);
    this.concernDisplayName = concernObject && concernObject[language] ? concernObject[language] : concernKey;
  }

  findUserDisplayName(name: string): string {
    return name?.split(' ')
      .slice(0, 1)
      .join('');
  }

  setRegimenImageBackgroundColor(): void {
    const variantId = this?.regimen?.selectedVariantId;
    this.regimenImageBackgroundColor = REGIMEN_IMG_BACKGROUND_CLASS[variantId] || REGIMEN_IMG_BACKGROUND_CLASS.variant_1499;
  }
}
