<div class="tw-container tw-mx-auto tw-py-2 tw-pt-0 tw-pl-4 tw-bg-white tw-my-2">
  <div class="tw-px-2 tw-py-2 tw-text-600 tw-font-semibold" i18n="@@mycsJourney"> My Cureskin journey </div>
  <div class="tw-overflow-x-scroll tw-whitespace-nowrap tw-space-x-4">
    <a class="tw-inline-block" *ngFor="let videoUrl of videoUrls; let i = index">
      <div class="tw-bg-gray-100 tw-relative tw-w-[230px]" (click)="onPlayButtonClick(videoUrl)">
        <img class="tw-relative tw-rounded-lg tw-object-contain tw-w-[230px] tw-h-[280px]" src="https://cdn.cureskin.com/app/img/testimonials/csjourneyvt_{{i+2}}.jpg" />
        <div class="tw-bg-fade-gradient tw-rounded-lg tw-absolute tw-inset-x-0 tw-bottom-0 tw-z-10 tw-h-[100px]"></div>
        <div class="tw-text-500 tw-absolute tw-font-bold tw-text-white tw-py-2 tw-px-2 tw-z-20 tw-bottom-0 tw-inset-x-0 tw-whitespace-break-spaces" *ngIf="videoTitles[i]">
          {{videoTitles[i]}}
        </div>
        <svg class="play-icon tw-w-12 tw-h-12 tw-absolute tw-inset-0 tw-m-auto tw-z-20 tw-flex tw-items-center tw-justify-center" fill="#ffffff" viewbox="0 0 24 24">
          <path d="M6 4l15 8-15 8z" transform="translate(10, 10)"></path>
        </svg>
      </div>
    </a>
  </div>
</div>
<div class="video-container tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-hidden tw-z-50">
  <div class="tw-bg-black tw-absolute tw-inset-0"></div>
  <video class="tw-h-full tw-w-full tw-object-contain tw-max-w-full tw-max-h-full tw-relative tw-z-10" (ended)="videoEnd()" #videoPlayer></video>
  <button class="tw-absolute tw-top-2 tw-right-1 tw-rounded-full tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-end tw-text-white tw-font-bold tw-text-xl tw-z-10 close-video-button" (click)="closeVideo()">
    <svg class="tw-h-6 tw-w-6" fill="currentColor" viewBox="0 0 24 24">
      <path d="M18.3 5.71a.996.996 0 00-1.41 0L12 10.59 7.11 5.7A.996.996 0 105.7 7.11L10.59 12 5.7 16.89a.996.996 0 101.41 1.41L12 13.41l4.89 4.89a.996.996 0 101.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path>
    </svg>
  </button>
</div>
