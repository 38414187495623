<div class="weekStrip tw-bg-white tw-text-center tw-m-auto tw-mb-6 tw-font-body tw-text-200 tw-pt-2">
    <span i18n="@@yourTreatment3">Your Treatment :</span><span><span class="tw-font-bold tw-mr-1">&nbsp;WEEK 3</span><img class="tw-inline-flex tw-mb-[2px]" src="/assets/icons/teal-tick-circle.svg" /></span>
</div>
<div class="title tw-text-center tw-text-600 tw-font-body tw-font-bold tw-mb-1 tw-m-auto tw-mb-2">
    <p class="tw-mx-4" i18n="@@monthlyCheckup">Your can now take your monthly checkup </p>
</div>
<div class="subTitle tw-text-center tw-mb-4 tw-font-body tw-text-300 tw-mx-4">
    <p i18n="@@checkUpExpiresOn">Checkup expires on {{ followUp?.get("deadline") | date: "dd MMM YYY" }}</p>
</div>
<div class="tw-text-center tw-mb-4 tw-mx-4">
    <cs-button class="tw-flex-none"
    [class]="'primary btn-xxxs btn-xxxs-padding'"
    (callback)="handleCTA()"
    [ngClass]="{'tw-text-100': user?.get('languagePreference') === 'ta' || user?.get('languagePreference') === 'kn', 'tw-text-200': user?.get('languagePreference') !== 'ta' && user?.get('languagePreference') !== 'kn'}">
      <cs-button-text i18n="@@takeCheckup" style="padding: 0 10px">Take Check-up</cs-button-text>
    </cs-button>
</div>
<swiper class="tw-pl-6 tw-pb-14 tw-pt-16" [config]="carouselConfigWeeklyJourney">
  <ng-container>
    <div class="bgClass firstCard tw-mr-3 tw-p-1 tw-h-full tw-box-border" *swiperSlide>
        <section class="cust-wid tw-flex tw-flex-col tw-items-start">
            <img class="cardImg tw-w-24 tw-h-24 tw-rounded-full tw-z-50 tw-absolute tw-object-cover tw-top-[-49px]" src="/assets/images/question_mark.png" />
            <div class="header tw-mt-11 tw-mb-4">
              <div class="tw-font-body tw-text-300 tw-font-bold tw-mb-2" i18n="@@weekThreeCardOne" style="word-break: break-word;">
                Experiencing Itching/Burning/Redness or seeing more acne breakout?
              </div>
            </div>
            <div class="tw-flex-auto tw-flex tw-w-fit tw-items-center tw-justify-center tw-gap-3 tw-pl-4 tw-pr-3 tw-h-10 tw-max-h-10 tw-mt-auto tw-mb-7 tw-border tw-border-gray-900 tw-font-body tw-font-bold tw-text-200 tw-text-green-500 tw-rounded-full" (click)="handleCardCTA('68tmQuvkpb')">
              <span class="tw-lowercase" i18n="@@readMore">Read More</span><cs-icon class="tw-h-4 tw-w-4 tw-text-green-400" [iconName]="'chevron_right'"></cs-icon>
            </div>
          </section>
    </div>
    <!-- 2 -->
    <section class="cust-wid tw-flex tw-flex-col tw-items-start tw-mr-2" *swiperSlide>
        <img class="cardImg tw-w-24 tw-h-24 tw-rounded-full tw-z-50 tw-absolute tw-object-cover tw-top-[-49px]" src="/assets/images/before-after.png" />
        <div class="header tw-mt-11 tw-mb-4">
          <div class="tw-font-body tw-text-300 tw-font-bold tw-mb-2" i18n="@@weekThreeCardTwo">
            When will I start to see changes in my skin?
          </div>
        </div>
        <div class="tw-flex-auto tw-flex tw-w-fit tw-items-center tw-justify-center tw-gap-3 tw-pl-4 tw-pr-3 tw-h-10 tw-max-h-10 tw-mt-auto tw-mb-8 tw-border tw-border-gray-900 tw-font-body tw-font-bold tw-text-200 tw-text-green-500 tw-rounded-full" (click)="handleCardCTA('7T2BaIQHxh')">
          <span class="tw-lowercase" i18n="@@knowMore">Know More</span><cs-icon class="tw-h-4 tw-w-4 tw-text-green-400" [iconName]="'chevron_right'"></cs-icon>
        </div>
      </section>
    <!-- 3 -->
    <section class="cust-wid tw-flex tw-flex-col tw-items-start tw-mr-2" *swiperSlide>
        <img class="cardImg tw-w-24 tw-h-24 tw-rounded-full tw-z-50 tw-absolute tw-object-cover tw-top-[-49px]" src="https://cdn.cureskin.com/app/img/home_checkup.png" />
        <div class="header tw-mt-11 tw-mb-4">
          <div class="tw-font-body tw-text-300 tw-font-bold tw-mb-2" i18n="@@weekThreeCardThree">
            How to take free monthly doctor checkups?
          </div>
        </div>
        <div class="tw-flex-auto tw-flex tw-w-fit tw-items-center tw-justify-center tw-gap-3 tw-pl-4 tw-pr-3 tw-h-10 tw-max-h-10 tw-mt-auto tw-mb-8 tw-border tw-border-gray-900 tw-font-body tw-font-bold tw-text-200 tw-text-green-500 tw-rounded-full" (click)="handleCardCTA()">
          <span class="tw-lowercase" i18n="@@checkHere">Check here</span><cs-icon class="tw-h-4 tw-w-4 tw-text-green-400" [iconName]="'chevron_right'"></cs-icon>
        </div>
      </section>
  </ng-container>
</swiper>
