<section class="tw-px-4">
  <div class="tw-p-4 tw-py-6 tw-bg-gray-200 tw-rounded-3 tw-flex tw-gap-0 tw-justify-between tw-shadow-z4">
    <div class="tw-pl-[9px] tw-pt-2">
      <h1 class="tw-text-500 tw-pt-2 tw-pb-4" i18n="@@doctorIsRequestingForYourPhoto">Doctor is <br/>requesting for <br/> your photo</h1>
      <button class="shine-button tw-bg-teal-600 tw-rounded-3 tw-text-300 tw-text-white tw-font-bold tw-p-0
      tw-font-body tw-shadow-z4 tw-ml-0 tw-h-[39px]"
              (click)="handleClick()" i18n="@@shareAgain">
        Share Again
      </button>
    </div>
    <div class="tw-w-[136px] tw-aspect-square tw-border tw-mr-2">
      <img class="tw-w-full"
           src="/assets/images/requesting-photo.svg"
           alt="requesting"/>
    </div>
  </div>
</section>
