<section class="tw-bg-white font-noto tw-pl-4 tw-my-2">
  <div class="tw-text-600 tw-mb-2 tw-font-semibold tw-p-3" i18n="@@caseStudies">
    Case studies
  </div>
  <div class="tw-overflow-x-scroll tw-whitespace-nowrap tw-space-x-2">
    <a class="tw-inline-block tw-pb-2" *ngFor="let testimonial of testimonialCaseStudies; let i = index">
      <div class="card tw-bg-green-100 tw-rounded-lg tw-p-4 tw-w-52 tw-flex tw-flex-col tw-justify-between" [ngClass]="getCardBgClass(i)" (click)="redirectToCaseStudiesInfo(i)">
        <img class="tw-object-cover tw-rounded-md tw-mb-4 tw-w-[108px]" src="https://cdn.cureskin.com/app/img/testimonials/case-studies/{{testimonial['imageName']}}-t.png" />
        <div class="tw-flex tw-justify-between tw-items-center tw-pb-[2.5]">
          <p class="tw-text-200 tw-text-black tw-w-auto tw-whitespace-normal tw-break-words">
            {{testimonial['name']}}
          </p>
        </div>
        <div class="tw-text-500 tw-text-black tw-font-semibold tw-mb-1" i18n="@@treatedFor">
          Treated for:
        </div>
        <div class="tw-text-500 tw-text-black tw-font-semibold tw-mb-1">
          {{testimonial['concern']}}
        </div>
        <div class="tw-flex tw-mt-2">
          <cs-button [class]="'primary btn-xxxs tw-px-2 tw-mt-auto'"><cs-button-text i18n="@@readMore">Read More</cs-button-text><cs-icon class="tw-h-4 tw-w-4" [iconName]='"chevron_right"'></cs-icon></cs-button>
        </div>
      </div>
    </a>
  </div>
</section>
