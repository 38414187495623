import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/index';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { BroadcastService } from '@services/broadcast-service';
import { WindowRefService } from '@services/window-ref-service';
import { AwsService } from '@services/aws-service';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { TimeService } from '@services/time-service';
import { DataStoreService } from '@services/data-store-service';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'app-checkup-capture',
  templateUrl: './checkup-capture.component.html',
  styles: [':host {@apply tw-block tw-h-full}'],
})
export class CheckupCaptureComponent implements OnInit {
  ui: { openCamera?: boolean, uploadingDone?: boolean } = {};
  canvas: any;
  scanningText: any;
  scanningIndex: number;
  user: any;
  canvasImage: any = {};
  fileName: any;
  assistantId: any;
  tag: string;
  nativeCameraImage: any;
  enableNativeCameraThroughWeb: boolean;
  imageSrc: any;
  subscriptions: Array<any> = [];
  interval: any;
  queryParams: { [key: string]: any };

  constructor(private broadcast: BroadcastService,
    private window: WindowRefService,
    private http: HttpClient,
    private aws: AwsService,
    public appConfig: AppConfig,
    private conn: ConnectionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private event: EventLoggerService,
    private awsService: AwsService,
    private timeService: TimeService,
    private dataStore: DataStoreService) { }

  async ngOnInit(): Promise<any> {
    const text = ['scanning for acne', 'scanning for comedones', 'scanning for pigmentation', 'scanning for melasma'];
    this.subscriptions.push(this.activatedRoute.queryParams.subscribe((params: any): void => {
      this.queryParams = params;
      this.tag = params.tag || this.appConfig.Shared.InstantCheckup.Type.FULL_FACE;
      this.tag = this.tag.toUpperCase();
      if ([this.appConfig.Shared.InstantCheckup.Type.FRONT_FACE,
        this.appConfig.Shared.InstantCheckup.Type.FULL_FACE,
        this.appConfig.Shared.InstantCheckup.Type.LEFT_SIDE_FACE,
        this.appConfig.Shared.InstantCheckup.Type.RIGHT_SIDE_FACE].includes(this.tag)) {
        this.scanningIndex = 0;
        this.scanningText = text[0];
        this.interval = setInterval((): void => {
          this.scanningIndex += 1;
          this.scanningIndex %= 4;
          this.scanningText = text[this.scanningIndex];
        }, 2500);
      }
      this.assistantId = params.assistantId;
      this.enableNativeCameraThroughWeb = (params.nativeCameraFile === 'true');
    }));
    this.ui = { openCamera: true, uploadingDone: false };
    this.user = this.conn.getActingUser();
    this.checkForNativeCameraImage();
    if (!this.user) this.back();
  }

  checkForNativeCameraImage(): any {
    if (this.enableNativeCameraThroughWeb) {
      this.ui.openCamera = false;
      const { file }: any = this.dataStore.get('IMAGE_FILE', {});
      if (!file) {
        this.showError('Image not found. Contact team.');
        this.back();
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (data: any): any => {
        this.nativeCameraImage = data.target.result;
        this.imageSrc = this.nativeCameraImage;
        this.uploadToAIServer();
      };
    }
  }

  cameraResult(canvas: any): void {
    this.event.trackEvent('instant_checkup_photo_taken_successfully', { username: this.user.get('username') });
    this.event.trackPeopleIncrement({ people_instant_checkup_photo_taken_successfully: 1 });
    this.closeCamera();
    this.canvas = canvas;
    if (!canvas || !canvas.nativeElement) {
      this.showError('Image Not Captured. Try Again');
      setTimeout((): void => this.back(), 4000);
      return;
    }
    const image = new Image();
    image.onload = (): void => {
      this.canvasImage.width = image.width;
      this.canvasImage.height = image.height;
      this.uploadToAIServer();
    };
    image.src = this.canvas.nativeElement.toDataURL('image/jpeg');
    this.imageSrc = image.src;
  }

  async uploadToAIServer(): Promise<any> {
    this.event.trackEvent('time_image_upload_analysis', { username: this.user.get('username') });
    const formData = new FormData();
    formData.append('file', this.dataURLtoFile(this.imageSrc));
    formData.append('userId', this.user.get('username'));
    if (this.conn.isInternalUser()) {
      const currentUser = this.conn.getCurrentUser();
      formData.append('sessionToken', currentUser.get('sessionToken'));
    } else {
      formData.append('sessionToken', this.user.get('sessionToken'));
    }
    const extraData = { ...this.queryParams };
    delete extraData.tag;
    delete extraData.nativeCameraFile;
    formData.append('instantCheckup', JSON.stringify({ type: this.tag, extraData: { ...extraData, analyzeHair: true } }));
    formData.append('testEnv', `${this.conn.isTestEnvironment}`);
    const headers = new HttpHeaders();
    const aiUrl = this.conn.getAiUrl();
    new Promise((resolve: (item: any) => void): void => {
      this.http.post(aiUrl, formData, { headers, responseType: 'text' })
        .subscribe((data: string): void => resolve(data));
    })
      .then((data: any): void => {
        const aiResponse = JSON.parse(data);
        this.event.trackEvent('image_upload_analysis_success', { username: this.user.get('username') });
        if (this.nativeCameraImage) {
          this.event.trackEvent('photo_taken_from_native_camera', {
            username: this.user.get('username'),
            instantCheckupId: aiResponse.objectId,
          });
        }
        if (this.queryParams.redirectTo) {
          setTimeout((): Promise<void> => this.conn.navigateToURL(this.queryParams.redirectTo, false, true), 2000);
        } else {
          this.router.navigate([`/user/instantCheckup/${aiResponse.objectId}`],
            {
              queryParams:
              {
                type: data.type,
                assistantId: this.assistantId,
                messageId: this.queryParams.messageId,
                className: this.queryParams.className,
              },
              replaceUrl: true,
            });
        }
        this.ui.uploadingDone = true;
      })
      .catch((error: any): void => {
        this.event.trackEvent('image_upload_analysis_failure', { username: this.user.get('username') });
        this.showError('Error Saving Image. Contact CureSkin Team.');
        setTimeout((): void => this.back(), 4000);
      });
  }

  closeCamera(back?: boolean): void {
    this.ui.openCamera = false;
    if (back) this.back();
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  showError(message: any): void {
    this.broadcast.broadcast('NOTIFY', { message });
  }

  dataURLtoFile(dataUrl: string): any {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const binaryString = Buffer.from(arr[1], 'base64').toString('binary');
    let { length }: any = binaryString;
    const u8arr = new Uint8Array(length);
    while (length) {
      length -= 1;
      u8arr[length] = binaryString.charCodeAt(length);
    }
    const blob = new Blob([u8arr], { type: mime });
    this.fileName = `cam3_v2_${this.user.get('username')}_${this.timeService.format(new Date(), 'MMddyyyy')}_${new Date().getTime()}.jpg`;
    return new File([blob], this.fileName, { type: mime });
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }
}
