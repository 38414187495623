<div class="tw-mx-4 tw-bg-teal-700 tw-p-4 tw-pt-8 tw-rounded-3 tw-text-white tw-relative ">
  <div class="tw-text-500 tw-mb-[15px] tw-leading-[20px]" i18n="@@hiYourDoctorCheckupIsDueToday">Hi <span class="tw-capitalize">{{ userPatientName }}</span>, Your doctor <br/> checkup is due today</div>
  <div class="tw-h-[1px] tw-bg-teal-200 tw-opacity-20 tw-mb-5"></div>
  <div class="tw-flex tw-items-center tw-justify-between tw-gap-2">
    <div class="tw-flex tw-items-center">
      <img src="{{doctor?.image}}" alt="doctor"
           class="tw-w-16 tw-h-16 tw-rounded-full tw-mr-2 tw-border-[1px] tw-shadow-z6" />
      <div class="tw-text-200 tw-text-gray-75">
        <div class="tw-font-bold">{{doctor?.firstName}}</div>
        <div i18n="@@skinDr">Skin Specialist</div>
      </div>
    </div>
    <div class="tw-relative tw-w-[128px]">
      <button class="shine-button tw-bg-teal-600 tw-rounded-3 tw-text-300 tw-text-white tw-font-bold
      tw-font-body tw-shadow-z6 tw-h-[39px] tw-w-[128px] tw-px-0" (click)="handleClick()" i18n="@@startNow">Start Now</button>
      <span class="tw-w-2 tw-h-2 tw-rounded-full tw-absolute tw-right-[12px] tw-top-[-3px] tw-transform">
        <img src="/assets/gifs/blinking-red-dot.gif" alt="red-blink-circle"/>
    </span>
    </div>
  </div>
</div>
