import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class OrderHelper {
  filterOrdersByType(orders: Array<Object>, type: string): Array<Object> {
    if (!orders) {
      return [];
    }
    return orders.filter((order: any): boolean => order.get('type') === type);
  }
}
