<div class="tw-flex tw-flex-col tw-h-full tw-bg-teal-200 tw-bg-repeat-x tw-bg-top">
  <div class="tw-absolute tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-gray-800 tw-rounded-[12px] tw-h-10 tw-w-10" (click)="back()" *ngIf="changeLanguage">
    <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
  </div>
  <div class="tw-flex-1 tw-p-6 tw-pb-8 tw-flex tw-flex-col">
    <div class="tw-font-body tw-font-bold tw-text-800 tw-text-gray-800 tw-pt-14 tw-mb-2" *ngIf="!changeLanguage">
      {{languageStrings.CHOOSE_LANGUAGE[language.code]}}
    </div>
    <div class="tw-font-body tw-font-bold tw-text-800 tw-text-gray-800 tw-pt-14 tw-mb-2" *ngIf="changeLanguage">
      {{languageStrings.CHANGE_LANGUAGE[language.code]}}
    </div>
    <img class="tw-block tw-w-[75px] tw-h-[25px]" src="https://cdn.cureskin.com/app/img/cs-icon.svg" />
  </div>
  <div class="tw-px-6 tw-py-0 tw-bg-white tw-h-[70%] tw-rounded-tr-5 tw-rounded-tl-5">
    <div class="tw-mt-[48px]">
      <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-w-full anim-group">
        <div id="language-holder" class="tw-h-16 tw-align-center tw-tracking-[0.5px] anim-fadeIn tw-rounded-2.5" *ngFor="let item of languages; let i = index" (click)="selectLanguage(item)">
          <div class="tw-bg-gray-80 tw-rounded-2.5 tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center tw-flex-col tw-flex-nowrap tw-text-gray-800 tw-font-bold tw-text-600 tw-lowercase" [ngClass]=" { 'tw-animate-anim_click': language.code === item.code } " style="box-shadow: none;">
            {{item.displayName}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
