
<div class="tw-mx-4">
  <div class="tw-bg-white tw-px-4 tw-py-5 tw-rounded-3xl tw-shadow-z1" (click)="handleClick()" >
    <div class="tw-w-full tw-flex tw-justify-between tw-items-start">
      <div class="tw-flex tw-flex-col tw-gap-1.5 tw-text-gray-900">
        <h1 class="tw-font-normal tw-text-[22px] tw-leading-tight tw-font-body" i18n="@@testimonialsBannerTitle">Transformed Lives of <br /> <span class="tw-font-semibold">17 Lakh+ Indians</span></h1>
      </div>
      <div class="tw-min-w-10 tw-flex tw-justify-end tw-items-center">
        <cs-icon class="tw-w-8 tw-h-8" [iconName]="'https://cdn.cureskin.com/app/img/rebranded_click-icon.svg'"></cs-icon>
      </div>
    </div>
    <div class="tw-w-36 tw-mt-1.5">
      <img src="/assets/images/testimonials.png" class="tw-w-full">
    </div>
    <div class="tw-my-2">
      <h1 class="tw-font-normal tw-text-200 tw-font-body tw-not-italic" i18n="@@testimonialsBannerSubTitle">Don’t take our word on it, trust our users</h1>
    </div>
  </div>
</div>
