import { Component, Input } from '@angular/core';
import { testimonialWallObject } from 'e2e/src/shared/constants';
import { ConnectionService } from '@services/connection-service';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'testimonial-wall',
  templateUrl: './testimonial-wall.html',
  styleUrls: [],
})
export class TestimonialWallComponent {
  @Input() regimen: any;
  user: any;
  @Input() experiments: any[] = [];
  concern: string;
  testimonialWallObject: any = testimonialWallObject;
  testimonialWallImages: string[] = [];
  language: string = 'en';

  constructor(public appConfig: AppConfig,
    private conn: ConnectionService) {
  }

  async ngOnInit(): Promise<void> {
    this.setWallImages();
  }

  setWallImages(): void {
    this.user = this.conn.getActingUser();
    const gender = this.user?.get('Gender') || 'Female';
    this.concern = this.regimen?.class || 'FACE';
    this.language = this.user?.get('languagePreference') === this.appConfig.Shared.Languages.HI ? 'hi' : 'en';

    if (this.concern === this.appConfig.Shared.Regimen.Class.FACE) {
      const numberOfImages = 4;
      this.testimonialWallImages = new Array(numberOfImages).fill('');
    } else {
      this.testimonialWallImages = this.testimonialWallObject[gender][this.language][this.concern];
    }
  }
}
