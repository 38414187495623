import { Component, EventEmitter, Output } from '@angular/core';
import { EventLoggerService } from '@services/event-logger-service';
import { ConnectionService } from '@services/connection-service';

@Component({
  selector: 'requesting-photo',
  standalone: true,
  imports: [],
  templateUrl: './requesting-photo.html',
  styles: '',
})
export class RequestingPhotoComponent {
  @Output('startFollowUp') startFollowUp: EventEmitter<any> = new EventEmitter();
  user: Table.User;

  constructor(private readonly eventLogger: EventLoggerService, private readonly conn: ConnectionService) {}

  async ngOnInit(): Promise<void> {
    this.user = this.conn.getActingUser();
  }

  handleClick(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'level-two-requesting-photo-banner' }));
    this.startFollowUp.emit('WAITING_FOR_IMAGE_BANNER_CLICKED');
  }
}
