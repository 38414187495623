<div class="tw-w-[calc(100%-93px)] tw-flex tw-flex-col tw-justify-between tw-gap-3">
  <div class="tw-flex tw-flex-col tw-gap-1">
    <ng-content select="take-photo-banner-title"></ng-content>
    <ng-content select="take-photo-banner-caption"></ng-content>  
  </div>
  <span class="tw-w-max tw-max-w-[90%] tw-bg-teal-600 tw-text-white tw-rounded-xl tw-text-300 tw-flex tw-justify-center tw-items-center tw-font-bold shine-button tw-relative tw-overflow-hidden tw-capitalize" [ngClass]="userLanguage === appConfig.Shared.Languages.TA ? 'tw-py-2.5 tw-px-2.5' : 'tw-py-3 tw-px-4'" i18n="@@takeSecurePhotoHeader"> Take secure photo </span>
</div>

<div class="tw-w-[93px] tw-flex tw-items-center tw-justify-end">
  <div class="tw-w-[93px] tw-h-[93px] tw-flex tw-justify-center tw-items-center tw-bg-gray-200 tw-rounded-full">
    <img class="tw-w-10 tw-h-10" src="https://cdn.cureskin.com/app/img/camera_rebranded.png" alt="camera_icon">
  </div>
</div>
