<section *ngIf="user" class="tw-px-4">
  <div class="tw-p-4 tw-py-6 tw-bg-white tw-rounded-3 tw-flex tw-gap-2 tw-justify-between tw-shadow-z4">
    <div>
      <div class="tw-flex tw-gap-2 tw-items-center">
        <img src="/assets/gifs/blinking-red-dot.gif" alt="red-blink-circle" class="tw-w-2"/>
        <p class="tw-text-orange-400 tw-text-200 tw-font-bold tw-capitalize" i18n="@@unread">
          unread
        </p>
      </div>
      <h1 class=" tw-pt-5 tw-pb-4" i18n="@@yourCheckupReportIsReady"
          [ngClass]="{'tw-text-300': [appConfig.Shared.Languages.TA].includes(user.toJSON().languagePreference),
          'tw-text-500': ![appConfig.Shared.Languages.TA].includes(user.toJSON().languagePreference)}"
      >Your checkup <br/>  report is ready</h1>
      <button class="shine-button tw-bg-teal-600 tw-rounded-3 tw-text-300 tw-text-white tw-font-bold tw-px-1
      tw-font-body tw-shadow-z4 tw-ml-0 tw-h-[40px] tw-capitalize"
      (click)="handleClick()"
      i18n="@@reportViewNow"
      >View now</button>
    </div>
    <div class="tw-relative tw-w-[136px] tw-h-[136px] tw-pt-1 tw-mr-2">
      <div *ngIf="beforeImage" class="tw-w-[102px] tw-h-[94px] tw-grayscale tw-absolute tw-opacity-50 tw-rounded-[9px]
            tw-border-white tw-border-[1px] tw-left-[-2px] tw-overflow-hidden">
        <img class="tw-w-[102px] tw-h-[94px] tw-object-cover" src="{{beforeImage}}" alt="before"/>
      </div>
      <div *ngIf="afterImage" class="tw-w-[102px] tw-h-[102px] tw-relative tw-top-8 tw-left-[29px] tw-rounded-[9px]
      tw-border-white tw-border-[1px] tw-overflow-hidden">
        <img class="tw-w-[102px] tw-h-[102px] tw-object-cover"
             src="{{afterImage}}"
             alt="After"/>
        <img class="tw-absolute tw-right-2 tw-top-2 tw-w-2" src="/assets/icons/check-circle.svg" alt="check"/>
      </div>
    </div>
  </div>
</section>
