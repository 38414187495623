import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import { CsIconModule } from '@components/icon';
import { ButtonModule } from '@components/button';
import { LoadingModule } from '@components/loading';
import { RefundPolicyModule } from '@shared/refund-policy/refund-policy.module';
import { ProductRoutingModule } from './product.routing';
import { ProductViewComponent } from './components/product-view/product-view.component';
import { ArticleViewComponent } from './components/article-view/article-view.component';
import { ProductControllerComponent } from './controllers/product-controller/product-controller.component';
import { ProductCtaContainer } from './components/product-cta-container/product-cta-container.component';

@NgModule({
  imports: [
    CommonModule,
    SwiperModule,
    CsIconModule,
    ButtonModule,
    LoadingModule,
    ProductRoutingModule,
    RefundPolicyModule,
    ProductCtaContainer,
  ],
  declarations: [
    ProductControllerComponent,
    ProductViewComponent,
    ArticleViewComponent,
  ],
})
export class ProductModule { }
