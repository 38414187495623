import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TakePhotoBannerComponent } from './take-photo-banner/take-photo-banner.component';
import { TakePhotoBannerTitleDirective } from './take-photo-banner-title/take-photo-banner-title.directive';
import { TakePhotoBannerCaptionDirective } from './take-photo-banner-caption/take-photo-banner-caption.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    TakePhotoBannerComponent,
    TakePhotoBannerTitleDirective,
    TakePhotoBannerCaptionDirective,
  ],
  exports: [
    TakePhotoBannerComponent,
    TakePhotoBannerTitleDirective,
    TakePhotoBannerCaptionDirective,
  ],
})

export class TakePhotoBannerModule { }
