import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { ConnectionService } from '@services/connection-service';
import { BroadcastService } from '@services/broadcast-service';
import { EventLoggerService } from '@services/event-logger-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AppConfig } from '../../../../app.config';

@Component({
  selector: 'user-view-regimen-preparation',
  templateUrl: './user-view-regimen-preparation.html',
  styleUrls: ['./user-view-regimen-preparation.scss'],
})
export class UserViewRegimenPreparationComponent implements OnDestroy {
  user: any;
  showPreparationTimer: boolean = false;
  @Input('regimen') regimen: any;
  @Input('experiments') experiments: any[];
  @Input('renderReport')
  set reportRendition(showReport: boolean) {
    if (showReport) this.showSkinReport = true;
    else this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'regimen-preparation' }));
  }
  @Output('showRegimen') showRegimen: EventEmitter<any> = new EventEmitter<any>();
  @Output('hideToolBars') hideToolBars: EventEmitter<any> = new EventEmitter<any>();
  timer: any = { minutes: 0, seconds: 0 };
  interval: any = {};
  primaryConcern: string = '';
  otherConcerns: string = '';
  allocatedDoctor: any;
  concerns: Array<any> = [];
  toggleCssClassToolbar: boolean = false;
  defaultTime: number = 120_000;
  elapsedTime: number = 0;
  isPaidUser: boolean = false;
  showSkinReport: boolean = false;
  showRegimenToast: boolean = false;
  remTime: number;
  startTimer: number;
  constructor(public conn: ConnectionService,
    public appConfig: AppConfig,
    private broadcast: BroadcastService,
    private eventLogger: EventLoggerService,
    private changeDetectionRef: ChangeDetectorRef,
    private appWebBridge: AppWebBridgeService,
  ) {
  }

  onToggleCssClassToolbar(event: boolean): void {
    this.toggleCssClassToolbar = event;
  }

  async ngOnInit(): Promise<any> {
    this.startTimer = new Date().getTime();
    this.user = await this.conn.getActingUser();
    const concern = this.user.get('PrivateMainConcernClass');
    if (!this.user?.get('allocatedDoctor')) this.user = await this.conn?.findUserByObjectId(this.user?.id);
    await this.showRegimenTimer();
    await this.fetchAllocatedDoctorForUser();
    this.extractConcernsAndLogPageOpen();
    this.hideToolBars.emit();
    this.updatePaidUserFlag();
    this.changeDetectionRef.detectChanges();
    const time = new Date().getTime() - this.startTimer;
    this.eventLogger.trackEvent('Timer_screen_loading_time', { timeInMilliSec: time, userId: this.user.id });
    this.appWebBridge.logEventInBranchAndFirebaseFromiOS({
      branch: { name: 'pageOpenRegimenTimer' },
      firebase: { name: 'pageOpenRegimenTimer' },
    });
  }

  async fetchAllocatedDoctorForUser(): Promise<void> {
    if (this.user.get('allocatedDoctor')?.id) {
      this.allocatedDoctor = await this.conn.findUserByObjectId(this.user.get('allocatedDoctor').id);
    }
  }

  extractConcernsAndLogPageOpen(): void {
    this.concerns = this.user?.get('aiDetections');
    if (this.concerns?.length) {
      this.primaryConcern = this.concerns[0];
      this.otherConcerns = this.concerns.slice(1).join(', ');
      this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'regimen-preparation-new-ui' }));
    }
  }

  updatePaidUserFlag(): void {
    this.isPaidUser = [
      this.appConfig.Shared.User.OrderState.PROCESSING,
      this.appConfig.Shared.User.OrderState.DELIVERED,
    ].includes(this.user.get('orderState'));
  }

  async showRegimenTimer(): Promise<any> {
    const currTime: any = new Date();
    const regimenShowTime: any = new Date(this.regimen.activeFrom.iso);
    this.remTime = regimenShowTime - currTime;
    this.elapsedTime = -(this.defaultTime - (regimenShowTime - currTime));
    if (this.elapsedTime >= 120_000) this.elapsedTime = -120_000;
    setTimeout((): void => this.startRegimenTimer((regimenShowTime - currTime)));
  }
  // Calculate remaining minutes and seconds from milliseconds
  calculateRemainingTime(milliseconds: number): { minutes: number; seconds: number } {
    const minutes = Math.floor(milliseconds / 1000 / 60);
    const seconds = Math.floor((milliseconds - (minutes * 1000 * 60)) / 1000);
    return { minutes, seconds };
  }

  // Start animation after a delay
  startAnimationAfterDelay(delay: number): void {
    setTimeout((): boolean => this.timer.animate = true, delay);
  }

  // Update the timer and check if it's finished
  updateTimerAndCheckIfFinished(): void {
    if (this.timer.seconds === 0) {
      this.timer.seconds = 59;
      this.timer.minutes -= 1;
    } else {
      this.timer.seconds -= 1;
    }

    if (this.timer.minutes === 0 && this.timer.seconds === 0) {
      clearInterval(this.interval.timer);
      if (this.showSkinReport) {
        // Instead of sheet, show the strip
        this.showRegimenToast = true;
        this.changeDetectionRef.detectChanges();
      } else {
        this.showRegimenPage();
      }
    }
  }

  showRegimenPage(fromToast: boolean = false): void {
    if (fromToast) this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'report-toast-view' }));
    this.showRegimen.emit();
  }

  // Start the timer and update it at regular intervals
  startIntervalTimer(updateInterval: number): void {
    this.interval.timer = setInterval(this.updateTimerAndCheckIfFinished.bind(this), updateInterval);
  }

  // Main function for starting the regimen timer
  startRegimenTimer(milliseconds: number = 0): void {
    if (milliseconds < 0) return;

    const { minutes, seconds }: { minutes: number, seconds: number } = this.calculateRemainingTime(milliseconds);
    this.timer.minutes = (minutes > 2) ? 1 : minutes;
    this.timer.seconds = seconds;

    const isTimeOver = this.timer.minutes === 0 && this.timer.seconds === 0;
    if (!isTimeOver) {
      this.startAnimationAfterDelay(500);
      this.startIntervalTimer(1000);
    }

    this.changeDetectionRef.detectChanges();
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  ngOnDestroy(): void {
    clearInterval(this.interval.timer);
    clearInterval(this.interval.carousel);
  }
}
