export const concernsTranslation: { [key: string]: string }[] = [
  {
    en: 'Dark Circles',
    hi: 'काले घेरे',
    mr: 'काळी वर्तुळे',
    te: 'నల్లటి వలయాలు',
    kn: 'ಡಾರ್ಕ್ ಸರ್ಕಲ್ಸ್',
    ta: 'கரு வளையங்கள்',
    bn: 'ডার্ক সার্কেল',
    ml: 'കറുത്ത വളയങ്ങൾ',
  },
  {
    en: 'Acne Scars',
    hi: 'एक्ने के निशान',
    mr: 'मुरुमांचे चट्टे',
    te: 'మొటిమల మచ్చలు',
    kn: 'ಏಕ್ನೆ ಸ್ಕಾರ್ಸ್',
    ta: 'முகப்பரு வடுக்கள்',
    bn: 'ব্রণর দাগ',
    ml: 'മുഖക്കുരുവിന്റെ പാടുകൾ',
  },
  {
    en: 'Inflammatory Acne',
    hi: 'इंफ्लेमेटरी एक्ने',
    mr: 'इंफ्लेमेटरी एक्ने',
    te: 'ఇన్ఫ్లమేటరీ యాక్నే',
    kn: 'ಇಂಪ್ಲಾಮೇಟರಿ ಏಕ್ನೆ',
    ta: 'இன்ஃப்ளமேட்டரி அக்னே',
    bn: null,
    ml: null,
  },
  {
    en: 'Anti Aging',
    hi: 'झुर्रियाँ',
    mr: 'सुरकुत्या',
    te: 'ముడతలు',
    kn: 'ರಿಂಕಲ್ಸ್',
    ta: 'சுருக்கங்கள்',
    bn: 'বলিরেখা',
    ml: 'പ്ചുളിവുകൾ',
  },
  {
    en: 'Dull skin',
    hi: 'सुस्त बेजान त्वचा',
    mr: 'निस्तेज त्वचा',
    te: 'నిస్తేజమైన చర్మం',
    kn: 'ಡಲ್ ಸ್ಕಿನ್',
    ta: 'பொலிவற்ற சருமம்',
    bn: 'নিস্তেজ প্রাণহীন ত্বক',
    ml: 'മങ്ങിയ ചർമ്മം',
  },
  {
    en: 'Comedones',
    hi: 'कॉमेडोन',
    mr: 'कॉमेडोन',
    te: 'కామెడోన్స్',
    kn: 'ಕಾಮೆಡೋನ್ಸ್',
    ta: 'கொமெடோன்கள்',
    bn: null,
    ml: null,
  },
  {
    en: 'Pigmentation',
    hi: 'पिगमेंटेशन',
    mr: 'पिगमेंटेशन',
    te: 'పిగ్మెంటేషన్',
    kn: 'ಪಿಗ್ಮೆಂಟೇಶನ್',
    ta: 'பிக்மென்டேஷன்',
    bn: null,
    ml: null,
  },
  {
    en: 'Acne',
    hi: 'एक्ने',
    mr: 'एक्ने',
    te: 'యాక్నే',
    kn: 'ಏಕ್ನೆ',
    ta: 'அக்னே',
    bn: null,
    ml: null,
  },
];
