<div class="tw-flex tw-flex-row tw-items-center tw-overflow-visible" #header="" [ngClass]='{ "gradient": customClass === "gradient", "tw-bg-white": customClass === "planInfo" && !isRegimenNewPropositionUI, "tw-bg-gray-800": customClass === "planInfo" && isRegimenNewPropositionUI, "tw-bg-black": customClass !== "planInfo" }'>
  <ng-container *ngIf="customClass === 'planInfo'">
  <ng-container *ngIf="isRegimenNewPropositionUI; then regimenNewPropositionPlanInfoTemplate else originalPlanInfoTemplate"></ng-container>
  <ng-template #regimenNewPropositionPlanInfoTemplate>
    <!-- Regimen new proposition plan info template -->
    <div
      class="tw-flex-1 tw-font-body tw-text-300 tw-text-center tw-pt-[14px] tw-pb-[9px] tw-border-b-[6px] tw-uppercase tw-text-gray-75 tw-font-bold"
      (click)="tabChange(0)"
      [ngClass]="{ 'tw-border-teal-400': (toolbar.selectedTab === 0 && toolbar.count === 3) || (toolbar.selectedTab === 0 && toolbar.count === 4), 'tw-font-normal tw-border-gray-800 tw-opacity-70': toolbar.selectedTab !== 0}">
      <div *ngTemplateOutlet="tab1"></div>
    </div>
    <div
      class="tw-flex-1 tw-font-body tw-text-300 tw-text-center tw-pt-[14px] tw-pb-[9px] tw-border-b-[6px] tw-uppercase tw-text-gray-75 tw-font-bold"
      (click)="tabChange(1)"
      [ngClass]="{'tw-border-orange-400': toolbar.selectedTab === 1 && toolbar.count === 3, 'tw-border-teal-400': toolbar.selectedTab === 1 && toolbar.count === 4, 'tw-font-normal tw-border-gray-800 tw-opacity-70': toolbar.selectedTab !== 1}">
      <div *ngTemplateOutlet="tab2"></div>
    </div>
    <div *ngIf="toolbar.count >= 3"
      class="tw-flex-1 tw-font-body tw-text-300 tw-text-center tw-pt-[14px] tw-pb-[9px] tw-border-b-[6px] tw-uppercase tw-text-gray-75 tw-font-bold"
      (click)="tabChange(2)"
      [ngClass]="{'tw-border-blue-400': toolbar.selectedTab === 2 && toolbar.count === 3, 'tw-border-orange-400': toolbar.selectedTab === 2 && toolbar.count === 4, 'tw-font-normal tw-border-gray-800 tw-opacity-70': toolbar.selectedTab !== 2}">
      <div *ngTemplateOutlet="tab3"></div>
    </div>
    <div *ngIf="toolbar.count === 4"
      class="tw-flex-1 tw-font-body tw-text-300 tw-text-center tw-pt-[14px] tw-pb-[9px] tw-border-b-[6px] tw-uppercase tw-text-gray-75 tw-font-bold tw-pr-1"
      (click)="tabChange(3)"
      [ngClass]="{'tw-border-blue-400': toolbar.selectedTab === 3, 'tw-font-normal tw-border-gray-800 tw-opacity-70': toolbar.selectedTab !== 3}">
      <div *ngTemplateOutlet="tab4"></div>
    </div>
  </ng-template>
  <ng-template #originalPlanInfoTemplate>
  <!-- Original plan info template -->
  <div class="tw-flex-1 tw-font-body tw-text-100 tw-text-center tw-border-b- tw-pt-[14px] tw-pb-[9px] tw-border-b-[6px]"
    (click)="tabChange(0)"
    [ngClass]=" { 'tw-text-black tw-border-teal-200': (toolbar.selectedTab === 0 && toolbar.count === 3) || (toolbar.selectedTab === 0 && toolbar.count === 4), 'tw-text-gray-500 tw-border-white': toolbar.selectedTab !== 0 } ">
    <div *ngTemplateOutlet="tab1"></div>
  </div>
  <div class="tw-flex-1 tw-font-body tw-text-100 tw-text-center tw-pt-[14px] tw-pb-[9px] tw-border-b-[6px]"
    (click)="tabChange(1)"
    [ngClass]=" { 'tw-text-black tw-border-orange-200': toolbar.selectedTab === 1 && toolbar.count === 3, 'tw-text-black tw-border-teal-200': (toolbar.selectedTab === 1 && toolbar.count === 4), 'tw-text-gray-500 tw-border-white': toolbar.selectedTab !== 1 } ">
    <div *ngTemplateOutlet="tab2"></div>
  </div>
  <div class="tw-flex-1 tw-font-body tw-text-100 tw-text-center tw-pt-[14px] tw-pb-[9px] tw-border-b-[6px]" *ngIf="toolbar.count >= 3" (click)="tabChange(2)" [ngClass]=" { 'tw-text-black tw-border-blue-200': toolbar.selectedTab === 2 && toolbar.count === 3, 'tw-text-black tw-border-orange-200': toolbar.selectedTab === 2 && toolbar.count === 4, 'tw-text-gray-500 tw-border-white': toolbar.selectedTab !== 2 } ">
    <div *ngTemplateOutlet="tab3"></div>
  </div>
  <div class="tw-flex-1 tw-font-body tw-text-100 tw-text-center tw-pt-[14px] tw-pb-[9px] tw-border-b-[6px] tw-pr-1" *ngIf="toolbar.count === 4" (click)="tabChange(3)" [ngClass]=" { 'tw-text-black tw-border-blue-200': toolbar.selectedTab === 3, 'tw-text-gray-500 tw-border-white': toolbar.selectedTab !== 3 } ">
    <div *ngTemplateOutlet="tab4"></div>
  </div>
  </ng-template>
  </ng-container>
  <ng-container *ngIf="customClass !== 'planInfo'">
  <div class="tw-flex-1 tw-font-body tw-text-300 tw-text-center tw-border-b- tw-pt-[14px] tw-pb-[9px] tw-border-b-[6px]" (click)="tabChange(0)" [ngClass]=" { 'tw-text-white tw-border-teal-400': toolbar.selectedTab === 0, 'tw-text-gray-500 tw-border-black': toolbar.selectedTab !== 0 } ">
    <div *ngTemplateOutlet="tab1"></div>
  </div>
  <div class="tw-flex-1 tw-font-body tw-text-300 tw-text-center tw-pt-[14px] tw-pb-[9px] tw-border-b-[6px]" (click)="tabChange(1)" [ngClass]=" { 'tw-text-white tw-border-purple-400': toolbar.selectedTab === 1, 'tw-text-gray-500 tw-border-black': toolbar.selectedTab !== 1 } ">
    <div *ngTemplateOutlet="tab2"></div>
  </div>
  <div class="tw-flex-1 tw-font-body tw-text-300 tw-text-center tw-pt-[14px] tw-pb-[9px] tw-border-b-[6px]" *ngIf="toolbar.count >= 3" (click)="tabChange(2)" [ngClass]=" { 'tw-text-white tw-border-purple-400': toolbar.selectedTab === 2, 'tw-text-gray-500 tw-border-black': toolbar.selectedTab !== 2 } ">
    <div *ngTemplateOutlet="tab3"></div>
  </div>
  </ng-container>
</div>
<ng-template #tab1=""><ng-content select="tab1"></ng-content></ng-template><ng-template #tab2=""><ng-content select="tab2"></ng-content></ng-template><ng-template #tab3=""><ng-content select="tab3"></ng-content></ng-template><ng-template #tab4=""><ng-content select="tab4"></ng-content></ng-template>
