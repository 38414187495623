<section class="card takePhoto getSkinAnalysis pos-rel flex-col m16 bdr-rad-20 pB20 tw-overflow-hidden tw-rounded-tr-4 tw-rounded-tl-4" id="takePhoto" *ngIf="userStatus.PAID" (click)="handleClick()">
  <video-player class="tw-block tw-overflow-hidden tw-mb-4" [src]="'https://cdn.cureskin.com/app/img/refer.mp4'" autoplay="true" [poster]="
    'https://cdn.cureskin.com/app/img/refer-thumbnail.jpg'
  " [hideAudioControl]="true"></video-player>
  <div class="nutritionist pos-abs pos-top-left mL15 mT15" i18n="@@referAndEarn">Refer & Earn</div>
  <div class="fw-700 font20 mL16" i18n="@@inviteYourFriend">
    Invite your friends
  </div>
  <div class="tw-flex tw-items-end">
    <div class="tw-flex-1 tw-flex tw-flex-col tw-self-center">
      <div class="mL16" i18n="@@earnCScash">
        Earn Cureskin cash<span class="fw-600"> worth ₹400</span>
      </div>
    </div>
    <div class="tw-mr-4">
      <cs-button [class]="'primary btn-xxxs tw-px-4'"><cs-button-text i18n="@@invite">Invite</cs-button-text><cs-icon class="tw-h-4 tw-w-4" [iconName]="'chevron_right'"></cs-icon></cs-button>
    </div>
  </div>
</section>
