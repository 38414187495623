import { version } from '../../package.json';
import { environment } from '../environments/environment';

interface DietPlans {
  PRO: string;
  BASIC: string;
}

interface Notice {
  dietPlans: DietPlans;
}

class Shared {
  AppVersion: string = version;
  Gender: any = { MALE: 'Male', FEMALE: 'Female', NONE: 'None' };
  Server: { env: string, production: boolean } = Object.assign(environment, { env: environment.production ? 'production' : 'development' });
  Languages: any = { EN: 'en', HI: 'hi', KN: 'kn', TE: 'te', TA: 'ta', MR: 'mr', BN: 'bn', ML: 'ml' };
  States: Array<{ name: string, value: string }> = [
    { name: 'Andaman And Nicobar', value: 'Andaman And Nicobar' },
    { name: 'Andhra Pradesh', value: 'Andhra Pradesh' },
    { name: 'Arunachal Pradesh', value: 'Arunachal Pradesh' },
    { name: 'Assam', value: 'Assam' },
    { name: 'Bihar', value: 'Bihar' },
    { name: 'Chhattisgarh', value: 'Chhattisgarh' },
    { name: 'Chandigarh', value: 'Chandigarh' },
    { name: 'Dadra And Nagar Haveli', value: 'Dadra And Nagar Haveli' },
    { name: 'Daman And Diu', value: 'Daman And Diu' },
    { name: 'Delhi', value: 'Delhi' },
    { name: 'Goa', value: 'Goa' },
    { name: 'Gujarat', value: 'Gujarat' },
    { name: 'Haryana', value: 'Haryana' },
    { name: 'Himachal Pradesh', value: 'Himachal Pradesh' },
    { name: 'Jammu And Kashmir', value: 'Jammu And Kashmir' },
    { name: 'Jharkhand', value: 'Jharkhand' },
    { name: 'Karnataka', value: 'Karnataka' },
    { name: 'Kerala', value: 'Kerala' },
    { name: 'Lakshadweep', value: 'Lakshadweep' },
    { name: 'Madhya Pradesh', value: 'Madhya Pradesh' },
    { name: 'Maharashtra', value: 'Maharashtra' },
    { name: 'Manipur', value: 'Manipur' },
    { name: 'Meghalaya', value: 'Meghalaya' },
    { name: 'Mizoram', value: 'Mizoram' },
    { name: 'Nagaland', value: 'Nagaland' },
    { name: 'Orissa', value: 'Orissa' },
    { name: 'Punjab', value: 'Punjab' },
    { name: 'Pondicherry', value: 'Pondicherry' },
    { name: 'Rajasthan', value: 'Rajasthan' },
    { name: 'Sikkim', value: 'Sikkim' },
    { name: 'Tamil Nadu', value: 'Tamil Nadu' },
    { name: 'Telangana', value: 'Telangana' },
    { name: 'Tripura', value: 'Tripura' },
    { name: 'Uttar Pradesh', value: 'Uttar Pradesh' },
    { name: 'Uttarakhand', value: 'Uttarakhand' },
    { name: 'West Bengal', value: 'West Bengal' },
  ];
  Months: any = [{ name: 'Jan', value: 'Jan' },
    { name: 'Feb', value: 'Feb' },
    { name: 'Mar', value: 'Mar' },
    { name: 'Apr', value: 'Apr' },
    { name: 'May', value: 'May' },
    { name: 'Jun', value: 'Jun' },
    { name: 'Jul', value: 'Jul' },
    { name: 'Aug', value: 'Aug' },
    { name: 'Sep', value: 'Sep' },
    { name: 'Oct', value: 'Oct' },
    { name: 'Nov', value: 'Nov' },
    { name: 'Dec', value: 'Dec' },
  ];
  InstantCheckup: any = {
    Type: {
      OTHER: 'OTHER',
      INVALID: 'INVALID',
      FACE: 'FACE',
      FULL_FACE: 'FULL_FACE',
      FRONT_FACE: 'FRONT_FACE',
      SIDE_FACE: 'SIDE_FACE',
      LEFT_SIDE_FACE: 'LEFT_SIDE_FACE',
      RIGHT_SIDE_FACE: 'RIGHT_SIDE_FACE',
      LEFT_FACE: 'LEFT_FACE',
      RIGHT_FACE: 'RIGHT_FACE',
      HAIR_FRONT: 'HAIR_FRONT',
      HAIR_TOP: 'HAIR_TOP',
      HAIR: 'HAIR',
      BODY: 'BODY',
      REPORT: 'REPORT',
    },
    LumaStandard: {
      Thresholds: {
        DARK: 35,
        DIM: 60,
        WELL_LIT: 180,
        TOO_BRIGHT: 255,
      },
    },
    DetectionStatus: {
      DETECTED: 'Detected',
      NOT_DETECTED: 'Not Detected',
    },
    Concerns: {
      MELASMA: 'Melasma',
    },
    Severity: {
      MILD: 'MILD',
    },
  };

  ArticleData: any = {
    clickToAction: {
      BUY_REGIMEN: 'BUY_REGIMEN',
      BUY_PRODUCT: 'BUY_PRODUCT',
      SHARE: 'SHARE',
      REFER: 'REFER',
      NONE: 'NONE',
    },
  };
  experimentVariants: any = {
    REFERRAL_CASH_VALUE: 400,
    SHOP_PAGE_CASH_VISIBILITY: {
      minCash: 50,
    },
    MaxExploreCashLimit: {
      MaxCash: 10,
    },
  };

  PendingCall: any = {
    Type: {
      UNPAID_USER_DOCTOR_CALL: 'UnpaidUserDoctorCall',
      RE_ACQUISITION_CALL: 'ReacquisitionCall',
      CHECK_IN: 'checkIn',
    },
    Status: {
      CallCompleted: 'CallCompleted',
      Requested: 'Requested',
    },
  };

  Inventory: any = {
    Type: {
      AVAILABLE: 'AVAILABLE',
      UNAVAILABLE: 'UNAVAILABLE',
      DISCONTINUED: 'DISCONTINUED',
    },
  };

  Notification: any = {
    Type: { Text: 'Text' },
    Category: {
      Regimen: 'regimen',
      Order: 'order',
      Ticket: 'ticket',
      FollowUp: 'followUp',
      Referral: 'referral',
      General: 'general',
      Doctor: 'doctor',
      Cash: 'cash',
    },
  };

  MarketingTags: any = {
    Type: {
      ON_REGIMEN: 'ON_REGIMEN',
    },
  };

  Order: any = {
    Type: {
      TRIAL_REGIMEN: 'TRIAL_REGIMEN',
      REGIMEN: 'REGIMEN',
      PRODUCT: 'PRODUCT',
      REPLACEMENT_PRODUCT: 'REPLACEMENT_PRODUCT',
      CONSULTATION: 'CONSULTATION',
      DIET_CONSULTATION: 'DIET_CONSULTATION',
    },
    PaymentType: {
      NOT_SELECTED: 'NOT_SELECTED',
      COD: 'COD',
      ONLINE: 'ONLINE',
      NA: 'NA',
    },
    Stage: {
      INITIAL: 'INITIAL',
      ONLINE_PAYMENT_PENDING: 'ONLINE_PAYMENT_PENDING',
      PAYMENT_ERROR: 'PAYMENT_ERROR',
      ONLINE_PAYMENT_SUCCESS: 'ONLINE_PAYMENT_SUCCESS',
      ORDER_CONFIRMED: 'ORDER_CONFIRMED',
      ORDER_PLACED: 'ORDER_PLACED',
      ADDRESS_CONFIRMATION: 'ADDRESS_CONFIRMATION',
      ADDRESS_VERIFIED: 'ADDRESS_VERIFIED',
      OUT_OF_STOCK: 'OUT_OF_STOCK',
      USER_CONFIRMATION_PENDING: 'USER_CONFIRMATION_PENDING',
      DOCTOR_APPROVAL_PENDING: 'DOCTOR_APPROVAL_PENDING',
      WAITING_FOR_IMAGE: 'WAITING_FOR_IMAGE',
      IMAGE_VERIFICATION_PENDING: 'IMAGE_VERIFICATION_PENDING',
      IMAGE_VERIFIED: 'IMAGE_VERIFIED',
      CONFIRMATION_PENDING: 'CONFIRMATION_PENDING',
      DR_VERIFIED: 'DR_VERIFIED',
      SHIP_LATER: 'SHIP_LATER',
      SHIP_NOW: 'SHIP_NOW',
      AWB_GENERATED: 'AWB_GENERATED',
      PACKED: 'PACKED',
      PICKUP_READY: 'PICKUP_READY',
      QC_FAIL: 'QC_FAIL',
      QC_PASS: 'QC_PASS',
      INVOICE_GENERATED: 'INVOICE_GENERATED',
      SHIPPED: 'SHIPPED',
      SOURCE_HUB_REACHED: 'SOURCE_HUB_REACHED',
      CONNECTED_TO_DESTINATION: 'CONNECTED_TO_DESTINATION',
      STUCK_IN_TRANSIT: 'STUCK_IN_TRANSIT',
      DELIVERY_FAIL: 'DELIVERY_FAIL',
      CUSTOMER_PICKUP: 'CUSTOMER_PICKUP',
      RETRY_DELIVERY: 'RETRY_DELIVERY',
      OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
      DELIVERED: 'DELIVERED',
      CANCEL_REQUESTED: 'CANCEL_REQUESTED',
      RETURN_INITIATED: 'RETURN_INITIATED',
      RETURNED: 'RETURNED',
      CANCELED: 'CANCELED',
      LOST_IN_TRANSIT: 'LOST_IN_TRANSIT',
      NONSERVICEABLEAREA: 'NONSERVICEABLEAREA',
      COURIER_NOT_AVAILABLE: 'COURIER_NOT_AVAILABLE',
      RETURN_REQUESTED: 'RETURN_REQUESTED',
      REFUND_REQUESTED: 'REFUND_REQUESTED',
      REFUND_PROCESSED: 'REFUND_PROCESSED',
      REFUND_INITIATED: 'REFUND_INITIATED',
      BOT_CANCELED: 'BOT_CANCELED',
      CONSULTATION_CREATED: 'CONSULTATION_CREATED',
      COMPLETED: 'COMPLETED',

      CONSULTATION_COMPLETED: 'CONSULTATION_COMPLETED',
      AfterShipment: [],
      BeforeShipment: [],
      CompletedStages: [],
      OrderPlaced: [],
      OrderPackedStage: [],

      orderPaymentPendingStages: [],
      orderPlacedStages: [],
      orderPackedStages: [],
      orderDispatchedStages: [],
      orderDeliveryFailedStages: [],
      orderOutForDeliveryStages: [],
      orderDeliveredStages: [],
      orderCanceledStages: [],
    },
  };
  Service: any = {
    Type: {
      CONSULTATION: 'CONSULTATION',
      REGIMEN_CONSULTATION: 'REGIMEN_CONSULTATION',
      RENEW_CONSULTATION: 'RENEW_CONSULTATION',
      DELIVERY: 'DELIVERY',
      COD: 'COD',
    },
  };

  Action: any = {
    CONFIRM: 'CONFIRM',
    CLEAR: 'CLEAR',
  };
  Payment: any = {
    PaymentPlan: {
      PRODUCT: 'PRODUCT',
      TRIAL_REGIMEN: 'TRIAL_REGIMEN',
      CONSULTATION: 'CONSULTATION',
      REGIMEN: 'REGIMEN',
    },
    PaymentSource: {
      COD: 'COD',
      RAZORPAY: 'RAZORPAY',
      PAYTM: 'PAYTM',
    },
    PreCondition: {
      COD_CONFIRMATION_FEE: 'CodConfirmationFee',
    },
    paymentModes: {
      PAYTM: 'PAYTM',
      CARD: 'CARD',
      WALLET: 'WALLET',
      COD: 'COD',
      CCOD: 'CCOD',
      NET_BANKING: 'NET_BANKING',
      UPI: 'UPI',
      PAY_PAL: 'PAY_PAL',
      OTHER: 'OTHER',
    },
    Charges: {
      COD_HANDLING_FEE: 'COD Handling fees',
      COD_PROCESSING_CHARGES: 'COD Processing Charges',
      CCOD_PROCESSING_AMOUNT: 20,
    },
  };
  Event: {
    Type: { Order: string };
  } = { Type: { Order: 'Order' } };
  Analytics: any = {
    CleverTap: 'cleverTap',
    Mixpanel: 'mixapnel',
    Facebook: 'facebook',
    Branch: 'branch',
  };
  Feedback: any = {
    Stage: {
      Created: 'CREATED',
      Partial: 'PARTIAL',
      Finished: 'FINISHED',
    },
    Type: {
      ARE_YOU_OK_CHAT: 'ARE_YOU_OK_CHAT',
      NPS: 'NPS',
    },
  };
  Assistant: any = {
    ResponseModes: [],
    MediaTypeModes: [],
    RequestImageModes: [],
    NonPlainTextModes: [],
    Mode: {
      ATTACHMENT: 'ATTACHMENT',
      ARTICLE: 'ARTICLE',
      AUDIO: 'AUDIO',
      VIDEO: 'VIDEO',
      GENERIC_USER_INPUT: 'GENERIC_USER_INPUT',
      PAYMENT: 'PAYMENT',
      REQUEST_IMAGE: 'REQUEST_IMAGE',
      SINGLE_SELECTOR: 'SINGLE_SELECTOR',
      SMS: 'SMS',
      STICKY_ACTION: 'STICKY_ACTION',
      REQUEST_IMAGE_FACE: 'REQUEST_IMAGE_FACE',
      REQUEST_IMAGE_SIDE_FACE: 'REQUEST_IMAGE_SIDE_FACE',
      REQUEST_IMAGE_LEFT_SIDE_FACE: 'REQUEST_IMAGE_LEFT_SIDE_FACE',
      REQUEST_IMAGE_RIGHT_SIDE_FACE: 'REQUEST_IMAGE_RIGHT_SIDE_FACE',
      REQUEST_IMAGE_FRONT_FACE: 'REQUEST_IMAGE_FRONT_FACE',
      REQUEST_IMAGE_HAIR: 'REQUEST_IMAGE_HAIR',
      REQUEST_IMAGE_BODY: 'REQUEST_IMAGE_BODY',
      REQUEST_IMAGE_REPORT: 'REQUEST_IMAGE_REPORT',
      REQUEST_REPORT: 'REQUEST_REPORT',
      TEXT_NOREPLY: 'TEXT_NOREPLY',
      WITH_TEXT_REPLY: 'WITH_TEXT_REPLY',
      ACTION_RESPONSE: 'ACTION_RESPONSE',
      ARTICLE_RESPONSE: 'ARTICLE_RESPONSE',
      GENERIC_USER_INPUT_RESPONSE: 'GENERIC_USER_INPUT_RESPONSE',
      PAYMENT_RESPONSE: 'PAYMENT_RESPONSE',
      REQUEST_IMAGE_RESPONSE: 'REQUEST_IMAGE_RESPONSE',
      SINGLE_SELECTOR_RESPONSE: 'SINGLE_SELECTOR_RESPONSE',
      WITH_TEXT_REPLY_RESPONSE: 'WITH_TEXT_REPLY_RESPONSE',
      TEXT_NOREPLY_RESPONSE: 'TEXT_NOREPLY_RESPONSE',
      BLOCK: 'BLOCK',
      UNBLOCK: 'UNBLOCK',
      IMAGE_CLUBBED: 'IMAGE_CLUBBED',
      TEXT_NO_REPLY: 'TEXT_NOREPLY',
      SMS_COPY: 'SMS_COPY',
    },
    Owner: {
      USER: 'USER',
      BOT: 'BOT',
      OPERATOR: 'OPERATOR',
      DOCTOR: 'DOCTOR',
    },
    QuestionType: {
      ANCHOR: 'Anchor',
      INFO: 'Info',
    },
    Attachment: {
      Type: {
        PDF: 'PDF',
        IMAGE: 'IMAGE',
      },
      Thumbnail: {
        PDF: 'https://cdn.cureskin.com/article_image/1629989795_14e81ac9d16542c6b38d91a03879fe29.png',
      },
    },
  };
  Regimen: any = {
    Class: {
      BODY: 'BODY',
      FACE: 'FACE',
      HAIR: 'HAIR',
    },
  };
  ShopTags: any = {
    TYPE: {
      BODY: 'BODY',
      DANDRUFF: 'DANDRUFF',
      SMOKER: 'SMOKER',
      HAIR_FALL: 'HAIR_FALL',
    },
  };
  ShopSections: {
    MARKETING_TAGS: { MT_DANDRUFF: string; MT_HAIR_FALL: string; MT_SMOKER: string; MT_BODY: string };
    TYPE: { Recommended: string; Doctor: string; ReOrder: string }
  } = {
      TYPE: {
        Recommended: 'Recommended',
        Doctor: 'Doctor',
        ReOrder: 'ReOrder',
      },
      MARKETING_TAGS: {
        MT_BODY: 'MT_BODY',
        MT_DANDRUFF: 'MT_DANDRUFF',
        MT_SMOKER: 'MT_SMOKER',
        MT_HAIR_FALL: 'MT_HAIR_FALL',
      },
    };
  Coupon: any = {
    TYPE: {
      NORMAL: 'NORMAL',
      USER_REFERRAL: 'USER_REFERRAL',
      CURE_SKIN_CASH: 'CURE_SKIN_CASH',
    },
  };
  Reward: any = {
    TYPE: {
      REGIMEN_REFERRAL_REWARD: 'REGIMEN_REFERRAL_REWARD',
      USER_REFERRAL_CASH: 'USER_REFERRAL_CASH',
    },
  };
  User: any = {
    OrderState: {
      PROCESSING: 'PROCESSING',
      DELIVERED: 'DELIVERED',
      UNPAID: 'UNPAID',
      CONSULTATION: 'CONSULTATION',
      NEW_USER: 'NEW_USER',
    },
    Type: {
      DOCTOR: 'doctor',
      OPERATOR: 'OPERATOR',
    },
    Age: {
      MIN_AGE: 13,
    },
    MinimumAmountSpentFromReAcq: 1999,
  };
  Faq: any = {
    Category: {
      DOCTOR: 'DOCTOR',
      REGIMEN: 'REGIMEN',
      ORDER: 'ORDER',
      PRODUCT: 'PRODUCT',
    },
    paidUserFaqIds: [
      '2jOHeE1FGg',
      '5yHJ31GG4x',
      'PD1gFUfQUw',
      'syFEDFtesW',
      'mEYqVnFsP8',
      'Yuj0pAGScm',
      'Un86E3Hu7Z',
    ],
    unpaidUserFaqIds: [
      'MUfQDuPfvI',
      'u7SgiPTWZo',
      'mz40L0ijTC',
      'PD1gFUfQUw',
      'hCVzbasqDl',
      'K8bIbkPEfS',
      'h8E3LHuEF8',
    ],
  };
  Followup: any = {
    State: {
      SKIP: 'SKIP',
      PENDING: 'PENDING',
      FINISHED: 'FINISHED',
      WAITING_FOR_IMAGE: 'WAITING_FOR_IMAGE',
    },
  };
  String: any = {
    DELETE_PHOTO: 'DELETE_PHOTO',
    DELETE: 'DELETE',
    KEEP_PHOTO: 'KEEP_PHOTO',
    CANCEL_ORDER: 'CANCEL_ORDER',
    GOT_QUESTIONS: 'GOT_QUESTIONS',
    SEE_REVIEWS: 'SEE_REVIEWS',
    CANCEL_ORDER_OFFER_CONFIRM: 'CANCEL_ORDER_OFFER_CONFIRM',
    OFFLINE: 'OFFLINE',
    UPDATE_APP_DETAIL: 'UPDATE_APP_DETAIL',
    UPDATE_BROWSER_DETAIL: 'UPDATE_BROWSER_DETAIL',
    INSTANT_CHECKUP_RATING: 'INSTANT_CHECKUP_RATING',
    SEND_PRODUCTS: 'SEND_PRODUCTS',
    WILL_ORDER_LATER: 'WILL_ORDER_LATER',
    REGIMEN_NOT_HAPPY: 'REGIMEN_NOT_HAPPY',
    ORDER_MISTAKE: 'ORDER_MISTAKE',
    COSTLY: 'COSTLY',
    SOMETHING_ELSE: 'SOMETHING_ELSE',
    ORDER_CANCEL_REASON: 'ORDER_CANCEL_REASON',
    OK: 'OK',
    CANCEL: 'CANCEL',
    SUBMIT: 'SUBMIT',
    LOGOUT_CONFIRM: 'LOGOUT_CONFIRM',
    LOGOUT: 'LOGOUT',
    YES: 'YES',
    NO: 'NO',
    UPDATE_APP: 'UPDATE_APP',
    UPDATE_NOW: 'UPDATE_NOW',
    UPDATE_CHROME: 'UPDATE_CHROME',
    UPDATE_WEB_VIEW: 'UPDATE_WEB_VIEW',
    FEEDBACK: 'FEEDBACK',
    ORDER_SELECTION: 'ORDER_SELECTION',
    PRODUCT_SELECTION: 'PRODUCT_SELECTION',
    BAD: 'BAD',
    GOOD: 'GOOD',
    LATER: 'LATER',
    RETRY: 'RETRY',
    RATE_APP: 'RATE_APP',
    RATE_NOW: 'RATE_NOW',
    RATE_US: 'RATE_US',
    PINCODE: 'PINCODE',
    DARKSPOTS: 'DARKSPOTS',
    ACNE_OR_PIMPLE: 'ACNE_OR_PIMPLE',
    DARK_CIRCLES: 'DARK_CIRCLES',
    DULL_SKIN: 'DULL_SKIN',
    MELASMA: 'MELASMA',
    STRETCH_MARKS: 'STRETCH_MARKS',
    RASH: 'RASH',
    FUNGAL_INFECTION: 'FUNGAL_INFECTION',
    TINEA: 'TINEA',
    DRY_SKIN: 'DRY_SKIN',
    HAIRFALL: 'HAIRFALL',
    DANDRUFF: 'DANDRUFF',
    ITCHY_SCALP: 'ITCHY_SCALP',
    DRY_HAIR: 'DRY_HAIR',
    SCALP_PSORIASIS: 'SCALP_PSORIASIS',
    INVALID_PINCODE: 'INVALID_PINCODE',
  };
  UserConfirmation: any = {
    TYPE: {
      WELCOME_BACK: 'WELCOME_BACK',
      DOCTOR_MESSAGE: 'DOCTOR_MESSAGE',
      FEEDBACK: 'FEEDBACK',
    },
  };
  Product: any = {
    ReOrderType: {
      LIMITED: 'LIMITED',
      NEVER: 'NEVER',
    },
  };
  FollowUpReport: any = {
    Stages: {
      // Don't change the order of stages
      WORSENED: '5AMdFBQkv6',
      NO_CHANGE: 'SG9UDCsAwz',
      SATISFACTORY: 'LwF37jN4R4',
      GOOD: '4EegMWvUNR',
      EXCELLENT: '8j9H48gN6l',
    },
  };

  IntroductionVideos: any = {
    en: 'https://cdn.cureskin.com/introductionVideos/intro_en.mp4?autoplay=1&byline=0&color=ffffff&loop=1&portrait=false&title=0',
    hi: 'https://cdn.cureskin.com/introductionVideos/introduction-video-hi.mp4'
      + '?autoplay=1&byline=0&color=ffffff&loop=1&portrait=false&title=0',
    kn: 'https://cdn.cureskin.com/introductionVideos/intro_en.mp4?autoplay=1&byline=0&color=ffffff&loop=1&portrait=false&title=0',
    te: 'https://cdn.cureskin.com/introductionVideos/intro_en.mp4?autoplay=1&byline=0&color=ffffff&loop=1&portrait=false&title=0',
    ta: 'https://cdn.cureskin.com/introductionVideos/intro_en.mp4?autoplay=1&byline=0&color=ffffff&loop=1&portrait=false&title=0',
    mr: 'https://cdn.cureskin.com/introductionVideos/intro_en.mp4?autoplay=1&byline=0&color=ffffff&loop=1&portrait=false&title=0',
    bn: 'https://cdn.cureskin.com/introductionVideos/intro_en.mp4?autoplay=1&byline=0&color=ffffff&loop=1&portrait=false&title=0',
  };

  Feed: any = {
    IMAGE: 'IMAGE',
    VIDEO: 'VIDEO',
  };

  FrequencyOfUsage: any = {
    Days: {
      M: 'Mon',
      T: 'Tue',
      W: 'Wed',
      Th: 'Thr',
      F: 'Fri',
      Sa: 'Sat',
      Su: 'Sun',
    },
  };

  AddressBook: any = {
    Tags: ['home', 'work', 'office', 'other'],
  };

  Support: any = {
    outputType: {
      URL: 'URL',
      COMPONENT: 'COMPONENT',
    },
    component: {
      HTML: 'HTML',
      REGIMEN_PRODUCT_LIST: 'REGIMEN_PRODUCT_LIST',
      REGIMEN_INSTRUCTION: 'REGIMEN_INSTRUCTION',
      ORDER_UPDATE: 'ORDER_UPDATE',
      PLACE_NEW_ORDER: 'PLACE_NEW_ORDER',
      GET_QUERY: 'GET_QUERY',
      ABOUT_MY_DOCTOR: 'ABOUT_MY_DOCTOR',
      SELECT_SUB_QUERY: 'SELECT_SUB_QUERY',
      TREE: 'TREE',
      REGIMEN_CLASS_SELECTION: 'REGIMEN_CLASS_SELECTION',
      VIEW_EDIT_ORDER: 'VIEW_EDIT_ORDER',
    },
  };

  Ticket: any = {
    status: {
      Pending: 'Pending',
      RequestClosure: 'RequestClosure',
      Completed: 'Completed',
    },
  };

  REFERRAL_CASH_VALUE: number = 400;
  CART_PAGE_CASH_VISIBILITY_MIN_CASH: number = 50;
  PAYMENT_PAGE_CASH_VISIBILITY_MIN_CASH: number = 50;

  Notice: Notice = {
    dietPlans: {
      PRO: 'PRO',
      BASIC: 'BASIC',
    },
  };

  SkinReport: any = {
    Concern: {
      INFLAMMATORY_ACNE: 'Inflammatory Acne',
      MELASMA: 'Melasma',
    },
    Severity: {
      MILD: 'Mild',
      MODERATE: 'Moderate',
      SEVERE: 'Severe',
    },
  };

  initialize(): void {
    this.Order.Stage.orderPaymentPendingStages.push(...[
      this.Order.Stage.INITIAL,
      this.Order.Stage.ONLINE_PAYMENT_PENDING,
    ]);
    this.Order.Stage.orderPlacedStages.push(...[
      this.Order.Stage.PAYMENT_ERROR,
      this.Order.Stage.ONLINE_PAYMENT_SUCCESS,
      this.Order.Stage.ORDER_CONFIRMED,
      this.Order.Stage.ORDER_PLACED,
      this.Order.Stage.ADDRESS_CONFIRMATION,
      this.Order.Stage.ADDRESS_VERIFIED,
      this.Order.Stage.OUT_OF_STOCK,
      this.Order.Stage.USER_CONFIRMATION_PENDING,
      this.Order.Stage.DOCTOR_APPROVAL_PENDING,
      this.Order.Stage.WAITING_FOR_IMAGE,
      this.Order.Stage.IMAGE_VERIFICATION_PENDING,
      this.Order.Stage.IMAGE_VERIFIED,
      this.Order.Stage.CONFIRMATION_PENDING,
      this.Order.Stage.DR_VERIFIED,
      this.Order.Stage.SHIP_LATER,
    ]);
    this.Order.Stage.orderPackedStages.push(...[
      this.Order.Stage.SHIP_NOW,
      this.Order.Stage.AWB_GENERATED,
      this.Order.Stage.PACKED,
      this.Order.Stage.PICKUP_READY,
      this.Order.Stage.QC_FAIL,
      this.Order.Stage.QC_PASS,
      this.Order.Stage.INVOICE_GENERATED,
    ]);
    this.Order.Stage.orderDispatchedStages.push(...[
      this.Order.Stage.SHIPPED,
      this.Order.Stage.SOURCE_HUB_REACHED,
      this.Order.Stage.CONNECTED_TO_DESTINATION,
      this.Order.Stage.STUCK_IN_TRANSIT,
    ]);
    this.Order.Stage.orderDeliveryFailedStages.push(...[
      this.Order.Stage.DELIVERY_FAIL,
      this.Order.Stage.CUSTOMER_PICKUP,
    ]);
    this.Order.Stage.orderOutForDeliveryStages.push(...[
      this.Order.Stage.RETRY_DELIVERY,
      this.Order.Stage.OUT_FOR_DELIVERY,
    ]);
    this.Order.Stage.orderDeliveredStages.push(...[
      this.Order.Stage.DELIVERED,
    ]);
    this.Order.Stage.orderCanceledStages.push(...[
      this.Order.Stage.CANCEL_REQUESTED,
      this.Order.Stage.RETURN_INITIATED,
      this.Order.Stage.RETURNED,
      this.Order.Stage.CANCELED,
      this.Order.Stage.LOST_IN_TRANSIT,
      this.Order.Stage.NONSERVICEABLEAREA,
      this.Order.Stage.COURIER_NOT_AVAILABLE,
      this.Order.Stage.RETURN_REQUESTED,
      this.Order.Stage.REFUND_REQUESTED,
      this.Order.Stage.REFUND_PROCESSED,
      this.Order.Stage.REFUND_INITIATED,
      this.Order.Stage.BOT_CANCELED,
    ]);

    this.Order.Stage.BeforeShipment.push(...[
      this.Order.Stage.INITIAL,
      this.Order.Stage.ONLINE_PAYMENT_PENDING,
      this.Order.Stage.ORDER_PLACED,
      this.Order.Stage.OUT_OF_STOCK,
      this.Order.Stage.SHIP_LATER,
      this.Order.Stage.IMAGE_VERIFICATION_PENDING,
      this.Order.Stage.IMAGE_VERIFIED,
      this.Order.Stage.USER_CONFIRMATION_PENDING,
      this.Order.Stage.CONFIRMATION_PENDING,
      this.Order.Stage.WAITING_FOR_IMAGE,
      this.Order.Stage.DOCTOR_APPROVAL_PENDING,
      this.Order.Stage.DR_VERIFIED,
      this.Order.Stage.PICKUP_READY,
      this.Order.Stage.SHIP_NOW]);

    this.Order.Stage.OrderPlaced.push(...[
      this.Order.Stage.ORDER_PLACED,
      this.Order.Stage.SHIP_LATER,
      this.Order.Stage.IMAGE_VERIFICATION_PENDING,
      this.Order.Stage.ADDRESS_CONFIRMATION,
      this.Order.Stage.ADDRESS_VERIFIED,
      this.Order.Stage.IMAGE_VERIFIED,
      this.Order.Stage.USER_CONFIRMATION_PENDING,
      this.Order.Stage.CONFIRMATION_PENDING,
      this.Order.Stage.WAITING_FOR_IMAGE,
      this.Order.Stage.DOCTOR_APPROVAL_PENDING,
      this.Order.Stage.DR_VERIFIED]);

    this.Order.Stage.OrderPackedStage.push(...[
      this.Order.Stage.PICKUP_READY,
      this.Order.Stage.SHIP_NOW,
      this.Order.Stage.PACKED]);

    this.Order.Stage.CompletedStages.push(...[
      this.Order.Stage.DELIVERED,
      this.Order.Stage.CANCELED,
      this.Order.Stage.COMPLETED,
      this.Order.Stage.BOT_CANCELED,
      this.Order.Stage.REFUND_INITIATED,
      this.Order.Stage.REFUND_PROCESSED,
      this.Order.Stage.REFUND_REQUESTED,
    ]);

    this.Order.Stage.AfterShipment = Object.values(this.Order.Stage)
      .filter((stage: any): boolean => ![this.Order.Stage.INITIAL, this.Order.Stage.ONLINE_PAYMENT_PENDING].includes(stage))
      .filter((stage: any): boolean => !this.Order.Stage.BeforeShipment.includes(stage))
      .filter((stage: any): boolean => !this.Order.Stage.CompletedStages.includes(stage));

    this.Assistant.ResponseModes = [this.Assistant.Mode.REQUEST_IMAGE,
      this.Assistant.Mode.GENERIC_USER_INPUT,
      this.Assistant.Mode.SINGLE_SELECTOR,
      this.Assistant.Mode.WITH_TEXT_REPLY,
      this.Assistant.Mode.TEXT_NOREPLY];
    this.Assistant.plainTextModes = [this.Assistant.Mode.REQUEST_IMAGE,
      this.Assistant.Mode.GENERIC_USER_INPUT,
      this.Assistant.Mode.SINGLE_SELECTOR,
      this.Assistant.Mode.WITH_TEXT_REPLY,
      this.Assistant.Mode.TEXT_NOREPLY];
    this.Assistant.NonPlainTextModes = [
      this.Assistant.Mode.PAYMENT,
      this.Assistant.Mode.ARTICLE,
      this.Assistant.Mode.STICKY_ACTION,
      this.Assistant.Mode.REQUEST_IMAGE_RESPONSE,
      this.Assistant.Mode.REQUEST_IMAGE,
      this.Assistant.Mode.REQUEST_IMAGE_REPORT,
      this.Assistant.Mode.REQUEST_IMAGE_FACE,
      this.Assistant.Mode.REQUEST_IMAGE_SIDE_FACE,
      this.Assistant.Mode.REQUEST_IMAGE_LEFT_SIDE_FACE,
      this.Assistant.Mode.REQUEST_IMAGE_RIGHT_SIDE_FACE,
      this.Assistant.Mode.REQUEST_IMAGE_FRONT_FACE,
      this.Assistant.Mode.REQUEST_IMAGE_HAIR,
      this.Assistant.Mode.REQUEST_IMAGE_BODY,
      this.Assistant.Mode.REQUEST_REPORT,
      this.Assistant.Mode.AUDIO,
      this.Assistant.Mode.VIDEO,
      this.Assistant.Mode.IMAGE_CLUBBED,
      this.Assistant.Mode.ATTACHMENT,
    ];
    this.Assistant.RequestImageModes = [
      this.Assistant.Mode.STICKY_ACTION,
      this.Assistant.Mode.REQUEST_IMAGE,
      this.Assistant.Mode.REQUEST_IMAGE_FACE,
      this.Assistant.Mode.REQUEST_IMAGE_HAIR,
      this.Assistant.Mode.REQUEST_IMAGE_BODY,
      this.Assistant.Mode.REQUEST_IMAGE_SIDE_FACE,
      this.Assistant.Mode.REQUEST_IMAGE_LEFT_SIDE_FACE,
      this.Assistant.Mode.REQUEST_IMAGE_RIGHT_SIDE_FACE,
      this.Assistant.Mode.REQUEST_IMAGE_FRONT_FACE,
      this.Assistant.Mode.REQUEST_IMAGE_REPORT,
    ];
    this.Assistant.MediaTypeModes = [this.Assistant.Mode.ARTICLE,
      this.Assistant.Mode.REQUEST_IMAGE_RESPONSE,
      this.Assistant.Mode.IMAGE_CLUBBED,
      this.Assistant.Mode.AUDIO,
      this.Assistant.Mode.VIDEO,
      this.Assistant.Mode.ATTACHMENT,
    ];
  }
}

export { Shared };
