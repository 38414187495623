<div class="hbox">
  <div class="flex-box">
  <div class="flex-heading">
    <div class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-[100]">
    <div class="tw-absolute tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
      <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
    </div>
    <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white" i18n="@@prescription">Prescription</span>
    </div>
    <toolbar-tabs #toolbarTabs="" (tabSelected)="tabChange($event)" [data]="tabData">
    <tab1 class="tw-uppercase" i18n="@@face">Face</tab1>
    <tab2 class="tw-uppercase" i18n="@@hair">Hair</tab2>
    </toolbar-tabs>
  </div>
  <div class="flex-content pos-rel">
    <loading class="pos-abs vh-center" *ngIf="loading"></loading>
    <div class="w100 h100 font-noto tw-bg-yellow-100 pT60" *ngIf="!loading && tabData.selectedTab === regimenClasses.indexOf(appConfig.Shared.Regimen.Class.FACE) && !facePrescription.length && !faceRegimenActive">
    <img class="diB m-auto" [src]="iconSrc" [class]="appConfig.Shared.Regimen.Class.FACE" style="width: 80px; height: 80px" />
    <div class="text tw-text-center">
      <p class="tw-text-300 tw-font-body tw-mb-4" i18n="@@noActiveFaceKit">
      You don't have any <br />
      active treatment kit for face
      </p>
      <div class="tw-w-12 tw-h-1 tw-bg-yellow-200 tw-center tw-inline-block tw-mb-4"></div>
      <p class="tw-text-400 tw-font-body tw-font-bold" i18n="@@skinGetYourNewTreatment">
      Get started now with doctor given kit for your skin. It only takes 2
      minutes!
      </p>
    </div>
    </div>
    <div class="w100 h100 font-noto tw-bg-yellow-100 pT60" *ngIf="!loading && tabData.selectedTab === regimenClasses.indexOf(appConfig.Shared.Regimen.Class.HAIR) && !hairPrescription.length && !hairRegimenActive">
    <img class="diB m-auto" [src]="iconSrc" [class]="appConfig.Shared.Regimen.Class.HAIR" style="width: 80px; height: 80px" />
    <div class="text tw-text-center">
      <p class="tw-text-300 tw-font-body tw-mb-4" i18n="@@noActiveHairKit">
      You don't have any <br />
      active treatment kit for hair
      </p>
      <div class="tw-w-12 tw-h-1 tw-bg-yellow-200 tw-center tw-inline-block tw-mb-4"></div>
      <p class="tw-text-400 tw-font-body tw-font-bold" i18n="@@hairGetYourNewTreatment">
      Get started now with doctor given kit for your hair. It only takes 2
      minutes!
      </p>
    </div>
    </div>
    <div class="empty-hint centerA empty-icon tw-font-body" *ngIf="!loading && tabData.selectedTab === regimenClasses.indexOf(appConfig.Shared.Regimen.Class.FACE) && !facePrescription.length && faceRegimenActive">
    No prescription available
    </div>
    <div class="empty-hint centerA empty-icon tw-font-body" *ngIf="!loading && tabData.selectedTab === regimenClasses.indexOf(appConfig.Shared.Regimen.Class.HAIR) && !hairPrescription.length && hairRegimenActive">
    No prescription available
    </div>
    <div class="fontS1 p20" *ngIf="tabData.selectedTab === regimenClasses.indexOf(appConfig.Shared.Regimen.Class.FACE) && facePrescription.length">
    <div class="w100 flex">
      <img class="mR15" src="https://cdn.cureskin.com/app/img/rebranded-logo.png" width="60px" />
      <div class="flex-grow pL25 tw-font-body">
      Cureskin : 26/10,<br />6th cross,<br />Ramanna Compound
      Mangammanapalya,<br />Bommanahalli, HSR Layout,<br />Bengaluru,
      Karnataka - 560068
      </div>
    </div>
    <div class="mT20">
      <img src="https://cdn.cureskin.com/rx.svg" width="30px" />
      <div class="mTB10 clr-secondary fontL2 bold tw-font-body" i18n="@@patientInformation">
      Patient Information
      </div>
      <div class="w100 flex mT10">
      <div class="w20 bold tw-font-body" i18n="@@name">Name</div>
      <div class="w50 tw-font-body">
        : &nbsp; {{user.get('PatientName')}}
      </div>
      <div class="w30 clr-secondary fontS2 tw-font-body">
        {{ facePrescription[facePrescription.length -
    1]?.get('createdAt')| date: 'dd MMM yyyy' }}
      </div>
      </div>
      <div class="w100 flex mT10">
      <div class="w20 bold tw-font-body" i18n="@@gender">Gender</div>
      <div class="w70 tw-font-body">: &nbsp; {{user.get('Gender')}}</div>
      </div>
      <div class="w100 flex mT10">
      <div class="w20 bold tw-font-body" i18n="@@age">Age</div>
      <div class="w70 tw-font-body">: &nbsp; {{user.get('Age')}}</div>
      </div>
      <div class="w100 flex mT10">
      <div class="w20 bold tw-font-body" i18n="@@doctor">Doctor</div>
      <div class="w70 tw-font-body">: &nbsp; {{doctorDisplayName}}</div>
      </div>
    </div>
    <div class="mT20">
      <div class="clr-secondary fontL2 bold tw-font-body tw-font-body" i18n="@@medicineInformation">
      Medicine Information
      </div>
      <div class="mT10">
      <div class="bold tw-font-body" i18n="@@productsMorning">
        Products (Morning) :
      </div>
      <li class="mL20 tw-font-body" *ngFor="let productName of facePrescription[facePrescription.length - 1].regimenData.morning">
        {{ productName }}
      </li>
      </div>
      <div class="mT10">
      <div class="bold tw-font-body" i18n="@@productsNight">
        Products (Night) :
      </div>
      <li class="mL20 tw-font-body" *ngFor="let productName of facePrescription[facePrescription.length - 1].regimenData.night">
        {{ productName }}
      </li>
      </div>
      <div class="w100 pR10">
      <div class="w40 float-right">
        <img src="{{signatureUrl}}" width="130px" />
      </div>
      </div>
    </div>
    </div>
    <div class="fontS1 p20" *ngIf="tabData.selectedTab === regimenClasses.indexOf(appConfig.Shared.Regimen.Class.HAIR) && hairPrescription.length">
    <div class="w100 flex">
      <img class="mR15" src="https://cdn.cureskin.com/app/img/rebranded-logo.png" width="60px" />
      <div class="flex-grow pL25 tw-font-body">
      Cureskin : 26/10,<br />6th cross,<br />Ramanna Compound
      Mangammanapalya,<br />Bommanahalli, HSR Layout,<br />Bengaluru,
      Karnataka - 560068
      </div>
    </div>
    <div class="mT20">
      <img src="https://cdn.cureskin.com/rx.svg" width="30px" />
      <div class="mTB10 clr-secondary fontL2 bold tw-font-body" i18n="@@patientInformation">
      Patient Information
      </div>
      <div class="w100 flex mT10">
      <div class="w20 bold tw-font-body" i18n="@@name">Name</div>
      <div class="w50 tw-font-body">
        : &nbsp; {{user.get('PatientName')}}
      </div>
      <div class="w30 clr-secondary fontS2 tw-font-body">
        {{ hairPrescription[hairPrescription.length -
    1]?.get('createdAt')| date: 'dd MMM yyyy' }}
      </div>
      </div>
      <div class="w100 flex mT10">
      <div class="w20 bold tw-font-body" i18n="@@gender">Gender</div>
      <div class="w70 tw-font-body">: &nbsp; {{user.get('Gender')}}</div>
      </div>
      <div class="w100 flex mT10">
      <div class="w20 bold tw-font-body" i18n="@@age">Age</div>
      <div class="w70 tw-font-body">: &nbsp; {{user.get('Age')}}</div>
      </div>
      <div class="w100 flex mT10">
      <div class="w20 bold tw-font-body" i18n="@@doctor">Doctor</div>
      <div class="w70 tw-font-body">: &nbsp; {{doctorDisplayName }}</div>
      </div>
    </div>
    <div class="mT20">
      <div class="clr-secondary fontL2 bold tw-font-body tw-font-body" i18n="@@medicineInformation">
      Medicine Information
      </div>
      <div class="mT10">
      <div class="bold tw-font-body" i18n="@@productsMorning">
        Products (Morning) :
      </div>
      <li class="mL20 tw-font-body" *ngFor="let productName of hairPrescription[hairPrescription.length - 1].regimenData.morning">
        {{ productName }}
      </li>
      </div>
      <div class="mT10">
      <div class="bold tw-font-body" i18n="@@productsNight">
        Products (Night) :
      </div>
      <li class="mL20 tw-font-body" *ngFor="let productName of hairPrescription[hairPrescription.length - 1].regimenData.night">
        {{ productName }}
      </li>
      </div>
      <div class="w100 pR10">
      <div class="w40float-right">
        <img src="{{signatureUrl}}" width="130px" />
      </div>
      </div>
    </div>
    </div>
  </div>
  <div class="flex-footer shadow" *ngIf="!loading">
    <div class="w100 p15" *ngIf="tabData.selectedTab === regimenClasses.indexOf(appConfig.Shared.Regimen.Class.HAIR) && !hairPrescription.length && !hairRegimenActive">
    <cs-button [class]="'w100 primary'" [disabled]="isInternalUser" (callback)="triggerTreeForRegimen(appConfig.Shared.Regimen.Class.HAIR)"><cs-button-text i18n="@@getYourTreatment">Get your treatment kit</cs-button-text></cs-button>
    </div>
    <div class="w100 p15" *ngIf="tabData.selectedTab === regimenClasses.indexOf(appConfig.Shared.Regimen.Class.FACE) && !facePrescription.length && !faceRegimenActive">
    <cs-button [class]="'w100 primary'" [disabled]="isInternalUser" (callback)="triggerTreeForRegimen(appConfig.Shared.Regimen.Class.FACE)"><cs-button-text i18n="@@getYourTreatment">Get your treatment kit</cs-button-text></cs-button>
    </div>
  </div>
  </div>
</div>
