import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AppConfig } from '../../app/app.config';

@Component({ selector: 'toolbar-tabs', templateUrl: './toolbar-tabs.html', styleUrls: ['./toolbar-tabs.scss'] })
export class ToolbarTabsComponent {
  toolbar: any = { selectedTab: -1, count: 2 };
  @ViewChild('header', { static: false }) header: ElementRef;
  @Input('data') set setData(data: any) {
    this.toolbar = data;
  }
  @Input('class') customClass: string;
  @Input('floatOnScroll') floatOnScroll: boolean = true;
  @Output('tabSelected') tabSelected: EventEmitter<any> = new EventEmitter();
  @Input('isRegimenNewPropositionUI') isRegimenNewPropositionUI: boolean = false;

  constructor(public appConfig: AppConfig) {}

  tabChange(index: any): void {
    this.toolbar.selectedTab = index;
    this.tabSelected.emit(index);
  }
}
