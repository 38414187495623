<ng-container *ngIf="isHairFrontPhoto || isHairTopPhoto">
     <cs-icon class="tw-absolute tw-left-6 tw-top-4 tw-h-6 tw-w-6 tw-text-black tw-z-10"
              [iconName]="'arrow_back'"
              (click)="back()"></cs-icon>
     <main class="tw-flex tw-flex-col tw-h-full tw-relative">
          <section class="tw-flex-1 tw-flex tw-flex-col tw-h-full tw-pb-[104px]">
               <swiper #slider
                       class="tw-h-full tw-w-full"
                       [config]="carouselConfig">
                    <div *swiperSlide
                         class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-6 tw-pt-20 tw-gap-8">
                         <img class="tw-rounded-5"
                              *ngIf="user?.get('Gender') === appConfig.Shared.Gender.MALE && isHairFrontPhoto"
                              src="https://cdn.cureskin.com/app/img/hair_instruction_male_1.png">
                         <img class="tw-rounded-5"
                              *ngIf="user?.get('Gender') === appConfig.Shared.Gender.MALE && isHairTopPhoto"
                              src="https://cdn.cureskin.com/app/img/hair_top_instruction_male_1.png">
                         <img class="tw-rounded-5"
                              *ngIf="user?.get('Gender') !== appConfig.Shared.Gender.MALE"
                              src="https://cdn.cureskin.com/app/img/hair_instruction_female_1.png">
                         <h2 *ngIf="user?.get('Gender') === appConfig.Shared.Gender.MALE"
                             class="tw-font-body tw-text-700 tw-text-black tw-text-center"
                             i18n="@@combYourHairBack">Comb your <br>hair back</h2>
                         <h2 *ngIf="user?.get('Gender') !== appConfig.Shared.Gender.MALE"
                             class="tw-font-body tw-text-700 tw-text-black tw-text-center"
                             i18n="@@centerPartitionWithHair">Center partition with hair behind shoulder</h2>
                    </div>
                    <div *swiperSlide
                         class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-6 tw-pt-20 tw-gap-8">
                         <img class="tw-rounded-5"
                              *ngIf="user?.get('Gender') === appConfig.Shared.Gender.MALE && isHairFrontPhoto"
                              src="https://cdn.cureskin.com/app/img/hair_instruction_male_2.png ">
                         <img class="tw-rounded-5"
                              *ngIf="user?.get('Gender') === appConfig.Shared.Gender.MALE && isHairTopPhoto"
                              src="https://cdn.cureskin.com/app/img/hair_top_instruction_male_2.png">
                         <img class="tw-rounded-5"
                              *ngIf="user?.get('Gender') !== appConfig.Shared.Gender.MALE && isHairFrontPhoto"
                              src="https://cdn.cureskin.com/app/img/hair_instruction_female_2.png">
                         <img class="tw-rounded-5"
                              *ngIf="user?.get('Gender') !== appConfig.Shared.Gender.MALE && isHairTopPhoto"
                              src="https://cdn.cureskin.com/app/img/hair_top_instruction_female_2.png">
                         <h2 *ngIf="isHairFrontPhoto"
                             class="tw-font-body tw-text-700 tw-text-black tw-text-center"
                             i18n="@@holdPhoneInFront">
                              Hold phone in front <br>of your face</h2>
                         <h2 *ngIf="isHairTopPhoto"
                             class="tw-font-body tw-text-700 tw-text-black tw-text-center"
                             i18n="@@tiltYourHeadTowardsCamera">Tilt your head <br> towards camera</h2>
                    </div>
                    <ng-container
                                  *ngIf="(!showNewHairAiInstructions || user?.get('Gender') !== appConfig.Shared.Gender.MALE) && !isHairTopPhoto">
                         <div *swiperSlide
                              class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-6 tw-pt-20 tw-gap-8">
                              <img class="tw-rounded-5"
                                   *ngIf="user?.get('Gender') === appConfig.Shared.Gender.MALE  && isHairFrontPhoto"
                                   src="https://cdn.cureskin.com/app/img/hair_instruction_male_3.png">
                              <img class="tw-rounded-5"
                                   *ngIf="user?.get('Gender') !== appConfig.Shared.Gender.MALE"
                                   src="https://cdn.cureskin.com/app/img/hair_instruction_female_3.png">
                              <h2 class="tw-font-body tw-text-700 tw-text-black tw-text-center"
                                  i18n="@@fitYourFaceInTheOval">
                                   Fit your face in <br>the oval</h2>
                         </div>
                    </ng-container>
                    <ng-container *ngIf="!isHairTopPhoto">
                         <div *swiperSlide
                              class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-6 tw-pt-20 tw-gap-8">
                              <div class="tw-relative">
                                   <img class="tw-rounded-5"
                                        *ngIf="user?.get('Gender') === appConfig.Shared.Gender.MALE  && isHairFrontPhoto"
                                        src="https://cdn.cureskin.com/app/img/hair_instruction_male_4.png">
                                   <img class="tw-rounded-5"
                                        *ngIf="user?.get('Gender') !== appConfig.Shared.Gender.MALE"
                                        src="https://cdn.cureskin.com/app/img/hair_instruction_female_4.png">
                                   <div
                                        class="tw-flex tw-gap-1 tw-justify-around tw-items-center tw-w-full tw-absolute -tw-bottom-4">
                                        <div
                                             class="tw-bg-orange-400 tw-rounded-full tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-center">
                                             <cs-icon class="tw-h-6 tw-w-6 tw-text-white"
                                                      [iconName]="'close'"></cs-icon>
                                        </div>
                                        <div
                                             class="tw-bg-green-400 tw-rounded-full tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-center">
                                             <cs-icon class="tw-h-6 tw-w-6 tw-text-white"
                                                      [iconName]="'done'"></cs-icon>
                                        </div>
                                   </div>
                              </div>
                              <h2 class="tw-font-body tw-text-700 tw-text-black tw-text-center"
                                  i18n="@@removeGlasses">
                                   Remove glasses</h2>
                         </div>
                    </ng-container>
               </swiper>
          </section>
          <footer class="tw-absolute tw-bottom-0 tw-right-0 tw-left-0 tw-h-[104px] tw-flex tw-px-6 tw-py-7">
               <div *ngIf="!slider?.isEnd && !isCarouselReachedEnd"
                    i18n="@@next"
                    class="tw-flex-1 tw-flex tw-items-center tw-justify-center tw-h-12 tw-border-2 tw-rounded-full tw-border-green-400 tw-font-body tw-text-500 tw-font-bold tw-text-black tw-transition-all"
                    (click)="slider?.swiperRef.slideNext()">Next</div>
               <div *ngIf="slider?.swiperRef?.isEnd || isCarouselReachedEnd"
                    i18n="@@continue"
                    class="tw-flex-1 tw-flex tw-items-center tw-justify-center tw-h-12 tw-bg-green-400 tw-rounded-full tw-font-body tw-text-500 tw-font-bold tw-text-white tw-transition-all"
                    (click)="takePhoto()">Continue</div>
          </footer>
     </main>
</ng-container>

<ng-container *ngIf="showNewFacePhotoInstructions && !(isHairFrontPhoto || isHairTopPhoto)">
     <cs-icon class="tw-absolute tw-left-6 tw-top-4 tw-h-6 tw-w-6 tw-text-black tw-z-10"
              [iconName]="'arrow_back'"
              (click)="back()"></cs-icon>
     <main class="tw-flex tw-flex-col tw-h-full tw-relative">
          <section class="tw-flex-1 tw-flex tw-flex-col tw-h-full tw-pb-[104px]">
               <swiper #slider
                       class="tw-h-full tw-w-full"
                       [config]="carouselConfig">
                    <div *swiperSlide
                       class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-6 tw-pt-20 tw-gap-8">
                       <img class="tw-rounded-5"
                            *ngIf="user?.get('Gender') === appConfig.Shared.Gender.MALE && isFrontFacePhoto"
                            src="https://cdn.cureskin.com/app/img/hair_instruction_male_2.png">
                       <img class="tw-rounded-5"
                            *ngIf="user?.get('Gender') === appConfig.Shared.Gender.MALE && isSideFacePhoto"
                            src="https://cdn.cureskin.com/app/img/face_side_instruction_male_1.png">
                       <img class="tw-rounded-5"
                            *ngIf="user?.get('Gender') !== appConfig.Shared.Gender.MALE && isFrontFacePhoto"
                            src="https://cdn.cureskin.com/app/img/hair_instruction_female_2.png">
                       <img class="tw-rounded-5"
                            *ngIf="user?.get('Gender') !== appConfig.Shared.Gender.MALE && isSideFacePhoto"
                            src="https://cdn.cureskin.com/app/img/face_side_instruction_female_1.png">
                       <h2 *ngIf="isFrontFacePhoto"
                           class="tw-font-body tw-text-700 tw-text-black tw-text-center"
                           i18n="@@holdPhoneInFront">
                            Hold phone in front <br>of your face</h2>
                       <h2 *ngIf="isSideFacePhoto"
                           class="tw-font-body tw-text-700 tw-text-black tw-text-center"
                           i18n="@@holdPhoneOnSide">Hold phone on side of <br>your face</h2>
                    </div>
                    <ng-container *ngIf="isFrontFacePhoto">
                         <div *swiperSlide
                              class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-6 tw-pt-20 tw-gap-8">
                              <div class="tw-relative">
                                   <img class="tw-rounded-5"
                                        *ngIf="user?.get('Gender') === appConfig.Shared.Gender.MALE"
                                        src="https://cdn.cureskin.com/app/img/hair_instruction_male_4.png">
                                   <img class="tw-rounded-5"
                                        *ngIf="user?.get('Gender') !== appConfig.Shared.Gender.MALE"
                                        src="https://cdn.cureskin.com/app/img/hair_instruction_female_4.png">
                                   <div
                                        class="tw-flex tw-gap-1 tw-justify-around tw-items-center tw-w-full tw-absolute -tw-bottom-4">
                                        <div
                                             class="tw-bg-orange-400 tw-rounded-full tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-center">
                                             <cs-icon class="tw-h-6 tw-w-6 tw-text-white"
                                                      [iconName]="'close'"></cs-icon>
                                        </div>
                                        <div
                                             class="tw-bg-green-400 tw-rounded-full tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-center">
                                             <cs-icon class="tw-h-6 tw-w-6 tw-text-white"
                                                      [iconName]="'done'"></cs-icon>
                                        </div>
                                   </div>
                              </div>
                              <h2 *ngIf="user?.get('Gender') === appConfig.Shared.Gender.MALE"
                                  class="tw-font-body tw-text-700 tw-text-black tw-text-center"
                                  i18n="@@removeGlasses">
                                  Remove glasses</h2>
                              <h2 *ngIf="user?.get('Gender') !== appConfig.Shared.Gender.MALE"
                                  class="tw-font-body tw-text-700 tw-text-black tw-text-center"
                                  i18n="@@removeGlassesAndMakeup">
                                  Remove glasses <br>& makeup</h2>
                         </div>
                    </ng-container>
                    <ng-container *ngIf="isSideFacePhoto">
                         <div *swiperSlide
                              class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-6 tw-pt-20 tw-gap-8">
                              <div class="tw-relative">
                                   <img class="tw-rounded-5"
                                        *ngIf="user?.get('Gender') === appConfig.Shared.Gender.MALE"
                                        src="https://cdn.cureskin.com/app/img/face_side_instruction_male_2.png">
                                   <img class="tw-rounded-5"
                                        *ngIf="user?.get('Gender') !== appConfig.Shared.Gender.MALE"
                                        src="https://cdn.cureskin.com/app/img/face_side_instruction_female_2.png">
                                   <div
                                        class="tw-flex tw-gap-1 tw-justify-around tw-items-center tw-w-full tw-absolute -tw-top-4">
                                        <div
                                             class="tw-bg-orange-400 tw-rounded-full tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-center">
                                             <cs-icon class="tw-h-6 tw-w-6 tw-text-white"
                                                      [iconName]="'close'"></cs-icon>
                                        </div>
                                        <div
                                             class="tw-bg-green-400 tw-rounded-full tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-center">
                                             <cs-icon class="tw-h-6 tw-w-6 tw-text-white"
                                                      [iconName]="'done'"></cs-icon>
                                        </div>
                                   </div>
                              </div>
                              <h2 *ngIf="user?.get('Gender') === appConfig.Shared.Gender.MALE"
                                  class="tw-font-body tw-text-700 tw-text-black tw-text-center"
                                  i18n="@@ensureNaturalLight">
                                  Ensure natural light <br>for clear face detection</h2>
                              <h2 *ngIf="user?.get('Gender') !== appConfig.Shared.Gender.MALE"
                                  class="tw-font-body tw-text-700 tw-text-black tw-text-center"
                                  i18n="@@pullHairBack">
                                  Pull your hair back, <br>ensure natural lighting</h2>
                         </div>
                    </ng-container>
                    <ng-container *ngIf="isFrontFacePhoto">
                         <div *swiperSlide
                              class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-6 tw-pt-20 tw-gap-8">
                              <img class="tw-rounded-5"
                                   *ngIf="user?.get('Gender') === appConfig.Shared.Gender.MALE"
                                   src="https://cdn.cureskin.com/app/img/hair_instruction_male_3.png">
                              <img class="tw-rounded-5"
                                   *ngIf="user?.get('Gender') !== appConfig.Shared.Gender.MALE"
                                   src="https://cdn.cureskin.com/app/img/hair_instruction_female_3.png">
                              <h2 class="tw-font-body tw-text-700 tw-text-black tw-text-center"
                                  i18n="@@fitYourFaceInTheOval">
                                   Fit your face in <br>the oval</h2>
                         </div>
                    </ng-container>
               </swiper>
          </section>
          <footer class="tw-absolute tw-bottom-0 tw-right-0 tw-left-0 tw-h-[104px] tw-flex tw-px-6 tw-py-7">
               <div *ngIf="!slider?.isEnd && !isCarouselReachedEnd"
                    i18n="@@next"
                    class="tw-flex-1 tw-flex tw-items-center tw-justify-center tw-h-12 tw-border-2 tw-rounded-full tw-border-green-400 tw-font-body tw-text-500 tw-font-bold tw-text-black tw-transition-all"
                    (click)="slider?.swiperRef.slideNext()">Next</div>
               <div *ngIf="slider?.swiperRef?.isEnd || isCarouselReachedEnd"
                    i18n="@@continue"
                    class="tw-flex-1 tw-flex tw-items-center tw-justify-center tw-h-12 tw-bg-green-400 tw-rounded-full tw-font-body tw-text-500 tw-font-bold tw-text-white tw-transition-all"
                    (click)="takePhoto()">Continue</div>
          </footer>
     </main>
</ng-container>

<div *ngIf="!showNewFacePhotoInstructions && !(isHairFrontPhoto || isHairTopPhoto)"
     class="tw-block tw-border-spacing-0 tw-w-full tw-h-full tw-overflow-hidden tw-font-body">
     <div class="tw-flex tw-flex-col tw-flex-wrap tw-h-full">
          <div class="tw-flex-initial tw-w-full">
               <div class="tw-flex tw-items-center tw-justify-center tw-h-14 tw-relative tw-bg-black">
                    <cs-icon class="tw-absolute tw-left-6 tw-top-4 tw-h-6 tw-w-6 tw-text-white"
                             [iconName]="'arrow_back'"
                             (click)="back()"></cs-icon>
                    <div class="tw-font-body tw-text-300 tw-text-white"
                         i18n="@@takeSecurePhotoHeader"> Take secure photo </div>
               </div>
          </div>
          <div
               class="tw-relative tw-flex tw-items-center tw-justify-center tw-overflow-hidden tw-flex-auto tw-bg-[#D7CDDF]">
               <img class="tw-h-full tw-m-auto"
                    [src]="imageSrc"
                    (load)="enableGif()"
                    [ngStyle]='{ "visibility": gifLoaded ? "visible": "hidden" }' />
               <video-player *ngIf="videoURL"
                             [src]="videoURL"
                             [autoplay]="true"
                             [hideAudioControl]="true"
                             (onLoad)="videoLoaded = true"
                             [muteVideo]="true"
                             [poster]="posterUrl"
                             class="tw-w-[90%] tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2"></video-player>
               <audio #audio=""
                      style="visibility: hidden"></audio>
               <loading class="tw-absolute tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2 tw-z-[-1]"
                        *ngIf="!videoLoaded && !gifLoaded"></loading>
          </div>
          <div class="tw-px-6 tw-py-4">
               <div class="tw-pb-4 tw-text-center">
                    <span class="tw-pl-[10px] tw-uppercase"
                          i18n="@@securePhotoNew">Click Secure Photo. Privacy Protected</span>
               </div>
               <cs-button [class]="'tw-w-full primary tw-bg-green-400'"
                          (callback)="takePhoto()">
                    <cs-button-text *ngIf="isFrontFacePhoto"
                                    i18n="@@takeFrontPhoto">Take Front Face Photo</cs-button-text>
                    <cs-button-text *ngIf="isSideFacePhoto"
                                    i18n="@@takeSideFacePhoto">Take Side Face Photo</cs-button-text>
                    <cs-button-text *ngIf="isHairTopPhoto"
                                    i18n="@@takeHairTopPhoto">Take Scalp photo</cs-button-text>
                    <cs-button-text *ngIf="isHairFrontPhoto"
                                    i18n="@@takeHairFrontPhoto">Take Hair Front Photo</cs-button-text>
                    <cs-button-text *ngIf="!isFrontFacePhoto && !isSideFacePhoto && !isHairFrontPhoto && !isHairTopPhoto"
                                    i18n="@@takePhoto">Take Photo</cs-button-text>
               </cs-button>
          </div>
     </div>
</div>
<file-picker (onFileChange)="uploadImageFromNativeCamera($event)"
             capture="user"></file-picker>