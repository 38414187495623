<div class="tw-flex tw-flex-col tw-bg-teal-500 tw-bg-repeat-x tw-bg-top tw-h-full">
  <div id="backButton" class="tw-fixed tw-left-4 tw-top-4 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-gray-800 tw-rounded-xl tw-h-10 tw-w-10" (click)="handleBackPress()">
    <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
  </div>
  <header class="tw-flex-1 tw-flex tw-items-end tw-justify-center tw-px-10 tw-pt-16" *ngIf="isConsulationOrder">
    <div class="tw-text-[16px] tw-font-body tw-font-bold tw-text-white tw-text-600 tw-text-center" i18n="@@hiUser">Hi {{ user?.get("PatientName")?.split(" ")[0] }},</div>
  </header>
  <header class="tw-flex-1 tw-flex tw-items-end tw-justify-center tw-px-10 tw-pt-16 tw-pb-10" *ngIf="!isConsulationOrder">
    <div class="tw-font-body tw-font-bold tw-text-gray-75 tw-text-center" i18n="@@orderConfirmed" [ngClass]="{'tw-text-700': ['ta', 'te', 'ml', 'kn'].includes(user?.get('languagePreference')), 'tw-text-800': !(['ta', 'te', 'ml', 'kn'].includes(user?.get('languagePreference')))}"> Your order is confirmed </div>
  </header>
  <header class="tw-flex-1 tw-flex tw-items-end tw-justify-center tw-px-10 tw-pb-[140px]" *ngIf="isConsulationOrder">
    <div class=" tw-text-[24px] tw-font-body tw-font-bold tw-text-gray-75 tw-text-800 tw-text-center" i18n="@@consultationOrderConfirmed" id="consultationOrderConfirmedTitle" [ngClass]="{'tw-text-700': ['ta', 'te', 'ml', 'kn'].includes(user?.get('languagePreference')), 'tw-text-800': !(['ta', 'te', 'ml', 'kn'].includes(user?.get('languagePreference')))}"> Your purchase is confirmed </div>
  </header>
  <section [ngClass]="{
    'tw-overflow-visible': isConsulationOrder ,
    'tw-overflow-auto': !isConsulationOrder
  }" class="tw-rounded-tr-2xl tw-rounded-tl-2xl">
    <section class="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-justify-center tw-pb-12 tw-bg-white tw-rounded-tr-2xl tw-rounded-tl-2xl tw-px-6" *ngIf="!isConsulationOrder">
      <div class="tw-flex tw-items-center tw-justify-between">
        <img src="/assets/images/paypment-sccess.gif" class="first:tw-w-[150px]" [width]='(showSurpriseGift$ | async) ? "150px" : "200px"' />
        <div class="tw-text-800 tw-font-semibold tw-my-0 tw-mx-6" *ngIf="(showSurpriseGift$ | async)">+</div>
        <img class="tw-w-15 tw-mb-4 tw-animate-gift_rotate tw-drop-shadow-z1" *ngIf="(showSurpriseGift$ | async)" src="/assets/images/gift-box.svg" />
      </div>
      <p id="freeGift" class="tw-font-body tw-text-300 tw-text-gray-800 tw-px-12 tw-text-center" *ngIf="(showSurpriseGift$ | async)" i18n="@@findAFreeGiftInside"> Find a free gift inside your CureSkin kit. </p>
      <p class="tw-font-body tw-text-300 tw-text-gray-800 tw-font-normal tw-mt-4 tw-text-center" i18n="@@youWillReceiveOn"> Your order is expected to be delivered on </p>
      <p class="tw-font-body tw-font-bold tw-text-gray-800 tw-mt-4 tw-text-center" [ngClass]="{'tw-text-600': ['ta', 'te', 'ml', 'kn'].includes(user?.get('languagePreference')), 'tw-text-700': !(['ta', 'te', 'ml', 'kn'].includes(user?.get('languagePreference')))}">
        {{expectedDeliveryDate.from | date: 'd MMM' }} - {{expectedDeliveryDate.to | date: 'd MMM'}}.
      </p>
    </section>
    <section class="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-4 tw-pb-4 tw-bg-white tw-rounded-tr-2xl tw-rounded-tl-2xl" *ngIf="isConsulationOrder">
      <div class="tw-p-4 tw-py-10 -tw-mt-[35%] tw-bg-teal-100 tw-items-center  tw-rounded-2xl">
        <div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
          <cs-icon class="tw-w-[68px]"
          [iconName]="'call'"></cs-icon>
          </div>
          <div class="tw-flex tw-items-center tw-justify-between tw-text-center">
          <p class="tw-font-display tw-p-4 tw-px-14 tw-text-[22px] tw-text-400 tw-leading-8  tw-mt-4" id="consultationOrderContent" i18n="@@consultationOrderContent"> You will receive a call from our diet specialist shortly. </p>
      </div>
      </div>
    </section>
    <footer class="tw-flex-none tw-flex tw-flex-col tw-gap-3 tw-p-6 tw-pb-12 tw-bg-gray-80">
      <div id="redirectToExplorePage" class="tw-flex tw-flex-wrap tw-gap-2 tw-items-center tw-bg-gray-75 tw-rounded-2 tw-py-2 tw-pl-6 tw-pr-2 tw-shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]" (click)='navigateTo("/user?tab=explore")'>
        <div class="tw-flex-1 tw-flex tw-flex-col tw-items-start">
          <p class="tw-font-body tw-font-bold tw-text-300 tw-text-gray-800" i18n="@@receiveTips"> Receive expert tips <span class="tw-block tw-font-normal tw-font-body tw-text-300 tw-text-gray-800">on skin & hair care</span></p>
        </div>
        <div class="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-bg-green-400 tw-h-8 tw-px-4 tw-rounded-full">
          <div class="tw-font-body tw-font-bold tw-text-200 tw-text-white tw-lowercase" i18n="@@clickHere">Click Here</div>
          <cs-icon class="tw-h-4 tw-w-4 tw-text-white" [iconName]='"chevron_right"'></cs-icon>
        </div>
      </div>
      <div id="redirectToUserWallet" class="tw-flex tw-flex-wrap tw-gap-2 tw-items-center tw-bg-gray-75 tw-rounded-2 tw-py-2 tw-pl-6 tw-pr-2 tw-shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]" (click)='navigateTo("/user/wallet?tab=earn&amp;back=home")'>
        <div class="tw-flex-1 tw-flex tw-flex-col tw-items-start">
          <p class="tw-font-body tw-font-bold tw-text-300 tw-text-gray-800" i18n="@@referralCash"> Earn ₹{{referralCashValue$ | async}}
            <span class="tw-block tw-font-normal tw-font-body tw-text-300 tw-text-gray-800"> Cureskin cash</span>
          </p>
        </div>
        <div class="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-bg-green-400 tw-h-8 tw-px-4 tw-rounded-full">
          <div class="tw-font-body tw-font-bold tw-text-200 tw-text-white tw-lowercase" i18n="@@referNow">Refer Now</div>
          <cs-icon class="tw-h-4 tw-w-4 tw-text-white" [iconName]='"chevron_right"'></cs-icon>
        </div>
      </div>
    </footer>
  </section>
</div>
