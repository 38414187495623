<div class="font-noto tw-flex tw-items-center tw-flex-col pos-abs vh-center z-ind-5 tw-mt-8" *ngIf="loading">
  <loading [stroke]='"#2E5CA3"'></loading>
  <div class="tw-text-400 tw-text-blue-400 tw-font-bold"> Generating Report... </div>
</div>
<div class="tw-bg-teal-200 tw-font-body tw-overflow-scroll tw-h-full" *ngIf="!loading">
  <div class="tw-flex tw-w-[40px] tw-h-[40px] tw-p-2 tw-justify-center tw-items-center tw-shrink-0 tw-rounded-xl tw-bg-black tw-shadow-[0_2px_4px_0_rgba(0,0,0,0.10)] tw-mt-4 tw-ml-4" *ngIf="!isTimerPage" (click)="back()">
    <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
  </div>
    <div class="tw-text-800 tw-text-gray-800 tw-font-body tw-font-bold tw-mt-[104px] tw-mx-4 tw-mb-4" i18n="@@yourSkinReport">Your Skin Report</div>
  <div class="font-noto tw-rounded-3xl">
    <section class="tw-bg-gray-80 tw-w-full tw-pt-6 tw-rounded-tl-5 tw-rounded-tr-5">
      <section>
        <div class="tw-mx-4 tw-mb-4">
          <p class="tw-pb-1 tw-font-body tw-font-bold tw-text-300 tw-text-gray-800 tw-font-medium">
            <span class="tw-font-normal">Main concern:</span><span class="tw-font-bold">&nbsp;{{ mainConcern || (detectedConcerns?.length ?
              detectedConcerns[0] : '') }}</span>
            </p>
          <p *ngIf="otherConcerns.length > 0" class="tw-pb-1 tw-font-body tw-font-bold tw-text-300 tw-text-gray-800 tw-font-medium">
            <span class="tw-font-normal">Other concerns:<span class="bolder">
                {{ otherConcerns }}</span></span>
          </p>
          <p class="tw-pb-1 tw-font-body tw-font-bold tw-text-300 tw-text-gray-800 tw-font-medium">
            <span class="tw-font-normal"><span>Severity:</span><span class="bolder">&nbsp;</span><span class="bolder">{{severity}}</span></span>
          </p>
        </div>
      </section>
      <!-- Doctor card -->
      <section class="tw-mx-4 tw-my-4 tw-py-4 tw-bg-white tw-rounded-3 tw-shadow-z1 tw-overflow-hidden tw-relative" *ngIf="allocatedDoctor">
        <div class="tw-relative">
          <div class="tw-px-2 tw-py-2 tw-mb-2 tw-bg-orange-100 tw-rounded-tr-3 tw-rounded-br-3 tw-w-content tw-flex tw-flex-row tw-items-center tw-gap-2">
            <cs-icon class="tw-w-5 tw-h-5 tw-text-orange-500" [iconName]="'check_circle'"></cs-icon>
            <p class="tw-font-body tw-font-bold tw-text-200 tw-text-orange-400"> Your skin specialist </p>
          </div>
          <div class="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-px-4 tw-justify-between">
            <div>
              <h2 class="tw-font-body tw-font-bold tw-text-500 tw-text-gray-800 tw-mb-2">
                {{allocatedDoctor?.get('DoctorDisplayName')}}
              </h2>
              <p class="tw-font-body tw-text-300 tw-text-gray-400 tw-mb-2">
                {{conn.getQualifyText(allocatedDoctor)}}
              </p>
              <p class="tw-font-body tw-font-bold tw-text-200 tw-text-gray-800"> Has treated {{allocatedDoctor?.get('patientsTreated') || 0}}+ <br />Cureskin patients </p>
            </div>
            <img class="tw-flex-none tw-h-[120px] tw-w-[120px] tw-rounded-full tw-bg-gray-300 tw-object-contain" *ngIf="allocatedDoctor?.get('doctorDisplayImage')" [src]="allocatedDoctor.get('doctorDisplayImage')" alt="Doctor Image" />
          </div>
        </div>
      </section>
      <!-- Overall Summary -->
      <div class="tw-flex tw-justify-space-around tw-gap-4 tw-items-start tw-mx-4 tw-my-6">
        <img class="w40 tw-object-contain tw-h-50" [src]="previousInstantCheckup?.[0]?.['imagePath']" />
        <div>
          <div class="tw-text-300 tw-text-gray-800 tw-font-bold tw-uppercase">Overall Summary</div>
          <div class="tw-text-300 tw-text-gray-500">
            {{ overallSummary || ''}}
          </div>
        </div>
      </div>
      <div class="tw-text-300 tw-text-gray-800 tw-font-bold tw-uppercase tw-mx-4 tw-mt-6">
        Your key skin parameters
      </div>
      <div class="tw-overflow-x-scroll tw-flex tw-gap-x-2.5 tw-pt-4 tw-pb-6">
        <div class="tw-flex">
          <div *ngIf="skinParametersInfo?.sebumBalanceParameters?.patientScore && skinParametersInfo?.sebumBalanceParameters?.expectedRange" class="tw-relative first:tw-ml-4 last:tw-mr-4 tw-shadow-[0_2px_4px_0_rgba(0,0,0,0.10)] tw-flex-col tw-rounded-lg tw-mr-2 tw-pb-[18vh] tw-bg-white">
            <cs-icon *ngIf="severityIconDisplayMap['sebumBalance']" class="tw-absolute -tw-top-[12px] tw-ml-4 tw-w-6 tw-h-6 tw-rounded-[50%] tw-shadow-[0_2px_4px_0_rgba(0,0,0,0.10)]" [iconName]="'/rebranding/v1/exclamation_icon'"></cs-icon>
            <div class="tw-text-500 tw-text-gray-800 tw-font-bold tw-mt-6 tw-mx-4 tw-mb-2">
              Sebum Balance <br> /Oiliness
            </div>
            <div class="tw-text-200 tw-font-normal tw-text-gray-500 tw-w-[184px] tw-mx-4">
              Sebum is an oily substance produced by the sebaceous glands in the
              dermis of the skin, and it plays a crucial role in maintaining the
              health and integrity of the skin.
            </div>
            <skin-parameter [patientScore]="skinParametersInfo?.sebumBalanceParameters?.patientScore" [expectedRange]="skinParametersInfo?.sebumBalanceParameters?.expectedRange" (showExclamationIcon)="setSeverityIconDisplayState('sebumBalance', $event)"></skin-parameter>
          </div>
          <div *ngIf="skinParametersInfo?.inflammationParameters?.patientScore && skinParametersInfo?.inflammationParameters?.expectedRange" class="tw-relative first:tw-ml-4 last:tw-mr-4 tw-shadow-[0_2px_4px_0_rgba(0,0,0,0.10)] tw-flex-col tw-rounded-lg tw-mr-2 tw-pb-[18vh] tw-bg-white">
            <cs-icon *ngIf="severityIconDisplayMap['inflammation']" class="tw-absolute -tw-top-[12px] tw-ml-4 tw-w-6 tw-h-6 tw-rounded-[50%] tw-shadow-[0_2px_4px_0_rgba(0,0,0,0.10)]" [iconName]="'/rebranding/v1/exclamation_icon'"></cs-icon>
            <div class="tw-text-500 tw-text-gray-800 tw-font-bold tw-mt-6 tw-mx-4 tw-mb-2">
              Inflammation/<br>Redness
            </div>
            <div class="tw-text-200 tw-font-normal tw-text-gray-500 tw-w-[184px] tw-mx-4">
              Inflammation on the skin signify the presence of an injury, physical
              trauma, infection, or irritation in that area, which is commonly
              associated with acne
            </div>
            <skin-parameter [patientScore]="skinParametersInfo?.inflammationParameters?.patientScore" [expectedRange]="skinParametersInfo?.inflammationParameters?.expectedRange" (showExclamationIcon)="setSeverityIconDisplayState('inflammation', $event)"></skin-parameter>
          </div>
          <div *ngIf="skinParametersInfo?.sensitivityParameters?.patientScore && skinParametersInfo?.sensitivityParameters?.expectedRange" class="tw-relative first:tw-ml-4 last:tw-mr-4 tw-shadow-[0_2px_4px_0_rgba(0,0,0,0.10)] tw-flex-col tw-rounded-lg tw-mr-2 tw-pb-[18vh] tw-bg-white">
            <cs-icon *ngIf="severityIconDisplayMap['senstivity']" class="tw-absolute -tw-top-[12px] tw-ml-4 tw-w-6 tw-h-6 tw-rounded-[50%] tw-shadow-[0_2px_4px_0_rgba(0,0,0,0.10)]" [iconName]="'/rebranding/v1/exclamation_icon'"></cs-icon>
            <div class="tw-text-500 tw-text-gray-800 tw-font-bold tw-mt-6 tw-mx-4 tw-mb-2">
              Skin Sensitivity <br>Range
            </div>
            <div class="tw-text-200 tw-font-normal tw-text-gray-500 tw-w-[184px] tw-mx-4">
              Skin condition characterized by heightened reactivity and a low
              tolerance threshold to various external factors such as burning,
              itching, dryness etc.
            </div>
            <skin-parameter [patientScore]="skinParametersInfo?.sensitivityParameters?.patientScore" [expectedRange]="skinParametersInfo?.sensitivityParameters?.expectedRange" (showExclamationIcon)="setSeverityIconDisplayState('senstivity', $event)"></skin-parameter>
          </div>
          <div *ngIf="skinParametersInfo?.skinRenewalParameters?.patientScore && skinParametersInfo?.skinRenewalParameters?.expectedRange" class="tw-relative first:tw-ml-4 last:tw-mr-4 tw-shadow-[0_2px_4px_0_rgba(0,0,0,0.10)] tw-flex-col tw-rounded-lg tw-mr-2 tw-pb-[18vh] tw-bg-white">
            <cs-icon *ngIf="severityIconDisplayMap['skinRenewal']" class="tw-absolute -tw-top-[12px] tw-ml-4 tw-w-6 tw-h-6 tw-rounded-[50%] tw-shadow-[0_2px_4px_0_rgba(0,0,0,0.10)]" [iconName]="'/rebranding/v1/exclamation_icon'"></cs-icon>
            <div class="tw-text-500 tw-text-gray-800 tw-font-bold tw-mt-6 tw-mx-4 tw-mb-2">
              Skin Renewal <br> Rate
            </div>
            <div class="tw-text-200 tw-font-normal tw-text-gray-500 tw-w-[184px] tw-mx-4">
              The skin's natural process of shedding dead cells and replacing them
              with new ones maintains its health, appearance, and functionality
              through this renewal cycle.
            </div>
            <skin-parameter [patientScore]="skinParametersInfo?.skinRenewalParameters?.patientScore" [expectedRange]="skinParametersInfo?.skinRenewalParameters?.expectedRange" (showExclamationIcon)="setSeverityIconDisplayState('skinRenewal', $event)"></skin-parameter>
          </div>
          <div *ngIf="skinParametersInfo?.skinRadianceParameters?.patientScore && skinParametersInfo?.skinRadianceParameters?.expectedRange" class="tw-relative first:tw-ml-4 last:tw-mr-4 tw-shadow-[0_2px_4px_0_rgba(0,0,0,0.10)] tw-flex-col tw-rounded-lg tw-mr-2 tw-pb-[18vh] tw-bg-white">
            <cs-icon *ngIf="severityIconDisplayMap['skinRadiance']" class="tw-absolute -tw-top-[12px] tw-ml-4 tw-w-6 tw-h-6 tw-rounded-[50%] tw-shadow-[0_2px_4px_0_rgba(0,0,0,0.10)]" [iconName]="'/rebranding/v1/exclamation_icon'"></cs-icon>
            <div class="tw-text-500 tw-text-gray-800 tw-font-bold tw-mt-6 tw-mx-4 tw-mb-2">
              Skin Radiance
            </div>
            <div class="tw-text-200 tw-font-normal tw-text-gray-500 tw-w-[184px] tw-mx-4">
              Skin radiance is the natural glow, vitality, and luminosity of skin
              described by healthy, well-hydrated, and youthful visual appearance.
            </div>
            <skin-parameter [patientScore]="skinParametersInfo?.skinRadianceParameters?.patientScore" [expectedRange]="skinParametersInfo?.skinRadianceParameters?.expectedRange" (showExclamationIcon)="setSeverityIconDisplayState('skinRadiance', $event)"></skin-parameter>
          </div>
          <div *ngIf="skinParametersInfo?.skinDamageParameters?.patientScore && skinParametersInfo?.skinDamageParameters?.expectedRange" class="tw-relative first:tw-ml-4 last:tw-mr-4 tw-shadow-[0_2px_4px_0_rgba(0,0,0,0.10)] tw-flex-col tw-rounded-lg tw-pb-[18vh] tw-bg-white">
            <cs-icon *ngIf="severityIconDisplayMap['skinDamage']" class="tw-absolute -tw-top-[12px] tw-ml-4 tw-w-6 tw-h-6 tw-rounded-[50%] tw-shadow-[0_2px_4px_0_rgba(0,0,0,0.10)]" [iconName]="'/rebranding/v1/exclamation_icon'"></cs-icon>
            <div class="tw-text-500 tw-text-gray-800 tw-font-bold tw-mt-6 tw-mx-4 tw-mb-2">
              Skin Damage Rate
            </div>
            <div class="tw-text-200 tw-font-normal tw-text-gray-500 tw-w-[184px] tw-mx-4">
              The frequency at which damage occurs to the skin tissues can result
              from various factors, including inflammatory acne, sun exposure, and
              skin sensitivity.
            </div>
            <skin-parameter [patientScore]="skinParametersInfo?.skinDamageParameters?.patientScore" [expectedRange]="skinParametersInfo?.skinDamageParameters?.expectedRange" (showExclamationIcon)="setSeverityIconDisplayState('skinDamage', $event)"></skin-parameter>
          </div>
        </div>
      </div>
      <improvement-journey [showFirstJourney]="showFirstJourney" [doctorDisplayImage]="allocatedDoctor?.get('doctorDisplayImage')"></improvement-journey>
      <div *ngIf="this.user.get('PrivateMainConcernClass') === this.appConfig.Shared.Regimen.Class.FACE" class="tw-w-full tw-mb-5 tw-px-4">
        <div class="tw-w-full tw-rounded-lg tw-shadow-z1 tw-bg-white tw-p-5">
          <h1>
            <span class="tw-text-orange-400 tw-font-semibold">
              <span i18n="@@note">Note</span><span class="tw-mr-1">:</span>
            </span>
            <span class="tw-font-medium" i18n="@@reportDisclaimer">This Report will be reviewed by a dermatologist after you order your treatment kit.</span>
          </h1>
        </div>
      </div>
      <div class="tw-mt-8 tw-pb-6 tw-bg-transparent">
          <div class="tw-text-300 tw-text-gray-800 tw-font-bold tw-mx-4">
            CLINICAL INTERPRETATION
          </div>
          <div class="tw-text-300 tw-text-gray-800 tw-font-medium tw-mx-4 tw-mt-2">
            Given your skin's current issues,We are <br> creating Cureskin's skincare treatment kit <br> cutomized for you
          </div>
          <ul class="tw-ml-4 tw-mr-4 tw-flex tw-flex-row tw-justify-between tw-items-start tw-gap-2" *ngIf="regimen.regimenTag && regimen.regimenTag[0]?.rebrandedImage">
            <li class="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-gap-2" *ngFor="let item of regimen.regimenTag">
              <img class="tw-h-18 tw-w-18 tw-mt-6" [src]="item.rebrandedImage" />
              <p class="tw-font-body tw-text-300 tw-text-gray-800 tw-text-center tw-break-words">
                {{ item.title }}
              </p>
            </li>
          </ul>
      </div>
    </section>
    <testimonial-wall [regimen]="regimen" [experiments]="experiments"></testimonial-wall>
    <div class="tw-bg-white tw-ml-auto tw-mr-auto tw-px-4 tw-pb-10 tw-pt-6">
    <div class="tw-text-200 tw-text-gray-800">
      1. Cureskin dermatologist follow the American Association of Dermatology,
      European association of Dermatology and IADVL guidelines to ensure the
      best and latest practices are adopted for identifying and treating your
      skin problems.
    </div>
    <div class="tw-text-200 tw-mt-2 tw-text-gray-800">
      2. Active ingredients in Cureskin prescription products are supported by
      clinical trial evidence and have secured FDA approval.
    </div>
    <div class="tw-flex tw-flex-col tw-mt-8 tw-justify-center">
      <img class="tw-w-[150px]" src="https://cdn.cureskin.com/app/img/dr-charu-sign.png" />
      <div class="tw-text-200 tw-text-gray-800">Head of Dermatology</div>
    </div>
    <div class="tw-border-b tw-border-gray-300 tw-mt-8 tw-mb-2"></div>
    <div class="tw-flex tw-justify-between" [ngStyle]="{'paddingBottom': showRegimenToast ? '80px' : '0'}">
      <div class="tw-text-100 tw-uppercase tw-pb-5 tw-text-gray-800">End of report</div>
      <cs-icon class="tw-w-[38px] tw-h-[12px]" [iconName]="'cs-logo'"></cs-icon>
    </div>
    </div>
  </div>
</div>
