<div class="weekStrip tw-bg-white tw-text-center tw-m-auto tw-mb-6 tw-font-body tw-text-200 tw-pt-2">
  <span i18n="@@yourTreatment1">Your Treatment :</span><span><span class="tw-font-bold tw-mr-1">&nbsp;WEEK 0</span><img class="tw-inline-flex tw-mb-[2px]" src="/assets/icons/teal-tick-circle.svg" /></span>
</div>
<div class="title tw-text-center tw-text-600 tw-font-body tw-font-bold tw-mb-1 tw-m-auto">
  <p class="tw-mx-4" i18n="@@hiUser">Hi {{ user?.get("PatientName")?.split(" ")[0] }},</p>
  <p class="tw-mx-4" i18n="@@orderReceived">We have received your order</p>
</div>
<div class="subTitle tw-text-center tw-mb-4 tw-font-body tw-text-300 tw-mx-4" *ngIf="expectedDeliveryDate" i18n="@@estimatedDelivery">
  Estimated delivery : {{ expectedDeliveryDate.from | date: "d MMM" }} -
  {{ expectedDeliveryDate.to | date: "d MMM" }}
</div>
<div class="tw-flex tw-justify-center tw-items-center tw-mx-4" *ngIf="latestPlacedRegimenOrder?.get('trackingURL')">
  <button class="tw-m-0 tw-min-w-unset tw-h-8 tw-px-4 tw-items-center tw-bg-green-400 tw-m-auto tw-mb-6" (click)="handleOrder(latestPlacedRegimenOrder?.get('trackingURL'))">
    <span class="tw-normal-case tw-font-body tw-text-300 tw-tracking-normal tw-text-white tw-whitespace-break-spaces" i18n="@@trackOrder">Track Order</span>
    <cs-icon class="tw-h-4 tw-w-4 tw-text-white" [iconName]="'chevron_right'"></cs-icon>
  </button>
</div>
<swiper class="tw-pl-6 tw-pb-14 tw-pt-16" [config]="carouselConfigWeeklyJourney">
  <ng-container>
    <div class="bgClass firstCard tw-mr-3 tw-p-1 tw-shadow-z1 tw-h-full tw-box-border" *swiperSlide>
      <section class="cust-wid tw-flex tw-flex-col tw-items-start">
        <img class="cardImg tw-w-24 tw-h-24 tw-rounded-full tw-z-50 tw-absolute tw-object-cover tw-top-[-49px]" [src]="userDrImage ? userDrImage : defaultDoctorImage" />
        <div class="tw-mt-11">
          <div>
            <span class="tw-font-body tw-text-300" i18n="@@skinDr">Skin Specialist</span><span>:</span>
          </div>
          <div class="tw-font-body tw-text-300 tw-font-bold tw-mb-2 tw-text-gray-500">
            {{ allocatedDoctor ? allocatedDoctor?.get('DoctorDisplayName') : '' }}
          </div>
        </div>
        <div class="tw-mt-1">
          <div class="tw-font-body tw-text-300" i18n="@@primaryConcern1">
            Primary concern:
          </div>
          <div class="tw-font-body tw-text-300 tw-font-bold tw-mb-2 tw-text-gray-500">
            {{ primaryConcern }}
          </div>
        </div>
        <div class="tw-mt-1">
          <div class="tw-font-body tw-text-300" i18n="@@otherConcern1">
            Other skin concerns:
          </div>
          <div class="tw-font-body tw-text-300 tw-font-bold tw-mb-2 tw-text-gray-500">
            {{ otherConcerns }}
          </div>
        </div>
      </section>
    </div>
    <section class="cust-wid tw-flex tw-flex-col tw-items-start tw-mr-3" *swiperSlide>
      <img class="cardImg tw-w-24 tw-h-24 tw-rounded-full tw-z-50 tw-absolute tw-object-cover tw-top-[-49px]" [src]="
      kitImg ||
      'https://storage.googleapis.com/heallo-other-user-files/doctor_api_pratham_gupta_06102023_1686400286033.png'
    " />
      <div class="header tw-mt-11 tw-mb-4">
        <div class="tw-font-body tw-text-300 tw-font-bold tw-mb-2" i18n="@@kitHasProducts">
          Your treatment kit has
          {{ latestPlacedRegimenOrder?.get("productInfo")?.length }} products.
        </div>
        <div class="tw-font-body tw-text-300 carousel-wd tw-text-gray-500" i18n="@@knowAboutUsage">
          Know about your product usage
        </div>
      </div>
      <div class="tw-flex-auto tw-flex tw-w-fit tw-items-center tw-justify-center tw-gap-3 tw-pl-4 tw-pr-3 tw-h-10 tw-max-h-10 tw-mt-auto tw-mb-8 tw-border tw-border-gray-900 tw-font-body tw-font-bold tw-text-200 tw-text-green-500 tw-rounded-full" (click)="handleInstructions('card1')">
        <span class="tw-lowercase" i18n="@@seeInstructions">See instructions</span><cs-icon class="tw-h-4 tw-w-4 tw-text-green-400" [iconName]="'chevron_right'"></cs-icon>
      </div>
    </section>
    <section class="cust-wid tw-flex tw-flex-col tw-items-start tw-mr-3" *swiperSlide>
      <img class="cardImg tw-w-24 tw-h-24 tw-rounded-full tw-z-50 tw-absolute tw-object-cover tw-top-[-49px]" src="/assets/images/dietImage.png" />
      <div class="header tw-mt-11 tw-mb-4">
        <div class="tw-font-body tw-text-300 tw-font-bold tw-mb-2" i18n="@@skinFriendlyDiet">
          Your skin friendly diet is here
        </div>
        <div class="tw-font-body tw-text-300 carousel-wd tw-text-gray-500" i18n="@@holisticCure">
          Start your holistic skincare & wellness journey
        </div>
      </div>
      <div class="tw-flex-auto tw-flex tw-w-fit tw-items-center tw-justify-center tw-gap-3 tw-pl-4 tw-pr-3 tw-h-10 tw-max-h-10 tw-mt-auto tw-mb-8 tw-border tw-border-gray-900 tw-font-body tw-font-bold tw-text-200 tw-text-green-500 tw-rounded-full" (click)="handleDiet('post-purchase-diet-card-1')">
        <span class="tw-lowercase" i18n="@@checkDiet">Check diet</span><cs-icon class="tw-h-4 tw-w-4 tw-text-green-400" [iconName]="'chevron_right'"></cs-icon>
      </div>
    </section>
    <section class="cust-wid tw-flex tw-flex-col tw-items-start tw-mr-3" *swiperSlide>
      <img class="cardImg tw-w-24 tw-h-24 tw-rounded-full tw-z-50 tw-absolute tw-object-cover tw-top-[-49px]" src="https://cdn.cureskin.com/app/img/home_need_help.png" />
      <div class="header tw-mt-11 tw-mb-4">
        <div class="tw-font-body tw-text-300 tw-font-bold tw-mb-2" i18n="@@helpAnytime">
          Get help anytime.
        </div>
        <div class="tw-font-body tw-text-300 carousel-wd tw-text-gray-500" i18n="@@helpDescription">
          Your skin advisor is available for all your treatment related queries.
        </div>
      </div>
      <div class="tw-flex-auto tw-flex tw-w-fit tw-items-center tw-justify-center tw-gap-3 tw-pl-4 tw-pr-3 tw-h-10 tw-max-h-10 tw-mt-auto tw-mb-8 tw-border tw-border-gray-900 tw-font-body tw-font-bold tw-text-200 tw-text-green-500 tw-rounded-full" (click)="handleHelp('post-purchase-help-card-1')">
        <span class="tw-lowercase" i18n="@@takeHelp">Take help ></span>
      </div>
    </section>
  </ng-container>
</swiper>
