<ng-container *ngIf="!isNewRegimenBanner">
  <div id="old-regimen-banner" class="font-noto tw-flex tw-justify-center tw-flex-col tw-pb-8">
    <div [ngClass]="regimenImageBackgroundColor" class="tw-rounded-t-[15px]">
      <img class="tw-w-full tw-rounded-[15px] tw-object-contain" [src]="coverImage" />
    </div>
    <div class="tw-flex tw-items-center tw-text-center tw-flex-col">
      <img class="tw-bg-green-100 tw-rounded-full tw-w-[96px] tw-object-contain tw-h-[96px]" [src]="doctorImage || '/assets/images/dr-jisha.png'" style="border: 4px solid white; margin-top: -60px" />
      <p class="tw-mt-2 tw-font-bold tw-text-500 tw-text-gray-800 tw-mb-2 tw-px-8" *ngIf="class === appConfig.Shared.Regimen.Class.HAIR">
        <span class="tw-capitalize">{{userName}},&nbsp;</span><span i18n="@@yourHairKitIsReady">Your hair treatment kit is ready</span>
      </p>
      <p class="tw-mt-2 tw-font-bold tw-text-500 tw-text-gray-800 tw-mb-2 tw-px-8" *ngIf="class === appConfig.Shared.Regimen.Class.FACE ">
        <span class="tw-capitalize">{{userName}},&nbsp;</span><span i18n="@@yourSkinKitIsReady">Your skin treatment kit is ready</span>
      </p>
      <div class="tw-w-full">
        <cs-button [class]="'tw-w-full tw-h-10 primary tw-max-w-[216px] tw-box-border'"><cs-button-text [class]="'tw-text-300 tw-font-bold'" i18n="@@buyNow">Buy Now</cs-button-text></cs-button>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isNewRegimenBanner">
  <div id="new-regimen-banner" class="font-noto tw-flex tw-justify-center tw-flex-col">
    <div [ngClass]="regimenImageBackgroundColor" class="tw-rounded-t-[15px]">
      <img id="cover-image" class="tw-w-full tw-rounded-[15px] max-h-[82px] tw-object-cover" alt="Products" [src]="coverImage" />
    </div>
    <div class="tw-flex tw-items-center tw-text-center tw-flex-col -tw-mt-14 tw-pt-12 tw-pb-5 tw-bg-white tw-relative tw-rounded-b-[15px]">
      <img id="doctor-image" class="tw-rounded-full tw-w-[93px] tw-object-cover tw-h-[93px] tw-absolute -tw-top-12 tw-left-1/2 -tw-translate-x-1/2" [src]="doctorImage || 'https://cdn.cureskin.com/app/img/regimen_banner_doctor.png'" alt="Doctor" style="border: 3px solid white" />
      <p id="hair-message-with-concern" class="tw-mt-2 tw-text-400 tw-text-gray-800 tw-mb-3 tw-px-10" *ngIf="class === appConfig.Shared.Regimen.Class.HAIR && regimen.displayConcern">
        <span class="tw-capitalize tw-font-bold">{{userName}},&nbsp;</span>
        <span i18n="@@treatmentKitIsReadyWithConcern">
            <span class="tw-font-bold">your treatment kit <br></span>for {{ concernDisplayName }} is ready
          </span>
      </p>
      <p id="hair-message-without-concern" class="tw-mt-2 tw-text-400 tw-text-gray-800 tw-mb-3 tw-px-8 tw-font-bold" *ngIf="class === appConfig.Shared.Regimen.Class.HAIR && !regimen.displayConcern">
        <span class="tw-capitalize">{{userName}},&nbsp;</span><span i18n="@@yourHairKitIsReady" class="tw-lowercase">Your hair treatment kit is ready</span>
      </p>
      <p id="face-message-with-concern" class="tw-mt-2 tw-text-400 tw-text-gray-800 tw-mb-3 tw-px-10" *ngIf="class === appConfig.Shared.Regimen.Class.FACE && regimen.displayConcern">
        <span class="tw-capitalize tw-font-bold">{{userName}},&nbsp;</span>
        <span i18n="@@treatmentKitIsReadyWithConcern">
            <span class="tw-font-bold">your treatment kit <br></span>for {{ concernDisplayName }} is ready
          </span>
      </p>
      <p id="face-message-without-concern" class="tw-mt-2 tw-text-400 tw-text-gray-800 tw-mb-3 tw-px-8 tw-font-bold" *ngIf="class === appConfig.Shared.Regimen.Class.FACE && !regimen.displayConcern">
        <span class="tw-capitalize">{{userName}},&nbsp;</span><span i18n="@@yourSkinKitIsReady" class="tw-lowercase">Your skin treatment kit is ready</span>
      </p>
      <div class="tw-w-fit">
        <span i18n="@@buyNow" class="tw-bg-teal-600 tw-text-white tw-py-2 tw-px-8 tw-rounded-xl tw-text-300 tw-flex tw-justify-center tw-items-center tw-font-bold shine-button tw-relative tw-overflow-hidden">Buy Now</span>
      </div>
    </div>
  </div>
</ng-container>
