<div class="tw-w-full tw-absolute tw-bottom-8">
  <div class="tw-flex">
    <div class="tw-w-[10%] tw-flex tw-justify-center tw-items-center">
      <div class="tw-text-100 tw-text-gray-800 tw-uppercase tw-absolute tw-rotate-[-90deg] tw-font-bold tw-left-0 tw-top-[5px]">
      Low
      </div>
    </div>
    <div class="tw-w-[80%] tw-flex tw-bg-gray-300 tw-border-gray-800 tw-relative tw-h-6">
      <div class="tw-grow" *ngFor="let _ of [].constructor(10); let i=index" [ngClass]="checkForBackground(i+1)"></div>
      <div class="tw-absolute tw-border-gray-800 tw-border tw-border-t tw-border-l tw-border-r-0 tw-w-[5px] tw-h-[28px] tw-z-10" style="top: -10%" [style.left]="(leftIdealPercentage === 0 ? '0' : leftIdealPercentage - 10) + '%'"></div>
      <div class="tw-absolute tw-border-gray-800 tw-border tw-border-t tw-border-l-0 tw-border-r tw-w-[5px] tw-h-[28px] tw-z-10" style="top: -10%" [style.left]="'calc(' + rightIdealPercentage + '% - 5px)'"></div>
      <div class="tw-w-5 tw-flex tw-flex-col tw-justify-center tw-absolute tw-bottom-0" [style.left]="patientScore === 0 ? 'calc(' + patientScore * 10 + '%)' : 'calc(' + (patientScore - 1) * 10 + '%)'">
        <div class="tw-flex tw-flex-col tw-items-center tw-justify-center" [ngClass]="{'tw-text-green-400': isIdeal, 'tw-text-orange-400': !isIdeal}">
          <div class="tw-text-200 tw-m-0 tw-ml-[-4px]">You</div>
          <span class="tw-m-0 tw-ml-[-4px]">&darr;</span>
        </div>
        <div class="tw-relative tw-h-6 tw-w-[17.6px] tw-mt-[-0.5px]" [ngClass]="{'tw-bg-green-400': isIdeal, 'tw-bg-orange-400': !isIdeal}"></div>
      </div>
    </div>
    <div class="tw-w-[10%] tw-flex tw-justify-center tw-items-center">
      <div class="tw-text-100 tw-text-gray-800 tw-uppercase tw-absolute tw-font-bold tw-rotate-[-90deg] tw-top-[5px]"> High </div>
    </div>
  </div>
  <div class="tw-mx-4">
    <div class="tw-flex tw-mt-2 tw-justify-center tw-items-center tw-relative" [style.width]="'calc(' + (rightIdealPercentage - leftIdealPercentage) + '%' + ')'" [style.left]="leftIdealPercentage + '%'">
      <div class="tw-text-100 tw-uppercase">Ideal</div>
    </div>
  </div>
</div>