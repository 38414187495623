import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class InstantCheckupHelper {
  getCheckupsByType(checkups: Array<Object>, type: string): Array<Object> {
    if (!checkups) {
      return [];
    }
    return checkups.filter((checkup: any): boolean => checkup.type === type);
  }
}
