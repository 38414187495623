import { Directive } from '@angular/core';

@Directive({
  selector: 'take-photo-banner-title',
  host: {
    class: 'tw-font-body tw-text-300 tw-text-gray-80 tw-font-bold',
  },
})

export class TakePhotoBannerTitleDirective {
}
