import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { BroadcastService } from '@services/broadcast-service';
import { FilePickerComponent } from '@components/file-picker/file-picker.component';
import { CommonUtilityHelper } from '@services/common-utility-helper/common-utility-helper';
import { Table } from 'api-client';
import { EventLoggerService } from '@services/event-logger-service';
import { DataStoreService } from '@services/data-store-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { AppConfig } from '../../../app.config';

interface CardData {
  type: string;
  param: string;
  url: {
    female: string;
    male: string;
  };
}

interface GenderCardData {
  type: string;
  param: string;
  url: string;
}
@Component({
  selector: 'app-select-checkup-type',
  templateUrl: './select-checkup-type.component.html',
  styles: [':host {@apply tw-block tw-h-full tw-overflow-y-scroll; }'],
})

export class SelectCheckupTypeComponent implements OnInit {
  isInternalUser: boolean;
  uploadFileExp: boolean = true;
  hasBodyRegimen: boolean = false;
  // eslint-disable-next-line new-cap
  @ViewChildren(FilePickerComponent) filePickerComponent: QueryList<FilePickerComponent>;

  user: any;
  experiments: any;
  isRebranding: any = false;
  displayCards: any;
  maleCardsData: Array<Object>;
  femaleCardsData: Array<Object>;
  userLanguage: string;

  cardsData: Array<CardData> = [
    {
      type: this.appConfig.Shared.InstantCheckup.Type.FRONT_FACE,
      param: this.appConfig.Shared.InstantCheckup.Type.FRONT_FACE,
      url: {
        female: 'https://cdn.cureskin.com/app/img/female-front-face-rebranded.png',
        male: 'https://cdn.cureskin.com/app/img/male-front-face-rebranded.png',
      },
    },
    {
      type: this.appConfig.Shared.InstantCheckup.Type.RIGHT_FACE,
      param: this.appConfig.Shared.InstantCheckup.Type.SIDE_FACE,
      url: {
        female: 'https://cdn.cureskin.com/app/img/female-right-side-face-rebranded.png',
        male: 'https://cdn.cureskin.com/app/img/male-right-face-rebranded.png',
      },
    },
    {
      type: this.appConfig.Shared.InstantCheckup.Type.LEFT_FACE,
      param: this.appConfig.Shared.InstantCheckup.Type.SIDE_FACE,
      url: {
        female: 'https://cdn.cureskin.com/app/img/female-left-side-face-rebranded.png',
        male: 'https://cdn.cureskin.com/app/img/male-left-face_rebranded.png',
      },
    },
    {
      type: this.appConfig.Shared.InstantCheckup.Type.HAIR_FRONT,
      param: this.appConfig.Shared.InstantCheckup.Type.HAIR_FRONT,
      url: {
        female: 'https://cdn.cureskin.com/app/img/female-front-face-rebranded.png',
        male: 'https://cdn.cureskin.com/app/img/male-front-face-rebranded.png',
      },
    },
    {
      type: this.appConfig.Shared.InstantCheckup.Type.HAIR_TOP,
      param: this.appConfig.Shared.InstantCheckup.Type.HAIR_TOP,
      url: {
        female: 'https://cdn.cureskin.com/app/img/female-hair-top-rebranded.png',
        male: 'https://cdn.cureskin.com/app/img/male-hair-top-rebranded.png',
      },
    },
  ];

  constructor(private conn: ConnectionService,
    private router: Router,
    public appConfig: AppConfig,
    private eventLogger: EventLoggerService,
    private broadcast: BroadcastService,
    private commonUtilityHelper: CommonUtilityHelper,
    private dataStore: DataStoreService,
    private appWebBridgeService: AppWebBridgeService) {
  }

  async ngOnInit(): Promise<void> {
    this.user = await this.conn.getActingUser();
    this.setDisplayCards();
    await this.setExperiments();
    this.isInternalUser = this.conn.isInternalUser();
    this.userLanguage = this.user.get('languagePreference');
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'instant-checkup' }));
    const regimens = (await this.conn.fetchRegimens(null, false)).filter((each: any): boolean => !each.expired);
    this.hasBodyRegimen = !!regimens.find((each: any): boolean => each.class === this.appConfig.Shared.Regimen.Class.BODY);
  }

  async setExperiments(): Promise<void> {
    this.experiments = await this.conn.findUserActiveExperiments();
    this.experiments.forEach((exp:any):void => {
      if (exp.key === 'rebranding_take_photo') {
        this.isRebranding = true;
      }
    });
  }

  setDisplayCards(): void {
    this.femaleCardsData = this.cardsData.map((item: CardData): GenderCardData => ({ ...item, url: item.url.female }));
    this.maleCardsData = this.cardsData.map((item: CardData): GenderCardData => ({ ...item, url: item.url.male }));
    this.displayCards = this.user?.get('Gender') === this.appConfig.Shared.Gender.FEMALE ? this.femaleCardsData : this.maleCardsData;
  }

  showPreview(tag: string): void {
    if (this.uploadFileExp && [this.appConfig.Shared.InstantCheckup.Type.REPORT].includes(tag)) {
      this.openFilePicker(this.appConfig.Shared.InstantCheckup.Type.REPORT);
      return;
    }
    if (tag === this.appConfig.Shared.InstantCheckup.Type.REPORT) return;
    this.router.navigate(['/user/instantCheckup/preview'], { queryParams: { tag } });
  }

  viewUploadedPhotos(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'view-uploaded-photos' }));
    this.router.navigate(['/user/instantCheckup/list']);
  }

  async onFileChange(event: any): Promise<void> {
    const file = event.target.files[0];
    if (!file) return;
    if (!this.commonUtilityHelper.isFileSizeLesserThanMaxSize(file.size, 5)) {
      this.broadcast.broadcast('NOTIFY', { message: 'File size should be less than 5MB. Kindly retry.' });
      return;
    }

    const response: { publicURL: string; signedGetURL: string } = await this.conn.uploadFile({
      file,
      bucket: 'OTHER',
      username: this.user?.get('username'),
      source: 'doctor_other',
    });

    const type: string = this.commonUtilityHelper.findFileType(file.name);
    const userFile = new Table.UserFiles();
    userFile.set('fileUrl', response.publicURL);
    userFile.set('user', this.user);
    userFile.set('type', type);

    try {
      await userFile.save();
    } catch (error) {
      this.broadcast.broadcast('NOTIFY', { message: error.toString() });
    }
  }

  async uploadImageFromNativeCamera(event: any): Promise<any> {
    const nativeCameraImage = event.target.files[0];
    this.dataStore.set('IMAGE_FILE', { file: nativeCameraImage });
    await this.router.navigate(['/user/instantCheckup/capture'],
      { queryParams: { tag: this.appConfig.Shared.InstantCheckup.Type.REPORT, nativeCameraFile: true }, replaceUrl: true });
  }

  openFilePicker(type: string): void {
    if (type === this.appConfig.Shared.InstantCheckup.Type.REPORT) {
      if (this.appWebBridgeService.isAppWebBridgeLoaded()) {
        this.appWebBridgeService.notifyCaptureImage(this.appConfig.Shared.InstantCheckup.Type.REPORT,
          {},
          { queryParams: { nativeCameraFile: true } });
      } else {
        this.filePickerComponent.last.openFileIntent();
      }
      return;
    }
    this.filePickerComponent.first.openFileIntent();
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }
}
