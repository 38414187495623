<section class="tw-bg-white tw-pt-2">
  <h2 class="tw-font-body tw-font-bold tw-text-600 tw-text-gray-800 tw-p-4" i18n="@@hairTransformation">
      Look at our customer's real transformation stories
  </h2>
  <ng-container *ngIf="concern === appConfig.Shared.Regimen.Class.FACE">
      <div *ngFor="let src of testimonialWallImages; let i = index">
          <img id="faceDiv" class="tw-h-full tw-w-full" src="https://cdn.cureskin.com/app/img/testimonials/testimonial-wall/{{language}}_{{i}}.jpg" />
      </div>
  </ng-container>
  <ng-container *ngIf="concern === appConfig.Shared.Regimen.Class.HAIR">
    <div *ngFor="let src of testimonialWallImages">
        <img id="hairDiv" class="tw-h-full tw-w-full" src="https://cdn.cureskin.com/article_image/{{src}}.jpg" />
    </div>
  </ng-container>
</section>
