import { Injectable } from '@angular/core';
import { AppConfig } from 'client/app/app.config';
import { ConcernSeverity, ConcernSeverityThreshold, CONCERN_SEVERITY, CONCERN_SEVERITY_THRESHOLD } from '../../app/app-constant-types';

@Injectable({
  providedIn: 'root',
})

export class InstantCheckupService {
  concernSeverity: ConcernSeverity = CONCERN_SEVERITY;
  concernSeverityThreshold:ConcernSeverityThreshold = CONCERN_SEVERITY_THRESHOLD;

  constructor(
    private readonly appConfig: AppConfig,
  ) {}

  getSeverityFromConcern(checkup: any):string | object {
    const detectedCount = checkup?.BoundingBoxes?.length || checkup?.PolygonPointsSequences?.length;
    let severity = '';

    if (!detectedCount && checkup.class !== this.appConfig.Shared.InstantCheckup.Concerns.MELASMA) return checkup;
    if (checkup.class === this.appConfig.Shared.InstantCheckup.Concerns.MELASMA) {
      severity = typeof checkup.Severity === 'string' ? checkup.Severity[0].toUpperCase() + checkup.Severity.slice(1).toLowerCase() : '';
    }
    if (detectedCount && this.concernSeverityThreshold[checkup.class] && (
      checkup.class !== this.appConfig.Shared.InstantCheckup.Concerns.MELASMA)) {
      const thresholdRange = this.concernSeverityThreshold[checkup.class];
      if (detectedCount > thresholdRange.moderate) severity = this.concernSeverity.Severe;
      else if (detectedCount > thresholdRange.mild) severity = this.concernSeverity.Moderate;
      else severity = this.concernSeverity.Mild;
    }
    return severity;
  }
}
