import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { ConnectionService } from '@services/connection-service';
import { BroadcastService } from '@services/broadcast-service';
import { EventLoggerService } from '@services/event-logger-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { DataStoreService } from '@services/data-store-service';
import { CurrentComponentService } from '@services/current-component';
import { FilePickerComponent } from '@components/file-picker/file-picker.component';
import { LocalStorageService } from '@services/local-storage-service';
import Swiper, { SwiperOptions } from 'swiper';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'app-checkup-photo-instruction',
  templateUrl: './checkup-photo-instruction.component.html',
  styles: [':host {@apply tw-block tw-h-full tw-relative};'],
})
export class CheckupPhotoInstructionComponent implements OnInit, OnDestroy {
  videoURL: string;
  imageSrc: string;
  user: any;
  gifLoaded: boolean = false;
  tag: string;
  @ViewChild(FilePickerComponent, { static: false }) filePickerComponent: FilePickerComponent;
  @ViewChild('audio', { static: false }) audio: ElementRef;
  nativeCameraImage: any;
  subscriptions: Array<Subscription> = [];
  isFrontFacePhoto: boolean;
  isSideFacePhoto: boolean;
  isHairFrontPhoto: boolean;
  isHairTopPhoto: boolean;
  videoLoaded: boolean = false;
  queryParams: { [key: string]: any };
  appLanguageCode: string;
  posterUrl: string;
  experiments: any;
  showNewHairAiInstructions: boolean = false;
  showNewFacePhotoInstructions: boolean = false;
  isCarouselReachedEnd: boolean = false;
  carouselConfig: SwiperOptions = {
    slidesPerView: 1,
    navigation: { prevEl: 'nonext', nextEl: 'nonext' },
    autoplay: {
      delay: 2000,
    },
    pagination: {
      type: 'bullets',
    },
    on: {
      reachEnd: (): void => {
        this.isCarouselReachedEnd = true;
      },
    },
  };
  constructor(private conn: ConnectionService,
    private router: Router,
    public appConfig: AppConfig,
    private route: ActivatedRoute,
    private eventLogger: EventLoggerService,
    private broadcast: BroadcastService,
    private event: EventLoggerService,
    private appWebBridgeService: AppWebBridgeService,
    private dataStore: DataStoreService,
    private currentComponent: CurrentComponentService,
    private localStorageService: LocalStorageService) {
    this.currentComponent.set(this);
  }

  async ngOnInit(): Promise<any> {
    const startTimer = performance.now();
    this.user = this.conn.getActingUser();
    this.appWebBridgeService.notifyWebLoginToken();
    this.subscriptions.push(this.route.queryParams.subscribe((params: any): void => {
      this.queryParams = params;
      this.tag = params.tag || this.appConfig.Shared.InstantCheckup.Type.FULL_FACE;
      this.tag = this.tag.toUpperCase();
    }));
    this.appLanguageCode = this.conn.currentWebSiteLanguage;
    this.openVideo();
    const endTimer = performance.now();
    this.experiments = await this.conn.findUserActiveExperiments();
    this.experiments.forEach((experiment: any): void => {
      if (experiment.key === 'enable_hair_ai_new_photo_taking_ui') {
        const currentAndroidVersion = this.appWebBridgeService.requestAppVersion();
        const experimentOSVersion = experiment.variant?.appVersion;
        if ((currentAndroidVersion === experimentOSVersion) || (experimentOSVersion === 'True')) this.showNewHairAiInstructions = true;
      }
      if (experiment.key === 'new_face_photo_instructions') {
        this.showNewFacePhotoInstructions = true;
      }
    });
    this.eventLogger.trackEvent('instant_photo_upload_screen', { timeInMillSec: (endTimer - startTimer), userId: this.user.id });
  }

  openVideo(): void {
    const isHindiUser: boolean = this.appLanguageCode === this.appConfig.Shared.Languages.HI;
    switch (this.tag) {
      case this.appConfig.Shared.InstantCheckup.Type.HAIR_FRONT: {
        this.isHairFrontPhoto = true;
        break;
      }
      case this.appConfig.Shared.InstantCheckup.Type.HAIR_TOP: {
        this.isHairTopPhoto = true;
        break;
      }
      case this.appConfig.Shared.InstantCheckup.Type.SIDE_FACE:
      case this.appConfig.Shared.InstantCheckup.Type.RIGHT_SIDE_FACE:
      case this.appConfig.Shared.InstantCheckup.Type.LEFT_SIDE_FACE: {
        this.isSideFacePhoto = true;
        if (isHindiUser) {
          this.videoURL = 'https://cdn.cureskin.com/app/video/PhotoClickSideHindi.mp4';
          this.posterUrl = 'https://cdn.cureskin.com/app/img/ThumbPhotoClickSideHindi.jpg';
        } else {
          this.videoURL = 'https://cdn.cureskin.com/app/video/PhotoClickSide.mp4';
          this.posterUrl = 'https://cdn.cureskin.com/app/img/ThumbPhotoClickSide.jpg';
        }
        break;
      }
      case this.appConfig.Shared.InstantCheckup.Type.FULL_FACE:
      case this.appConfig.Shared.InstantCheckup.Type.FRONT_FACE: {
        this.isFrontFacePhoto = true;
        if (isHindiUser) {
          this.videoURL = 'https://cdn.cureskin.com/app/video/PhotoClickStraightHindi.mp4';
          this.posterUrl = 'https://cdn.cureskin.com/app/img/ThumbPhotoClickStraightHindi.jpg';
        } else {
          this.videoURL = 'https://cdn.cureskin.com/app/video/PhotoClickStraight.mp4';
          this.posterUrl = 'https://cdn.cureskin.com/app/img/ThumbPhotoClickStraight.jpg';
        }
        break;
      }
      case this.appConfig.Shared.InstantCheckup.Type.BODY:
      case this.appConfig.Shared.InstantCheckup.Type.OTHER:
      case this.appConfig.Shared.InstantCheckup.Type.REPORT:
      default: {
        this.imageSrc = '';
      }
    }
    if (!this.imageSrc && !this.videoURL && !this.isHairFrontPhoto && !this.isHairTopPhoto) this.takePhoto();
    this.event.trackEvent('start_watching_video', { videoSrc: this.videoURL });
  }

  takePhoto(): void {
    if (this.queryParams?.tag) {
      this.localStorageService.setValue('expectedFacePhoto', this.queryParams.tag);
    }
    if (this.queryParams?.fromTakePhotoBanner) {
      this.localStorageService.setValue('takePhotoBannerClicked', true);
    }
    this.eventLogger.cleverTapEvent('instantCheckupInitiated', JSON.stringify({ type: this.tag }));
    this.appWebBridgeService.logEventInBranchAndFirebaseFromiOS({
      branch: { name: 'instantCheckupInitiated' },
      firebase: { name: 'instantCheckupInitiated' },
    });
    this.event.trackEvent('take_photo_clicked', { username: this.user?.get('username'), type: this.tag });
    this.event.trackPeopleIncrement({ people_take_photo_clicked: 1 });
    if (this.appWebBridgeService.isAppWebBridgeLoaded()) {
      const extraData = { ...this.queryParams };
      delete extraData.tag;
      this.appWebBridgeService.notifyCaptureImage(this.tag,
        {},
        extraData);
    } else {
      this.filePickerComponent.openFileIntent();
    }
  }

  enableGif(): void {
    setTimeout((): void => {
      this.gifLoaded = true;
    }, 1000);
  }

  async uploadImageFromNativeCamera(event: any): Promise<any> {
    this.nativeCameraImage = event.target.files[0];
    await this.dataStore.set('IMAGE_FILE', { file: this.nativeCameraImage });
    await this.router.navigate(['/user/instantCheckup/capture'],
      { queryParams: { ...this.queryParams, nativeCameraFile: true }, replaceUrl: true });
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  ngOnDestroy(): void {
    this.currentComponent.remove(this);
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
  }
}
