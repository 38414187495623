<div class="tw-flex tw-flex-col tw-h-full tw-bg-teal-200 tw-bg-repeat-x tw-bg-top tw-pb-24 tw-overflow-y-auto" #scrollContainer>
  <div class="tw-flex-1 tw-p-6 tw-pb-7 tw-flex tw-flex-col">
    <div class="tw-font-body tw-font-bold tw-text-800 tw-text-gray-800 tw-pt-14 tw-mb-2 w60" i18n="@@addYourInformation"> Add your information </div>
    <img class="tw-block tw-w-[75px] tw-h-[25px]" src="https://cdn.cureskin.com/app/img/cs-icon.svg" />
    <div class="tw-flex-1"></div>
  </div>
  <div class="tw-flex-none tw-rounded-tr-5 tw-rounded-tl-5 tw-px-6 tw-pt-7 tw-pb-7 tw-bg-white">
    <div class="tw-font-body tw-text-gray-800 tw-mb-2 tw-text-200" [ngClass]="conn.getClassBasedOnLanguagePreference()" i18n="@@tellUsYourName"> Tell us your name </div>
    <input class="tw-h-12 tw-flex tw-bg-gray-80 tw-rounded-2.5 tw-border tw-border-solid tw-border-gray-300 tw-m-0 placeholder:tw-text-300 placeholder:tw-font-normal placeholder:tw-text-left tw-w-full tw-font-body tw-text-300 tw-text-gray-800 tw-px-3" #nameInput="" name="patientName" autocomplete="off" type="text" autofocus="autofocus" id="nameInput" placeholder="Full Name" i18n-placeholder="@@fullName" [(ngModel)]="patientName" (keyup)="onNameChange()" [ngClass]='{ "tw-border-teal-400": patientName.length > 0 && !nameError, "tw-border-orange-500": nameError }' (paste)="onInputPaste($event)" />
    <div class="tw-flex tw-mt-2" *ngIf='nameError' id="nameError">
      <img src="/assets/images/warn-err.png" style="height: 18px" />
      <div class="tw-text-orange-500 tw-text-300 tw-ml-1" *ngIf="nameErrorMessage === GenderFormErrorMessages.numberInName" i18n="@@nameErrorSpecialCharacters">{{nameErrorMessage}}</div>
      <div class="tw-text-orange-500 tw-text-300 tw-ml-1" *ngIf="nameErrorMessage === GenderFormErrorMessages.emptyName" i18n="@@nameErrorEmptyName">{{nameErrorMessage}}</div>
      <div class="tw-text-orange-500 tw-text-300 tw-ml-1" *ngIf="nameErrorMessage === GenderFormErrorMessages.maxNameLength" i18n="@@nameErrorLengthLimit">{{nameErrorMessage}}</div>
    </div>
    <div class="tw-mt-6 tw-font-body tw-text-gray-800 tw-mb-[10px] tw-text-200" [ngClass]="conn.getClassBasedOnLanguagePreference()" i18n="@@chooseYourGender"> Choose Your Gender </div>
    <div class="tw-flex tw-items-start tw-gap-6" id="genderSelection">
      <div class="tw-flex tw-flex-col tw-items-center tw-gap-2 female" (click)="markGenderTo(appConfig.Shared.Gender.FEMALE)">
        <img class="tw-w-20 tw-h-auto" [ngClass]="{'tw-hidden': gender !== appConfig.Shared.Gender.MALE}" src="https://cdn.cureskin.com/app/img/female_deselected_v1.svg" />
        <img class="tw-w-20 tw-h-auto" [ngClass]="{'tw-hidden': gender === appConfig.Shared.Gender.FEMALE || gender === appConfig.Shared.Gender.MALE}" src="https://cdn.cureskin.com/app/img/female_inactive_v1.svg" />
        <img class="tw-w-20 tw-h-auto" [ngClass]="{'tw-hidden': gender !== appConfig.Shared.Gender.FEMALE}" src="https://cdn.cureskin.com/app/img/female_active_v1.svg" />
        <div class="tw-font-body tw-font-bold tw-text-300 tw-text-gray-800" i18n="@@female" [ngClass]="{'tw-opacity-30': gender === appConfig.Shared.Gender.MALE}"> Female </div>
      </div>
      <div class="tw-flex tw-flex-col tw-items-center tw-gap-2 male" (click)="markGenderTo(appConfig.Shared.Gender.MALE)">
        <img class="tw-w-20 tw-h-auto" [ngClass]="{'tw-hidden': gender !== appConfig.Shared.Gender.FEMALE}" src="https://cdn.cureskin.com/app/img/male_deselected_v1.svg" />
        <img class="tw-w-20 tw-h-auto" [ngClass]="{'tw-hidden': gender === appConfig.Shared.Gender.MALE || gender === appConfig.Shared.Gender.FEMALE}" src="https://cdn.cureskin.com/app/img/male_inactive_v1.svg" />
        <img class="tw-w-20 tw-h-auto" [ngClass]="{'tw-hidden': gender !== appConfig.Shared.Gender.MALE}" src="https://cdn.cureskin.com/app/img/male_active_v1.svg" />
        <div class="tw-font-body tw-font-bold tw-text-300 tw-text-gray-800" i18n="@@male" [ngClass]="{'tw-opacity-30': gender === appConfig.Shared.Gender.FEMALE}"> Male </div>
      </div>
    </div>
    <div class="tw-flex tw-mt-2" *ngIf='saveGenderInfo && gender === "None" ' id="genderError">
      <img src="/assets/images/warn-err.png" style="height: 18px" />
      <div class="tw-text-orange-500 tw-text-300 tw-ml-1" i18n="@@enterGender"> Select your gender </div>
    </div>
    <div class="tw-mt-6 tw-font-body tw-text-gray-800 tw-mb-2 tw-text-200" [ngClass]="conn.getClassBasedOnLanguagePreference()" i18n="@@enterYourAge"> Enter Your Age </div>
    <div class="tw-flex tw-items-center">
      <input class="tw-h-12 tw-flex tw-bg-gray-80 tw-rounded-2.5 tw-border tw-border-solid tw-border-gray-300 tw-m-0 placeholder:tw-text-300 placeholder:tw-font-normal placeholder:tw-text-left tw-w-28 tw-font-body tw-text-300 tw-text-gray-800 tw-px-3" [ngClass]='{"tw-border-teal-400": age, "tw-border-orange-500":!age && saveGenderInfo}' #ageInput="" name="age" [(ngModel)]="age" pattern="[0-9]*" (input)="sanitizeAgeInput($event)" autocomplete="off" type="text" inputmode="numeric" autofocus="autofocus" (keydown)="commonUtilityHelper.limitInputByMaxDigit($event, 2)" maxlength="2" id="ageInput" placeholder="Age" i18n-placeholder="@@age" (paste)="onInputPaste($event)" />
    </div>
    <div class="tw-flex tw-mt-2" *ngIf="ageError || (saveGenderInfo && !age)" id="ageError">
      <img src="/assets/images/warn-err.png" style="height: 18px" />
      <div *ngIf="ageErrorMessage === GenderFormErrorMessages.underAged" class="tw-text-orange-500 tw-text-300 tw-ml-1" i18n="@@ageErrorUnderage">{{ageErrorMessage}}</div>
      <div *ngIf="ageErrorMessage === GenderFormErrorMessages.invalidAge" class="tw-text-orange-500 tw-text-300 tw-ml-1" i18n="@@ageErrorInvalidAge">{{ageErrorMessage}}</div>
      <div *ngIf="ageErrorMessage === GenderFormErrorMessages.enterAge || (!ageErrorMessage && saveGenderInfo && !age)" class="tw-text-orange-500 tw-text-300 tw-ml-1" i18n="@@enterAge">{{ GenderFormErrorMessages.enterAge }}</div>
    </div>
  </div>
  <div class="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-p-6 tw-mt-6 tw-bg-white">
    <cs-button class="tw-h-12" [class]="'w100 primary'" (click)="updateGender()" id="continueBtn" [loading]="loading">
      <cs-button-text i18n="@@continueNew">Continue</cs-button-text>
    </cs-button>
  </div>
</div>
