<section class="card takePhoto getSkinAnalysis pos-rel flex-col m16 bdr-rad-20 pB20 rebranded-diet-banner tw-overflow-hidden tw-rounded-tr-4 tw-rounded-tl-4" id="takePhoto" *ngIf="userStatus.PAID" (click)="handleClick()">
  <img class="w100 tw-mb-4" src=" https://cdn.cureskin.com/app/img/diet-banner.png" style="height: 180px; object-fit: cover" />
  <div class="nutritionist pos-abs pos-top-left mL15 mT15" i18n="@@nutritionist">
    Nutritionist
  </div>
  <div class="fw-700 font20 mL16" i18n="@@getDietPlan">Get a diet plan</div>
  <div class="tw-flex tw-items-end">
    <div class="tw-flex-1 tw-flex tw-flex-col tw-self-center">
      <div class="mL16" i18n="@@receiveDailyMealPlan">
        Receive daily meal plan for<span class="fw-600">
          healthy skin & hair</span>
      </div>
    </div>
    <div class="tw-mr-4">
      <cs-button [class]="'primary btn-xxxs tw-px-4'"><cs-button-text i18n="@@clickHere">Click Here</cs-button-text><cs-icon class="tw-h-4 tw-w-4" [iconName]="'chevron_right'"></cs-icon></cs-button>
    </div>
  </div>
</section>
