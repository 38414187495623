export const emailRegex: RegExp = /^[\w.-]+@[a-zA-Z\d-]+\.[a-zA-Z]{2,}$/;
export const commonSpecialCharecterRegex: RegExp = /[0-9$&+,:;=?@#|"'`~/<>\\^*()%!\-_{}]/;

export const onlyEnglishNameRegex: RegExp = /[^a-zA-Z\s-]/;
export const onlyEnglishAddressRegex: RegExp = /[^a-zA-Z0-9\s.,?\-#&@{}()]/;

// eslint-disable-next-line max-len
export const specialCharacterAndEmojiRegex: RegExp = /[$&+@#:;=?|"'`_{}~<>^*%![\]\\/]|[\u{1F600}-\u{1F64F}\u{2700}-\u{27BF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{1F300}-\u{1FAFF}]/u;
// eslint-disable-next-line max-len
export const numbersAndSpecialCharacterRegex: RegExp = /[0-9$&+.,:;=?@#|"'<>^*()%!_{}\\/]|[\u{1F300}-\u{1FAFF}\u{1F600}-\u{1F64F}\u{2700}-\u{27BF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}]/u;
