<div class="tw-bg-gray-200 tw-mx-4 tw-p-4 tw-pt-9 tw-rounded-3 tw-relative tw-overflow-hidden tw-shadow-z4" (click)="handleClick()">
    <h1 class="tw-text-400 tw-mb-[17px] tw-text-gray-800 tw-w-[70%]" i18n="@@doctorIsCreatingYourCheckupReport">{{ doctor.firstName }}, is creating your checkup report</h1>
    <div class="tw-bg-gray-100 tw-p-4 tw-pl-2 tw-rounded-[10px] tw-relative tw-flex tw-items-center tw-shadow-sm tw-border tw-gap-2">
      <div class="tw-flex-shrink-0">
        <img class="tw-h-12 tw-w-12" src="/assets/gifs/sand-watch-loader-gray-bg.gif" alt="loader" class="tw-w-12 tw-aspect-square"/>
      </div>
      <p class="tw-text-gray-800 tw-font-bold tw-text-200 tw-w-[32%] tw-leading-[15px]" i18n="@@reportWillBeReadyWithin48Hours">Report will be ready within 48 hours</p>
      <div class="tw-absolute tw-right-0 tw-bottom-0">
        <img src="{{doctor.image}}"
             alt="Doctor" class="tw-w-[125px] tw-object-cover tw-shadow-lg"/>
      </div>
    </div>
</div>
