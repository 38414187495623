import { Component, EventEmitter, Output } from '@angular/core';
import { EventLoggerService } from '@services/event-logger-service';
import { ConnectionService } from '@services/connection-service';
import { NgClass, NgIf } from '@angular/common';
import { FollowUpReport } from '../../../../../app-constant-types';
import { AppConfig } from '../../../../../app.config';

@Component({
  selector: 'report-is-ready',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
  ],
  templateUrl: './report-is-ready.html',
  styles: '',
})
export class ReportIsReadyComponent {
  @Output('openFollowUpReport') openFollowUpReport: EventEmitter<any> = new EventEmitter();
  user: Table.User;
  followUpReport: FollowUpReport;
  instantCheckups: Array<any> = [];
  beforeImage: string;
  afterImage: string;

  constructor(
    private readonly eventLogger: EventLoggerService,
    private readonly conn: ConnectionService,
    protected readonly appConfig: AppConfig,
  ) {}

  async ngOnInit(): Promise<void> {
    this.user = this.conn.getActingUser();
    await this.checkFollowUpReportAvailable();
  }

  async checkFollowUpReportAvailable(): Promise<void> {
    [this.followUpReport] = await this.conn.fetchFollowUpReports({
      where: {
        user: this.user,
        createdAt: { $gte: new Date(new Date().getTime() - 604800000) }, // 7 * 24 * 60 * 60 * 1000
        read: { $ne: true },
      },
      descending: 'createdAt',
      limit: 1,
      include: ['afterInstantCheckup', 'beforeInstantCheckup', 'imageConfig'],
    });
    const jsonReport = JSON.parse(JSON.stringify(this.followUpReport));

    this.instantCheckups = await this.loadInstantCheckups([jsonReport.afterInstantCheckup.objectId,
      jsonReport.beforeInstantCheckup.objectId]);
    this.beforeImage = jsonReport.imageConfig.after.compressedImage
      ? this.instantCheckups[0]?.compressedImagePath
      : this.instantCheckups[0]?.imagePath;
    this.afterImage = jsonReport.imageConfig.before.compressedImage
      ? this.instantCheckups[1]?.compressedImagePath
      : this.instantCheckups[1]?.imagePath;
  }

  async loadInstantCheckups(ids: string[]): Promise<any> {
    const username: string = this.user.get('username');
    const data = await this.conn.fetchInstantCheckup({ userId: username,
      id: ids,
      project: ['orientation', 'imagePath', 'compressedImagePath'] });
    if (!data || data.length !== 2) {
      return [];
    }
    if (ids[0] !== data[0].objectId) data.reverse();
    return data;
  }

  handleClick(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'level-two-report-is-ready-banner' }));
    this.openFollowUpReport.emit();
  }
}
